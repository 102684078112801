<template>
    <b-container>
        <b-row v-if="info">
            <b-col>
                <b-row>
                    <b-col class="content p-0 pr-5" v-html="description[0]">
                    </b-col>
                </b-row>
                <b-row class="mt-4" v-if="info.document">
                    <div class="p-0 icon-list">
                        <a :href="info.document.path">
                            <b-icon icon="file-earmark-text"></b-icon>
                        </a>
                    </div>
                    <b-col class="href pt-1">
                        <a :href="info.document.path" class="href">
                            Подробная инструкция оплаты услуг через сайт Красноярского краевого Дворца пионеров
                        </a>
                    </b-col>
                </b-row>
                <b-row class="mt-3" v-if="info.document2">
                    <div class="p-0 icon-list">
                        <a :href="info.document2.path">
                            <b-icon icon="file-earmark-text"></b-icon>
                        </a>
                    </div>
                    <b-col class="href pt-2">
                        <a :href="info.document2.path" class="href">
                            Анкета для родителей
                        </a>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" lg="7" class="mt-4 mt-lg-0 p-0" v-if="info.image_description">
                <b-card class="info-card">
                    <div class="title w-100">Для оплаты через сайт вам потребуется</div>
                    <img :src="info.image_description.path" class="image_description">
                    <div class="content mt-3" v-html="description[1]"></div>
                </b-card>

            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Info",
        computed: {
            info: function () {
                return this.$store.getters.allPages;
            },
            description: function(){
                return this.info.description.split("<hr>");
            }
        }
    }
</script>

<style scoped>

    .title {
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-size: 14px;
        width: 200px;
        line-height: 17px;
        color: var(--text-primary-color);
        height: 32px;
    }

    .content {
        padding-right: 32px;
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 21px;
        color: var(--text-secondary-color);
    }

    .info-card {
        background-color: var(--background-color-secondary);
        border: 0px;
    }

    .href {
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 13px;
        line-height: 18px !important;
        color: var(--text-primary-color);
    }

    .icon-list {
        font-size: 24px;
        color: #F2913A;
        width: 24px;
    }

    .image_description {
        width: 100%;
        max-width: 540px;
        border-radius: 16px;
    }
</style>