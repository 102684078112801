<template>
    <b-container class="p-3 mb-5">
        <b-row v-if="info">
            <b-col class="text-center" v-if="info.pics">
                <img :src="img_path+image" class="image" />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    
    export default {
        name: 'ProjectsBDDFooter',
        data() {
            return {
                img_path: process.env.VUE_APP_IMG
            }
        },        
        computed: {
            info() {
                return this.$store.getters.getProject
            },
            image() {
                return this.info.pics.find(item => item.label == "footer").images
            }
        }
    }
</script>

<style scoped>

    h3 {
        font-family: 'SF Light';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;

        color: #000000;
    }

    .content {
        font-family: 'SF Light';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
    }

    .image {
        width: 100%;
    }
</style>