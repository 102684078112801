<template>
    <b-container fluid="xl" class="pl-4 pl-md-4 pl-lg-4 pl-xl-0 pr-2" v-if="info">
        <b-row class="mr-0 ml-0 mt-0 mb-4" no-gutters>
            <b-col class="title mt-4" cols="12">
                Педагогическим работникам
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-5">
                <b-row v-for="(doc, index) in info.docs" :key="'doc'+index">
                    <b-col class="doc_item mt-2">
                        <a :href="doc.document.path">{{doc.name}}</a>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Docs",
        computed: {
            info: function () {
                return this.$store.getters.ovzDetail;
            }
        }
    }
</script>

<style scoped>
    .title {
        width: 600px;
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: var(--text-primary-color);
    }

    .doc_item a {
        font-family: SF, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #F2913A;
    }
</style>