<template>
    <b-container fluid>
        <b-row>
            <b-col class="mb-1 p-0">
                <event-title></event-title>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-1 p-0">
                <event-description></event-description>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-5 mb-5 p-0">
                <event-list></event-list>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import Title from './EventList/Title';
    import List from './EventList/List';
    import Description from './EventList/Description'

    export default {
        name: "EventList",
        components: {
            'event-title': Title,
            'event-list': List,
            'event-description': Description
        },
        mounted() {
            this.$store.dispatch('getEventPage');
            this.$store.dispatch('getEventList');
        }
    }
</script>

<style scoped>

</style>