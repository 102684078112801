<template>
    <b-container>
        <b-row>
            <b-col class="title p-0">Оплата по договору:</b-col>
        </b-row>
        <b-row>
            <b-col class="p-0">
                <b-card class="form">
                    <b-row>
                        <b-col class="text">Введите данные договора:</b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col class="text">
                            <b-form-group
                                    id="fieldset-horizontal"
                                    label-cols-md="4"
                                    label-cols-lg="3"
                                    content-cols-md="4"
                                    content-cols-lg="3"
                                    label="Номер договора"
                                    label-for="number"
                            >
                                <b-form-input id="number"></b-form-input>
                            </b-form-group>
                            <b-form-group
                                    id="fieldset-horizontal"
                                    label-cols-md="4"
                                    label-cols-lg="3"
                                    content-cols-md
                                    content-cols-lg="9"
                                    label="ФИО заказчика (родителя) полностью"
                                    label-for="number"
                            >
                                <b-input-group>
                                <b-form-input id="number"></b-form-input>
                                <b-input-group-append>
                                    <b-button variant="primary">Найти</b-button>
                                </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                 </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Form"
    }
</script>

<style scoped>

    .form {
        min-height: 200px;
        background-color: var(--background-color-secondary);
        border: 1px solid var(--background-input-border) !important;
    }

    .title {
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-size: 14px;
        width: 200px;
        line-height: 17px;
        color: var(--text-primary-color);
        height: 32px;
    }


    .text{
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 14px;
        width: 200px;
        line-height: 17px;
        color: var(--text-secondary-color);
        height: 32px;
    }


    @media all and (max-width: 640px) {
        .form {
            min-height: 260px;
        }
    }

</style>