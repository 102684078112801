<template>
    <b-container fluid="xl" class="pl-4 pl-md-4 pl-lg-5 pl-xl-0 pr-2" v-if="info">
        <b-row align-v="top" class="mr-3 mr-xl-0 mt-5">
            <b-col cols="12" lg="6" xl="8">
                <b-row v-if="info.description">
                    <b-col class="content" cols="12" xl="6" v-html="description[0]">
                    </b-col>
                    <b-col cols="12" class="d-block d-lg-none text-center mb-4">
                        <img :src="info.image_description.path" v-if="info.image_description" class="image_description">
                    </b-col>
                    <b-col class="content" cols="12" xl="6">
                        <b-row>
                            <b-col v-html="description[1]" />
                        </b-row>
                        <b-row>
                            <b-col cols="12" class="mt-0">
                                <b-row class="mt-0 ml-0 d-block" v-if="info.document">
                                    <b-col class="href p-0">
                                        <a :href="info.document.path">
                                            <b-icon icon="file-earmark-text" class="icon-list mr-2"></b-icon> {{ info.document.title }}
                                        </a>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-2 ml-0" v-if="info.document2">
                                    <b-col class="href p-0">
                                        <a :href="info.document2.path">
                                            <b-icon icon="file-earmark-text" class="icon-list mr-2"></b-icon>{{ info.document2.title }}
                                        </a>
                                    </b-col>
                             </b-row>
                       </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" lg="6" xl="4" class="d-none d-lg-block text-right mr-xl-0">
                <img :src="(getTheme=='light-theme')?info.image_description.path:(info.image_description_dark)?info.image_description_dark.path:info.image_description.path" v-if="info.image_description" class="image_description">
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Description",
        computed: {
            info: function () {
                return this.$store.getters.allPages;
            },
            description: function(){
                return this.info.description.split("<hr>");
            },
            getTheme() {
                return this.$store.getters.userTheme
            }
        }
    }
</script>

<style scoped>
    .title {
        width: 600px;
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: var(--text-primary-color);
    }

    .content {
        padding-right: 32px;
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        color: var(--text-secondary-color);
    }

    .image_description {
        width: 100%;
        max-width: 460px;
    }

</style>