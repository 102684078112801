<template>
    <b-container fluid="xl" class="p-0 pl-4 pr-4 pl-sm-5 pr-sm-5 pl-xl-3 pr-xl-3"  v-if="course">
        <b-row class="mb-5">
            <b-col class="main_title p-0 pl-2 pl-sm-0">Педагоги</b-col>
        </b-row>
        <b-row>
            <b-col class="pl-4 pl-sm-3" cols="12" lg="6" v-for="(person, index) in course.teacher" :key="'person'+index">
                <b-row class="mb-5">
                    <div style="width: 164px" class="d-none d-md-block">
                        <b-avatar square variant="dark" :src="img_path+person.avatar" size="10rem"></b-avatar>
                    </div>
                    <div style="width: 100px" class="d-block d-md-none">
                        <b-avatar square variant="dark" :src="img_path+person.avatar" size="6rem"></b-avatar>
                    </div>

                    <b-col cols="6" sm="7" md="8" lg="7" xl="6">
                        <div class="title">{{person.fio}}</div>
                        <div class="item mt-1">{{person.achievs || ''}}</div>
                        <div class="item mt-3" v-if="person.phone"><Icon icon="carbon:phone-voice-filled" color="#333" height="18" :rotate="2" class="mr-2"
                                                     :horizontalFlip="true" :verticalFlip="true" /> {{person.phone || '-'}}</div>
                        <div class="item" v-if="person.mail"> <Icon icon="cib:mail-ru" color="#333" height="16" :rotate="2" :horizontalFlip="true" class="mr-2"
                                                 :verticalFlip="true"/> {{person.mail || '-'}}</div>
                        <div class="item" v-if="person.cabinet"><Icon icon="fluent:conference-room-24-filled" color="#333" height="18" :rotate="2" :horizontalFlip="true" :verticalFlip="true"  class="mr-2" />
                            кабинет: {{person.cabinet || '-'}}</div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import {Icon} from '@iconify/vue2';
    export default {
        name: "Team",
        data() {
            return {
                img_path: process.env.VUE_APP_IMG
            }
        },
        components: {
            Icon
        },
        computed: {
            course: function () {
                return this.$store.getters.currentCourse;
            }
        }
    }
</script>

<style scoped>
    .main_title {
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-size: 32px;
        line-height: 42px;
        color: #9D9E9E;
    }

    .title {
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: var(--text-primary-color);
    }

    .item {
        font-family: SF LIGHT, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: var(--text-secondary-color);
    }

</style>