<template>
    <b-container fluid>
        <b-row class="wrapper">
            <b-col>
                <h1>Извините, такой страницы не существует на сайте :(</h1>
            </b-col>
        </b-row>
    </b-container>

</template>

<script>
    export default {
        name: "ErrorPage"
    }
</script>

<style scoped>

    .wrapper{
        min-height: 500px;
    }
    h1 {     
        margin-top: 120px;   
        font-family: SF BOLD, sans-serif;
        font-weight: 400;
        font-style: normal;
        text-align: center;
        font-size: 24px !important;
        line-height: 32px !important;
        color: var(--text-secondary-color) !important;
    }
</style>