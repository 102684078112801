<template>
    <b-container fluid="xl" class="pl-5 pr-5 pl-xl-3 pr-xl-3">
        <b-row>
            <b-col class="title mt-0 ml-0 mb-5 p-0">
                <span class="main_title"> Достижения обучающихся</span>
            </b-col>
        </b-row>
        <b-row сlass="mt-3" v-if="course">
            <b-col cols="6" md="4" lg="3" xl="2" class="p-0 mb-4" v-for="(achiev, index) in course.achiev" :key="'achiev'+index">
                <div class="text-center item">
                    <div class="place">
                        <img alt="первое место" src="../../assets/img/course/first-place.png" v-if="achiev.place==1" class="venok" />
                        <img alt="второе место" src="../../assets/img/course/second-place.png" v-else-if="achiev.place==2" class="venok" />
                        <img alt="третье место" src="../../assets/img/course/third-place.png" v-else-if="achiev.place>2" class="venok" />
                        <img src="../../assets/img/course/first-place.png" v-else class="venok" />
                        <img src="../../assets/img/course/kubok.png" class="cup">
                        <!--{{achiev.place}}<div class="place_text">место</div>-->
                    </div>
                    <div class="achiev_name">{{achiev.name}}</div>
                    <div class="fio mt-1">{{achiev.pupil_fio}}, <span v-if="achiev.pupil_age">{{achiev.pupil_age}} {{getNoun(achiev.pupil_age, 'год', 'года', 'лет')}}</span></div>
                    <div class="event">{{achiev.event_name}}<br>{{achiev.year}} г.</div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Achievs",
        computed: {
            course: function () {
                return this.$store.getters.currentCourse;
            }
        },
        methods: {
            getNoun: function (number, one, two, five) {
                let n = Math.abs(number);
                n %= 100;
                if (n >= 5 && n <= 20) {
                    return five;
                }
                n %= 10;
                if (n === 1) {
                    return one;
                }
                if (n >= 2 && n <= 4) {
                    return two;
                }
                return five;
            }
        }
    }
</script>

<style scoped>
    .main_title {
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-size: 32px;
        line-height: 46px;
        color: #ccc;
    }

    .item {
        max-width: 164px;
    }

    .venok {
        width: 100px;
    }

    .cup {
        position: absolute;
        margin-left: -68px;
        margin-top: 20px;
        width: 35px;
    }

    .fio {
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-weight: 400;
        text-align: center;
        font-size: 14px;
        line-height: 20px;
        color:#D7B56D;
    }

    .event {
        font-family: SF LIGHT, sans-serif;
        font-style: normal;
        font-weight: 300;
        text-align: center;
        font-size: 13px;
        line-height: 20px;
        color: var(--text-secondary-color);
    }

    .achiev_name {
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        margin-top: 8px;
        color: var(--text-primary-color);
    }

</style>