<template>
    <b-container fluid class="p-0 pl-3 pl-xl-0 pr-md-0">
        <b-row>
            <b-col class="block m-auto" :style="(getTheme=='light-theme')?'background: url('+require('/src/assets/img/main/gallery_back.png')+')':''">
                <b-row class="wrapper">
                    <b-col>
                        <b-row>
                            <b-col cols="12">
                                <div>
                                    <span class="title"> ГАЛЕРЕЯ &nbsp; <span class="line">|</span></span> &nbsp;<router-link :to="{name: 'gallery'}" class="href">Все альбомы</router-link>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="mt-5" align-v="top">
                            <b-col cols="12" lg="6" v-for="(item, index) in gallery" :key="'gal'+index" class="mb-3">
                                <b-row align-v="center" class="gallery_item">
                                    <b-col cols="12" class="item_image">
                                        <router-link :to="{name: 'gallery', params: { id: item.id}}">
                                            <thumb :src="img_path+item.poster" :alt="item.name + ' (Фотография)'" />                                            
                                        </router-link>
                                    </b-col>
                                    <b-col cols="8" md="6" lg="8" class="mt-3 mb-3">
                                        <router-link :to="{name: 'gallery', params: { id: item.id}}" class="item_name">{{item.name}}</router-link>
                                    </b-col>
                                    <b-col cols="4" md="3" lg="4" class="mt-3 pr-4 text-right ">
                                        <router-link :to="{name: 'gallery', params: { id: item.id}}" class="href float-end">Подробнее</router-link>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import Thumb from '../../components/Thumb.vue';
    export default {
        name: "Gallery",
        components: {
            Thumb,
        },
        data() {
            return {
                img_path: process.env.VUE_APP_IMG
            }
        },
        computed: {
            info: function () {
                return this.$store.getters.allGallery
            },
            gallery: function() {
                    return this.info.slice(0,4);
            },
            getTheme() {
                return this.$store.getters.userTheme
            }
        }
    }
</script>

<style scoped>
    .wrapper {
        max-width: 1150px;
        margin: 48px auto;
    }
    .block {
        width: 100%;
        min-height: 740px;
        background-color: var(--background-color-secondary) !important;
        background-position-x: 400px !important;
        background-position: 640px bottom !important;
        background-repeat: no-repeat !important;
    }

    .item_name {
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-size: 20px;
        line-height: 24px;
        color: var(--text-secondary-color);
        height: 60px;
    }

    .gallery_item {                
        justify-content: top;
    }

    @media all and (max-width: 920px) {
        .block {
            background-position: 240px bottom !important;
        }
    }

    @media all and (max-width: 640px) {
        .item_name {
            font-size: 16px !important;
            line-height: 20px !important;
        }
        .block {
            background-position: 80px bottom !important;
        }
    }

</style>