<template>
    <b-container class="pl-md-2 pl-xl-0">
            <b-tabs pills card
                    active-tab-class="active"
                    nav-class="nav"
                    nav-wrapper-class="nav_wrapper"
                    justified
                    class="mb-5">
                <b-tab active class="pl-0 pr-0 pt-2">
                    <template #title>
                        <b-button variant="info" class="butt">Продукты для тиражирования</b-button>
                    </template>
                    <b-card-text class="content p-3">
                        <b-row v-for="(item, i) in products" :key="'doc'+i" class="mt-2 mb-4">
                            <div class="book_icon pl-3">
                                <img :src="require('/src/assets/img/rrc/book_icon.png')" />
                            </div>
                            <b-col class="description mr-5">
                                <a :href="item.document.path" v-if="item.document"><h4>{{item.name}}</h4></a>
                                <a v-else><h4>{{item.name}}</h4></a>
                                <span v-if="item.description">{{item.description}}</span>
                                <div class="authors mt-3" v-if="item.authors">Авторы: {{item.authors}}</div>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-tab>
                <b-tab class="pl-0 pr-0 pt-2">
                    <template #title>
                        <b-button variant="secondary" class="butt">Региональная инновационная площадка</b-button>
                    </template>
                    <b-card-text class="content p-3">
                        <b-row v-for="(item, i) in articles" :key="'doc'+i" class="mt-2 mb-4">
                            <div class="book_icon pl-3">
                                <img :src="require('/src/assets/img/rrc/book_icon2.png')" />
                            </div>
                            <b-col class="description mr-5">
                                <a :href="item.document.path" v-if="item.document"><h4>{{item.name}}</h4></a>
                                <a v-else><h4>{{item.name}}</h4></a>
                                <span v-if="item.description">{{item.description}}</span>
                                <div class="authors mt-3" v-if="item.authors">Авторы: {{item.authors}}</div>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-tab>

            </b-tabs>
    </b-container>
</template>

<script>
    export default {
        name: "Docs",
        computed: {
            docs: function () {
                return this.$store.getters.rrcDocs;
            },
            products: function() {
                return this.docs.filter(item => {
                    return (item.type==1)
                })
            },
            articles: function() {
                return this.docs.filter(item => {
                    return (item.type==2)
                })
            }
        }
    }
</script>

<style scoped>

    /deep/ .nav-link {
        background-color: var(--background-color-primary);
    }

    /deep/ .active {
        background-color: var(--background-color-primary) !important;
    }


    .butt {
        width: 100%;
        color: #fff;
        border-radius: 8px;
    }

    /deep/ .nav {
        background-color: var(--background-color-secondary) !important;
        border: 0px;
    }

    /deep/ .nav_wrapper {
        background-color: var(--background-color-primary);
        border: 0px;
        margin: 0px -16px;
        padding:0px;
    }

    /deep/ .content {
        background-color: var(--background-color-secondary);
    }

    /deep/ .card-header-pills {
        margin: 0px;
    }

    /deep/ .nav-item {
        padding: 0px;
    }

    li {
        margin: 0px;
        padding: 0px;
    }

    .book_icon {
        width: 50px;
    }

    h4 {
        font-family: SF MEDIUM, sans-serif !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 20px !important;
        margin-top: 4px;
        color: var(--text-secondary-color);
    }

    .description {
        font-family: SF, sans-serif !important;
        font-size: 12px;
        line-height: 15px;
        color: var(--text-secondary-color);
    }

    .authors {
        text-transform: uppercase;
    }


    @media all and (max-width: 600px) {
        .butt {
            font-size: 12px;
        }
    }

</style>