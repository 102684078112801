<template>
    <b-container fluid>
        <b-row>
            <b-col class="mb-5 p-0">
                <course-title></course-title>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-5 p-0">
                <course-description></course-description>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-5 mb-5 p-0">
                <course-team></course-team>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-5 mb-5 p-0">
                <course-shedule></course-shedule>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-5 mb-5 p-0">
                <course-navigator></course-navigator>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-1 mb-5 p-0">
                <course-works></course-works>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-1 mb-5 p-0">
                <course-achievs></course-achievs>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import Title from "./Course/Title";
    import Description from "./Course/Description";
    import Team from "./Course/Team";
    import Shedule from "./Course/Shedule";
    import Navigator from "./Course/Navigator";
    import Works from "./Course/Works"
    import Achievs from "./Course/Achievs";

    export default {
        name: "Course",
        components: {
            'course-title': Title,
            'course-description': Description,
            'course-team': Team,
            'course-shedule': Shedule,
            'course-navigator': Navigator,
            'course-works': Works,
            'course-achievs': Achievs
        },
        mounted() {
            this.$store.dispatch('getCourse', this.$route.params.slug);
        }
    }
</script>

<style scoped>

</style>