<template>
    <b-container class="p-0">
        <b-card pills card
                    active-tab-class="active"
                    nav-class="nav"
                    nav-wrapper-class="nav_wrapper"
                    justified
                    class="mb-5">
                    <b-card-text class="content p-3" v-if="info.docs">
                        <b-row class="mt-2 mb-4" v-for="(doc, i) in filteredDocs"  :key="'doc'+i">
                            <div class="book_icon pl-3">
                                <img :src="require('/src/assets/img/rrc/book_icon.png')" />
                            </div>
                            <b-col class="description mr-5">
                                <a :href="doc.path"  target="_blank"><h4 class="pt-1">{{(doc.title)?doc.title:doc.file_name}}</h4></a>                                
                            </b-col>
                        </b-row>
                    </b-card-text>
        </b-card>
    </b-container>
</template>

<script>
    export default {
        name: "Docs",
        data() {
            return {
                types: ["", "Художественная направленность", "Социально-гуманитарная направленность", "Техническая направленность","Физкультурно-спортивная направленность", "Естественнонаучная направленность"]
            }
        },
        computed: {
            info() {
                return this.$store.getters.allPages;
            },
            products() {
                return this.docs.filter(item => {
                    return (item.type==1)
                })
            },
            filteredDocs(){
                return this.info.docs.filter(item =>{
                    return (item.description === this.types[this.$route.query.id])
                })
            }
        }
    }
</script>

<style scoped>

    .title {
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-size: 14px;
        width: 200px;
        line-height: 17px;
        color: var(--text-primary-color);
        height: 32px;
    }

    .card {
        background-color: var(--background-color-secondary) !important;        
    }

    .content {
        padding-right: 32px;
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 21px;
        color: var(--text-secondary-color);
    }

    .info-card {
        background-color: var(--background-color-secondary);
        border: 0px;
    }

    h4 {
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 13px;
        line-height: 18px !important;
        color: var(--text-primary-color);
    }

    h4:hover {
        color: #F2913A;
    }

    .icon-list {
        font-size: 24px;
        color: #F2913A;
        width: 24px;
    }

    .image_description {
        width: 100%;
        max-width: 540px;
        border-radius: 16px;
    }
</style>