<template>
    <b-container>
        <b-row>
            <b-col class="title p-0">
                Режим работы кассы
            </b-col>
        </b-row>
        <b-row>
            <b-col class="p-0 mb-5">
                <b-card class="form" >
                    <b-row class="pl-4 pr-4 pt-0">
                        <b-col v-for="(day, i) in info" :key="'d'+i" class="text-center mt-3">
                            <div class="circle m-auto" :style="(day.time_begin)?'background-color: #EF7C00':'background-color: #C5C6C6'">{{week[i]}}</div>
                            <div class="time" v-if="day.time_begin">{{time(day.time_begin)}} - {{time(day.time_end)}}</div>
                            <div class="time" v-else>Выходной</div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="time pl-4 ml-2 mt-4 ml-lg-4">
                            Обращаем ваше внимание, расписание кассы возможно будет корректироваться
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Shedule",
        data() {
            return {
                week: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']
            }
        },
        computed: {
            info: function () {
                return this.$store.getters.allKassa;
            }
        },
        methods: {
            time: function (date) {
                let st = date.split(":");
                return st[0]+":"+st[1];
            }
        }
    }
</script>

<style scoped>
    .form {
        min-height: 200px;
        background-color: var(--background-color-secondary);
        border: 0px;
    }

    .title {
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-size: 14px;
        width: 200px;
        line-height: 17px;
        color: var(--text-primary-color);
        height: 32px;
    }

    .circle {
        width: 44px;
        height: 44px;
        background-color: #EF7C00;
        border-radius: 24px;
        padding: 8px 11px;
        text-align: center;
        color: #FFF;
    }

    .time {
        margin-top: 8px;
        margin-left: -4px;
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 11px;
        line-height:  16px;
        color: var(--text-secondary-color);
    }
</style>