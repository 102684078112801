<template>
    <b-container fluid>
        <b-row>
            <b-col class="mb-5 p-0">
                <faq-title></faq-title>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-0 pr-0">
                <faq-description></faq-description>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="p-0">
                <faq-question></faq-question>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import Title from "./Faq/Title";
    import Description from "./Faq/Description";
    import Question from "./Faq/Question";

    export default {
        name: "faq",
        components: {
          'faqTitle': Title,
          'faqDescription': Description,
          'faqQuestion': Question
        },
        mounted(){
            this.$store.dispatch('getPagesInfo', 'faq');
        },
        computed: {
            getTheme() {
                return this.$store.getters.userTheme
            }
        },
    }
</script>

<style scoped>

</style>