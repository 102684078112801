<template>
    <b-container fluid>
        <b-row>
            <b-col class="mb-1 p-0">
                <project-title />
            </b-col>
        </b-row>
        <project-bdd v-if="this.$route.params.slug ==='bdd'" />
        <project-bilet v-if="this.$route.params.slug ==='bilet'" />
        <project-junior v-if="this.$route.params.slug ==='junior'" />
        <project-multilab v-if="this.$route.params.slug ==='multilab'" />
    </b-container>
</template>

<script>
    import BDD from "./Projects/BDD";
    import Bilet from "./Projects/Bilet";
    import Junior from "./Projects/Junior";
    import Title from "./Projects/Title";
    import Multilab from "./Projects/Multilab";

    export default {
        name: "RRC",
        components: {
            'project-bdd': BDD,
            'project-bilet': Bilet,
            'project-junior': Junior,
            'project-title': Title,
            'project-multilab': Multilab
        },
        mounted() {
            this.$store.dispatch('getPagesInfo', 'project');
        }
    }
</script>

<style scoped>

</style>