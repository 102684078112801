<template>
    <b-container fluid>
        <b-row>
            <b-col class="mb-1 p-0">
                <junior-description />
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-0 p-0">
                <junior-documents />
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-0 p-0">
                <junior-footer />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>    
    import Description from "./Junior/Description";
    import Documents from './Junior/Documents.vue';
    import Footer from "./Junior/Footer";
    export default {
        name: "ProjectJunior",
        components: {
            'junior-description': Description,
            'junior-footer': Footer,
            'junior-documents': Documents
        },
        mounted() {
            this.$store.dispatch('getProject', 'junior');
        }
    }
</script>

<style scoped>

</style>