<template>
    <b-container>
        <b-modal ref="modal" v-model="show" id="BigImage" title="BigImage" @hide="hide"  hide-footer hide-header size="xl">
            <b-row>
                <b-col v-if="gallery">
                    <b-carousel
                            id="images"
                            v-model="photo"
                            :interval="0"
                            controls
                            indicators
                            img-width="1024"
                            img-height="480"
                            label-next=""
                            label-prev=""
                    >
                        <b-carousel-slide
                                v-for="(item, index) in gallery"
                                :key="'gal'+index"
                                class="img-size"
                                :img-src="img_path+item.photo"
                        ></b-carousel-slide>
                    </b-carousel>
                </b-col>
                <b-col v-else>
                    <img :src="photo" class="img-size">
                </b-col>
            </b-row>
        </b-modal>
    </b-container>
</template>

<script>
    export default {
        name: "Item",
        watch: {
          showWindow: function(newVal){
              this.photo = this.photoData
              this.gallery = this.galleryData
              this.show = newVal
          }
        },
        props: ['galleryData', 'photoData', 'showWindow'],
        data() {
            return {
                gallery: this.galleryData,
                photo: this.photoData,
                imageIndex: null,
                // img_path: 'http://localhost/dvpion/api/storage/app/media',
                img_path: process.env.VUE_APP_IMG,
                show: this.showWindow
            }
        },
        methods: {
            hide: function(){
                this.show = false;
                this.$emit('hideWindow')
            }
        }
    }
</script>

<style scoped>
    .href {
        cursor: pointer;
    }

    .href:hover {
        opacity: 0.8;
    }


    /deep/ .carousel-control-prev-icon {
        width: 30px;
        height: 48px;
    }
    /deep/ .carousel-control-next-icon {
        width: 30px;
        height: 48px;
    }

    /deep/ .img-size{
        margin: 32px;
        width: 94%;
        max-height: 800px;
        overflow: hidden;
        border-radius: 8px;
    }
    /deep/ .modal-body {
        padding: 0;
    }

</style>