<template>
    <b-container fluid>
        <b-row>
            <b-col class="mb-1 p-0">
                <ovz-title></ovz-title>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-1 p-0">
                <ovz-description></ovz-description>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-1 mt-4 p-0">
                <ovz-gallery></ovz-gallery>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-5 p-0">
                <ovz-maps></ovz-maps>
            </b-col>
        </b-row>
        <!-- <b-row>
            <b-col class="mt-5 p-0">
                <ovz-sign></ovz-sign>
            </b-col>
        </b-row>
        -->
        <b-row>
            <b-col class="mt-0 p-0">
                <ovz-docs></ovz-docs>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import Title from "./OVZ/Title";
    import Description from "./OVZ/Description";
    import Docs from "./OVZ/Docs";
    import Maps from "./OVZ/Maps";
    // import Sign from "./OVZ/Sign";
    import Gallery from "./OVZ/Gallery";

    export default {
        name: "OVZ",
        components: {
            'ovz-title': Title,
            'ovz-description': Description,
            'ovz-docs': Docs,
            'ovz-maps': Maps,
            // 'ovz-sign': Sign,
            'ovz-gallery': Gallery
        },
        mounted() {
            this.$store.dispatch('getPagesInfo', 'ovz');
            this.$store.dispatch('getOVZDetails');
        }
    }
</script>

<style scoped>

</style>