<template>
    <b-container fluid class="pl-3 pr-3">
        <b-modal v-model="show" hide-footer hide-header size="md" content-class="testWindow">
            <b-row class="p-4"
                   :style="'background: url('+require('/src/assets/img/test/back.png')+'); border-radius: 16px'">
                <b-col>
                    <b-card class="quiz_back">
                        <b-row>
                            <b-col class="text-center">
                                <h3 class="mb-3">Документы</h3>
                                <table class="tab m-auto w-100">
                                    <tbody>
                                        <tr
                                        v-for="(item, i) in info.document"
                                        :key="'doc'+i"
                                        >
                                            <td>{{ item.title || item.file_name }}</td>
                                            <td class="text-center"><a :href="item.path" target="_blank">скачать</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </b-modal>
        <div @click="show = true" class="href">
                <b-icon icon="file-earmark-text" class="icon-list mt-2 mr-2" />
                Документы
        </div>
    </b-container>
</template>


<script>
    export default {
        name: "EventDocuments",
        data() {
            return {
                show: false
            }
        },
        computed: {
            info: function(){
                return this.$store.getters.currentEvent;
            }
        }
    }
</script>


<style scoped>
    .quiz_back {
        background-color: var(--background-color-primary);
    }

    .table {
        border-collapse: collapse;
    }

    .tab td {
        padding: 4px 8px;
        text-align: left;
        border: 1px #ccc solid;
    }

    h3 {
        font-family: SF bold, sans-serif;
        font-style: normal;
        font-size: 20px;
    }

    .href {
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        color: #F2913A;
        text-decoration: none;
        cursor: pointer;
    }

    .href:hover {
        color: #af483a;
    }

    .href:hover .icon-list {
        color: #af483a;
    }
</style>