<template>
    <b-container fluid="xl">
        <b-row class="mt-5">
            <b-container class="pl-4 pl-xl-3">
                <b-col cols="12" class="p-0">
                    <b-row>
                        <b-col cols="12" lg="5">
                            <b-row>
                                <b-col class="description pl-0" v-html="info.description"></b-col>
                            </b-row>
                            <b-row class="mt-3 d-none d-lg-block" v-if="info.document">
                                <b-col class="href p-0">
                                    <a :href="info.document.path">
                                        <b-icon icon="file-earmark-text" class="icon-list mr-2"></b-icon>Положение о РЦ
                                    </a>
                                </b-col>
                            </b-row>
                            <b-row class="mt-3 d-none d-lg-block" v-if="info.document2">
                                <b-col class="href p-0">
                                    <a :href="info.document2.path">
                                        <b-icon icon="file-earmark-text" class="icon-list mr-2"></b-icon>План
                                    </a>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="12" lg="7" class="mt-4 mt-lg-0 p-0" v-if="info.image_description">
                            <img  :src="(getTheme=='light-theme')?info.image_description.path:(info.image_description_dark)?info.image_description_dark.path:info.image_description.path" class="image_description">
                            <b-row class="mt-4 ml-0 d-block d-lg-none" v-if="info.document">
                                <b-col class="href p-0">
                                    <a :href="info.document.path">
                                        <b-icon icon="file-earmark-text" class="icon-list mr-2"></b-icon>Положение о РЦ
                                    </a>
                                </b-col>
                            </b-row>
                            <b-row class="mt-3 ml-0 d-block d-lg-none" v-if="info.document2">
                                <b-col class="href p-0">
                                    <a :href="info.document2.path">
                                        <b-icon icon="file-earmark-text" class="icon-list mr-2"></b-icon>План
                                    </a>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-col>
            </b-container>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Description",
        computed: {
            info: function () {
                return this.$store.getters.allPages;
            },
            getTheme() {
                return this.$store.getters.userTheme
            }
        }
    }
</script>

<style scoped>
    /deep/ h4 {
        font-family: SF MEDIUM, sans-serif !important;
        font-weight: 300 !important;
        font-size: 14px !important;
        line-height: 16px !important;
        color: var(--text-secondary-color);
    }
    .description {
        font-family: SF, sans-serif !important;
        font-style: normal;
        font-size: 13px !important;
        line-height: 16px !important;
        color: var(--text-secondary-color);
    }

    .image_description {
        width: 100%;
    }

    .icon-list {
        font-size: 24px;
        color: #F2913A;
    }

</style>