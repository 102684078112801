<template>
    <b-container fluid>
        <b-row>
            <b-col class="mb-5 p-0">
                <parents-title></parents-title>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-0 pr-0">
                <description></description>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="pt-5 pb-5" :style="(getTheme=='light-theme')?'background: url('+require('/src/assets/img/choose_course_back.png')+') #F7F7F7 no-repeat; background-position: 60% -50%':'background-color: var(--background-color-primary);'">
                <courses></courses>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-5 mt-0 p-0">
                <online></online>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-5 mt-0 p-0">
                <quiz></quiz>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="p-0">
                <question></question>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import Title from "./Parents/Title";
    import Description from "./Parents/Description";
    import Courses from "./Parents/ChooseCourse";
    import Online from "./Parents/Online";
    import Question from "./Parents/Question";
    import Quiz from "./Parents/Quiz";

    export default {
        name: "Parents",
        components: {
          'parentsTitle': Title,
          Description,
          Courses,
          Online,
          Question,
          Quiz
        },
        mounted(){
            this.$store.dispatch('getPagesInfo', 'parents');
            this.$store.dispatch('getCourseTypeList');
            this.$store.dispatch('getCourseFilterAge');
        },
        computed: {
            getTheme() {
                return this.$store.getters.userTheme
            }
        },
    }
</script>

<style scoped>

</style>