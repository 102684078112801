<template>
    <b-container class="p-3 mb-5" v-if="info">
        <b-row>
            <b-col class="text-center">
                <img :src="img_path+image_photo" class="image" />
            </b-col>
        </b-row>
        <b-row>
            <b-col class="content text-center mt-5 mb-4">
                <h3>Этапы реализации проекта</h3>
            </b-col>
        </b-row>
        <b-row style="background-color: #f5f5f5">
            <b-col class="text-center mt-5 mb-5">
                <img :src="img_path+image_footer" class="image_footer" />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    
    export default {
        name: 'ProjectsBiletFooter',
        data() {
            return {
                img_path: process.env.VUE_APP_IMG
            }
        },        
        computed: {
            info() {
                return this.$store.getters.getProject
            },
            image_photo() {
                return this.info.pics.find(item => item.label == "photo").images
            },
            image_footer() {
                return this.info.pics.find(item => item.label == "footer").images
            }
        }
    }
</script>

<style scoped>

    h3 {
        font-family: 'SF';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        color: var(--text-primary-color);
    }

    .content {
        font-family: 'SF Light';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
    }

    .image {
        width: 100%;
    }

    .image_footer {
        width: 80%;
    }
</style>