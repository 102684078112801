<template>
    <b-container fluid>
        <b-row>
            <b-col class="mb-1 p-0">
                <shedule-title></shedule-title>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-1 mt-4 p-0 ml-2 mr-2 ml-xl-0">
                <shedule-docs></shedule-docs>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import Title from "./SheduleDocs/Title";
    import Docs from "./SheduleDocs/Docs";
    export default {
        name: "SheduleDocs",
        components: {
            'shedule-title': Title,
            'shedule-docs': Docs
        },
        mounted() {
            this.$store.dispatch('getPagesInfo', 'shedule');
        }
    }
</script>

<style scoped>

</style>