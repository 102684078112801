<template>
    <b-container fluid class="p-0 pl-1 pl-sm-3 pl-xl-0 pr-md-0">
        <b-row>
            <b-col class="image_back m-auto" :style="(getTheme=='light-theme')?'background-image: url('+require('/src/assets/img/about_back.jpg')+')':'background-color: var(--background-color-secondary)'">
                <b-row class="wrapper">

                    <b-col class="d-none d-xl-block">
                        <div class="main_img"><img :src="require('/src/assets/img/about.png')"></div>
                                <div class="pt-3 pb-0 pb-md-0 float-left position-absolute">
                                    <span class="title"> О ДВОРЦЕ</span>
                                </div>
                        <b-row class="mb-2 mt-4">
                            <b-col class="href_tour">
                                <a href="https://old.dvpion.ru/konkurs/2021/vr_tour/virtour-dvpion.html" target="_blank">Виртуальный тур по Дворцу</a>
                            </b-col>
                        </b-row>
                        <b-row class="mb-3 mt-0">
                            <b-col cols="12" offset="0" xl="6" offset-xl="6" class="text-right slogan">
                                Будущее<br>начинается здесь
                            </b-col>
                        </b-row>
                        <b-row class="mb-4">
                            <b-col cols="12" offset="0" xl="6" offset-xl="6" class="text-right description" v-html="info.about">
                            </b-col>
                        </b-row>
                        <b-row class="pl-5">
                            <b-col offset="0" md="6" offset-md="6" class="mt-2">
                                <b-row>
                                    <b-col xl="3" lg="4" cols="6" v-for="(item, index) in stat" :key="'stat'+index" class="pl-1 pr-1">
                                        <div class="stat_num">
                                            {{item.number}}
                                        </div>
                                        <div class="stat_text">
                                            {{item.name}}
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="mt-1">
                            <b-col class="text-right pb-0 mb-0">
                                <router-link :to="{name: 'about'}" class="href float-end">Подробнее</router-link>
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col class="d-block d-xl-none">
                        <b-row>
                            <b-col>
                                <div class="ml-3 pt-3 pb-3 pb-md-0">
                                    <span class="title"> О ДВОРЦЕ</span>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-center">
                                <div class="main_img_center"><img :src="require('/src/assets/img/about.png')"></div>
                            </b-col>
                        </b-row>

                        <b-row class="mb-0 mt-4">
                            <b-col class="href_tour text-center">
                                Виртуальный тур по Дворцу
                            </b-col>
                        </b-row>
                        <b-row class="mb-3">
                            <b-col cols="12" offset="0" xl="6" offset-xl="6" class="text-center slogan">
                                Будущее начинается здесь
                            </b-col>
                        </b-row>
                        <b-row class="mb-4">
                            <b-col cols="12" offset="0" xl="6" offset-xl="6" class="description_center" v-html="info.about">
                            </b-col>
                        </b-row>
                        <b-row class="pl-md-4 pl-lg-5 pr-4">
                            <b-col cols="12" md="10" lg="8" class="mt-2 mt-md-3 pb-3 m-auto">
                                <b-row>
                                    <b-col cols="6" sm="3" v-for="(item, index) in stat" :key="'stat'+index" class="pl-1 pr-1">
                                        <div class="stat_num">
                                            {{item.number}}
                                        </div>
                                        <div class="stat_text">
                                            {{item.name}}
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="mt-1">
                            <b-col class="text-center pb-0 mb-0">
                                <router-link :to="{name: 'about'}" class="href float-end">Подробнее</router-link>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "About",
        data() {
            return {
                windowHeight: window.innerWidth,
                txt: ''
            }
        },
        watch: {
            windowHeight(newHeight, oldHeight) {
                this.txt = `it changed to ${newHeight} from ${oldHeight}`;
            }
        },
        computed: {
            info() {
                return this.$store.getters.allInfo
            },
            stat() {
                return this.$store.getters.allInfoStat
            },
            getTheme() {
                return this.$store.getters.userTheme
            }
        },

        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize);
            })
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        methods: {
            onResize() {
                this.windowHeight = window.innerWidth
            }
        }
    }
</script>

<style scoped>
    .wrapper {
        max-width: 1150px;
        margin: 24px auto;
        min-height: 464px;
    }

    .image_back {
        width: 1920px;
        height: 490px;
        background-size: 1920px;
        background-position: bottom;
        background-repeat: no-repeat;
    }

    .main_img {
        position: absolute;
        bottom:0px;
    }

    .main_img_center img {
        width: 100%;
        max-width: 520px;
        min-width: 300px;
    }

    .main_img img {
        width: 520px;
    }

    .slogan {
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-size: 36px;
        line-height: 40px;
        text-align: right;
        color: var(--text-secondary-color);
    }

    .description {
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-size: 13px;
        line-height: 16px;
        color: var(--text-secondary-color);
        text-align: right;
    }

    .description_center {
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        text-align: center;
        max-width: 600px;
        margin: auto;
        font-size: 13px;
        line-height: 16px;
        color: var(--text-secondary-color);
    }

    .stat_num {
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-size: 32px;
        line-height: 32px;
        color: #FF6F03;
        text-align: center;
        margin-bottom: 4px;
    }

    .stat_text {
        font-family: SF, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 10px;
        line-height: 16px;
        color: var(--text-primary-color);
        text-align: center;
    }


    .href {
        font-family: SF, sans-serif;
        font-style: normal;
        color: #F2913A;
        font-size: 16px;
    }
    .href:hover {
        color: #9AAF22;
    }

    .href_tour { font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-size: 16px;
        cursor: pointer;
        line-height: 32px;
        color: #FF6F03;
        text-align: right;
        margin-bottom: 4px;
    }
    .href_tour:hover {
        color: #9AAF22;
    }

    @media all and (max-width: 1200px) {
        .image_back {
            min-height: 894px;
        }
    }

    @media all and (max-width: 640px) {
        .image_back {
            min-height: 920px;
        }

        .stat_text {
            padding: 0px 16px;
        }
    }
</style>