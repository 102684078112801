<template>
    <b-container fluid class="p-0">
        <div class="wrapper"></div>
        <b-row class="m-0 pl-0 pr-0 pl-md-2 pr-md-2">
            <b-col class="pt-0 pb-0 pl-4 pr-4 pl-sm-4 pr-sm-4">
                <b-container fluid="xl">
                    <b-row>
                        <b-col class="cloud pt-5 mr-3">
                            <div class="nil">
                                <img src="../../assets/img/course/shedule_nib.png" v-if="(getTheme=='light-theme')">
                                <img src="../../assets/img/course/shedule_nib_dark.png" v-else>
                            </div>

                            <b-row class="mb-3">
                                <b-col class="main_title text-center">Расписание занятий</b-col>
                            </b-row>
                            <b-row class="mb-5">
                                <b-col>
                                    <div style="max-width: 630px" class="m-auto">
                                        <b-row>
                                            <b-col cols="12" lg="5" class="m-3 mb-0 m-lg-0 pt-1 subtitle">Показать расписание для</b-col>
                                            <b-col cols="12" lg="7" class="ml-3 mt-0 m-lg-0 pr-5 pr-lg-0">
                                                <b-form-select v-model="teacher" :options="options" class="p-2 pr-4 select-form"
                                                               @change="showShedule"></b-form-select>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="shedule_block">
                                    <b-row class="mb-5" v-if="teacher&&shed.list">
                                        <carousel
                                                :perPageCustom="[[200, 1],[500, 2], [680, 3], [850, 4], [980, 5], [1200, 7]]"
                                                mouseDrag
                                                loop
                                                navigationEnabled
                                                :paginationEnabled = "false"
                                                :speed="2000"
                                        >
                                            <slide  v-for="(day, i) in days" :key="'day-title'+i">
                                                <div cols="12" md="6" lg="2" class="day_column">
                                                    <b-row class="mb-3">
                                                        <b-col class="day_title">{{day}}</b-col>
                                                    </b-row>
                                                    <b-row v-if="(shed.list[i+1])&&(groupColors.length)">
                                                                <b-col v-for="(group, j) in shed.list[i+1]" :key="'group-title'+j" cols="12" class="mb-3">
                                                                    <b-card class='shedule_card' :style="'background-color: '+groupColor(group.number, 'back')">
                                                                        <b-card-text>
                                                                            <div class="day_time" :style="'color: '+groupColor(group.number, 'font')">{{time(group.time_begin)}} - {{time(group.time_end)}}</div>
                                                                            <div class="day_name" :style="'color: '+groupColor(group.number, 'font')">{{group.name}}</div>
                                                                            <div class="day_cabinet" :style="'color: '+groupColor(group.number, 'font')+'; margin-top: -12px; line-height: 14px'" v-if="group.cabinet.includes('http')"><a class="href" :href="group.cabinet" target="_blank">дистанционная платформа</a></div>
                                                                            <div class="day_cabinet" :style="'color: '+groupColor(group.number, 'font')" v-else>Каб №{{group.cabinet}}</div>
                                                                        </b-card-text>
                                                                    </b-card>
                                                                </b-col>
                                                    </b-row>
                                                </div>
                                            </slide>
                                        </carousel>
                                    </b-row>

                                    <b-row v-if="!teacher&&shed.list" class="mb-5">
                                        <carousel
                                                :perPageCustom="[[200, 1], [500, 2], [680, 3], [850, 4], [980, 5], [1200, 7]]"
                                                mouseDrag
                                                loop
                                                navigationEnabled
                                                :paginationEnabled = "false"
                                                :speed="1000"
                                        >
                                            <slide  v-for="(day, i) in days" :key="'day-title'+i">
                                                <div class="day_column">
                                                    <b-row class="mb-3">
                                                        <b-col class="day_title">{{day}}</b-col>
                                                    </b-row>
                                                    <b-row v-if="(shed.list[i+1])&&(groupColors.length)">
                                                        <b-col cols="12" v-for="(group, j) in shed.list[i+1]" :key="'group-title'+j"
                                                               class="mb-3">
                                                            <b-card class='shedule_card' :style="'background-color: '+groupColor(group.number, 'back')">
                                                                <b-card-text>
                                                                    <div class="day_time" :style="'color: '+groupColor(group.number, 'font')">{{time(group.time_begin)}} - {{time(group.time_end)}}</div>
                                                                    <div class="day_name" :style="'color: '+groupColor(group.number, 'font')">{{teacher_small(group.teacher)}}</div>
                                                                    <div class="day_cabinet" :style="'color: '+groupColor(group.number, 'font')+'; margin-top: -12px; line-height: 14px'" v-if="group.cabinet.includes('http')"><a class="href" :href="group.cabinet" target="_blank">дистанционная платформа</a></div>
                                                                    <div class="day_cabinet" :style="'color: '+groupColor(group.number, 'font')" v-else>Каб №{{group.cabinet}}</div>
                                                                </b-card-text>
                                                            </b-card>
                                                        </b-col>
                                                    </b-row>
                                                </div>
                                            </slide>
                                        </carousel>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-container>
            </b-col>
        </b-row>
    </b-container>
</template>


<script>
    import moment from 'moment';
    import { Carousel, Slide } from 'vue-carousel';
    //import { Icon } from '@iconify/vue2';
    //import chevronRight from '@iconify/icons-mdi/chevron-right';

    export default {
        name: "Shedule",
        components: {
            'carousel': Carousel,
            'slide': Slide,
            //Icon
        },
        watch: {
            course: function(newVal){
                this.$store.dispatch('getSheduleByCourse', newVal.id);
            },
            shed: function (newVal) {
                let i=0;
                if (newVal.groups) {
                    newVal.groups.forEach((item, index) => {
                        this.groupColors[index] = {}
                        this.groupColors[index].number = item;
                        this.groupColors[index].color = this.colors[i];
                        if (this.colors[i+1]) i++; else i=0;
                    });
                }
            }
        },
        data() {
            return {
                teacher: 0,
                icons: {
                    //chevronRight,
                },
                days: [
                    'Понедельник',
                    'Вторник',
                    'Среда',
                    'Четверг',
                    'Пятница',
                    'Суббота',
                    'Воскресение'
                ],
                colors: [
                    {backColor: '#FECB07', fontColor: '#333'},
                    {backColor: '#52b6d8', fontColor: '#FFF'},
                    {backColor: '#d3abd0', fontColor: '#333'},
                    {backColor: '#a7be21', fontColor: '#FFF'},
                    {backColor: '#ae498d', fontColor: '#FFF'},
                    {backColor: '#f8f8f8', fontColor: '#333'},
                    {backColor: '#ae498d', fontColor: '#FFF'},
                    {backColor: '#bbdbb5', fontColor: '#333'},
                    {backColor: '#f08219', fontColor: '#FFF'},
                    {backColor: '#285aa4', fontColor: '#FFF'},
                    {backColor: '#816fb0', fontColor: '#FFF'},
                    {backColor: '#f6b9bb', fontColor: '#333'},
                    {backColor: '#FECC00', fontColor: '#333'},
                    {backColor: '#2DB2DD', fontColor: '#FFF'},
                    {backColor: '#E31E24', fontColor: '#FFF'},
                    {backColor: '#AE4A84', fontColor: '#FFF'},
                    {backColor: '#9AAF22', fontColor: '#FFF'}
                ],
                groupColors: []
            }
        },
        computed: {
            course: function () {
                return this.$store.getters.currentCourse;
            },
            shed: function () {
                return this.$store.getters.currentShedule;
            },
            options: function () {
                let list = [];
                list.push({'value': 0, 'text': 'Все преподаватели'});
                if (this.course) {
                    let t = this.course.teacher;

                    t.forEach((item) => {
                        list.push({'value': item.id, 'text': item.fio});
                    });
                }
                return list;
            },
            getTheme() {
                return this.$store.getters.userTheme
            }
        },
        methods: {
            showShedule: function () {
                if (this.teacher)
                this.$store.dispatch('getGroupSheduleByTeacher', this.teacher);
                else
                this.$store.dispatch('getSheduleByCourse', this.course.id);
            },
            time: function (date) {
                return moment(date, 'HH:mm').locale('ru').format('HH:mm');
            },
            teacher_small: function(fio){
                let fio2 = fio.split(' ');
                return fio2[0]+" "+fio2[1][0]+". "+fio2[2][0]+".";
            },
            groupColor(group, type){
                if (type === 'back'){
                    const number = this.groupColors.findIndex(item => item.number === group)
                    return this.groupColors[number].color.backColor
                }
                if (type === 'font'){
                    const number = this.groupColors.findIndex(item => item.number === group)
                    return this.groupColors[number].color.fontColor
                }
            }
        },
        mounted() {

        }
    }
</script>

<style scoped>

    .wrapper {
        position: absolute;
        margin-top: 80px;
        background-color: var(--background-color-secondary);
        height: 800px;
        width: 100%;
    }

    .day_column {
        width: 140px;
        margin-left: 6px;
        margin-right: 6px;
    }
    .main_title {
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-size: 48px;
        line-height: 56px;
        color: #ccc;
    }

    .subtitle {
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 20px;
        color: #333;
    }

    .cloud {
        border: 2px solid var(--background-input-border);
        border-radius: 16px;
        background-color: var(--background-color-primary);
        min-height: 800px;
    }

    .nil {
        position: absolute;
        margin-top: -90px;
        margin-left: 30%;
    }

    .select-form {
        background-color: var(--background-input);
        border: 1px solid #999;
        border-radius: 8px;
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 20px;
        color: #333;
    }

    .day_title {
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 14px;
        color: #333;
        text-align: center;
    }

    /deep/ .card-body {
        padding: 8px 12px !important;
    }

    .shedule_block {
        padding-left: 36px;
    }

    .shedule_card {
        border: 0px;
        border-radius: 16px;
        height: 120px;
    }

    .day_time, .day_name, .day_cabinet {
        font-family: SF LIGHT, sans-serif;
        font-style: normal;
        font-size: 12px;
        color: #333;
        text-align: left;
    }

    .day_time {
        text-decoration: underline;
        text-align: center;
    }

    .day_name {
        font-family: SF MEDIUM, sans-serif;
        margin-top: 8px;
        height: 60px;
        text-align: center;
    }

    .day_cabinet {
        text-align: center;
    }

    .href {
        font-family: SF LIGHT, sans-serif;
        font-style: normal;
        font-size: 12px;
        color: #333;
        text-align: left;
        text-decoration: underline;
        line-height: 14px;
    }

    .href:hover {
        color: #F2913A;
    }


    @media all and (max-width: 1200px) {
        /deep/ .VueCarousel {
            width: 92%;
        }
        .shedule_block {
            padding-left: 80px;
        }
    }

    @media all and (max-width: 920px) {
        /deep/ .VueCarousel {
            width: 88%;
        }
        .shedule_block {
            padding-left: 80px;
        }
    }

    @media all and (max-width: 499px) {
        /deep/ .VueCarousel {
            width: 88%;
        }

        .shedule_block {
            padding-left: 50px;
        }

        .day_column {
            margin-left: 84px;
        }
    }

    @media all and (max-width: 400px) {

        .day_column {
            margin-left: 44px;
        }
    }

</style>