<template>
    <b-container class="p-0 p-md-3 pt-0 pt-md-0 pb-md-0">
        <b-modal id="BigImage" title="BigImage"  hide-footer hide-header size="xl">
            <b-row>
                <b-col v-if="gallery[0]">
                    <b-carousel
                            id="images"
                            v-model="imageIndex"
                            :interval="0"
                            controls
                            indicators
                            background="#ababab"
                            img-width="1024"
                            img-height="480"
                            label-next=""
                            label-prev=""
                    >
                        <b-carousel-slide
                                v-for="(item, index) in gallery[0].photo"
                                :key="'gal'+index"
                                :img-src="img_path+item.photo"
                        ></b-carousel-slide>
                    </b-carousel>
                </b-col>
            </b-row>
        </b-modal>

        <b-row class="mt-5 mb-5" v-if="gallery[0]">
            <b-col cols="12" md="6" xl="4" v-for="(item, index) in gallery[0].photo" :key="'gal'+index" class="mb-4">
                <b-card :img-src="img_path+item.photo"
                        class="shadow href"
                        @click="showImage(index)" no-body></b-card>
            </b-col>
        </b-row>

    </b-container>
</template>

<script>
    export default {
        name: "Item",
        data() {
            return {
                imageIndex: null,
                img_path: process.env.VUE_APP_IMG
            }
        },
        computed: {
            idGallery: function(){
                return this.$route.params.id;
            },
            gallery: function(){
                return this.$store.getters.allGallery.filter((item)=>{
                    return item.id == this.$route.params.id
                })
            }
        },
        methods: {
            showImage: function (i) {
                this.imageIndex = i;
                this.$bvModal.show('BigImage');
            }
        }
    }
</script>

<style scoped>
    .href {
        cursor: pointer;
    }

    .href:hover {
        opacity: 0.8;
    }


    /deep/ .carousel-control-prev-icon {
        width: 30px;
        height: 48px;
    }
    /deep/ .carousel-control-next-icon {
        width: 30px;
        height: 48px;
    }

    /deep/ .img-size{
        height: 750px;
        width: 1200px;
        background-size: cover;
        overflow: hidden;
    }
    /deep/ .modal-body {
        padding: 0;
    }

</style>