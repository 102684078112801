<template>
     <b-container class="p-0 mt-5">
        <b-row>
            <b-col cols="12" md="6" lg="4" v-if="info">
                <b-row>
                    <b-col>
                        <h3>Региональная профильная смена</h3>
                    </b-col>
                </b-row>
                <b-row class="mt-3" v-if="info.description">
                    <b-col class="content" v-html="description[1]">                
                    </b-col>
                </b-row>
            </b-col>
            <b-col class="text-right" v-if="info.pics">
                <img :src="img_path+image" class="image" />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "ProjectBddSmena",
        data() {
            return {
                img_path: process.env.VUE_APP_IMG
            }
        },        
        computed: {
            info() {
                return this.$store.getters.getProject
            },
            image() {
                return this.info.pics.find(item => item.label == "photo").images
            },
            description: function(){
                return this.info.description.split("<hr>")
            }
        },
    }
</script>

<style lang="scss" scoped>
  h3 {
        font-family: 'SF Light';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;

        color: var(--text-primary-color);
    }

    .content {
        font-family: 'SF Light';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* or 133% */


        color: var(--text-primary-color);
    }

    .image {
        width: 100%;
        max-width: 575px;
    }
</style>