<template>
    <b-container fluid class="p-0">
        <b-row class="m-0">
            <b-col :style="(getTheme=='light-theme')?'background: url('+require('/src/assets/img/footer_back.png')+') #5B5B5B no-repeat':'background-color: var(--background-color-secondary)'"  class="footer">
                <b-container fluid="lg">
                    <b-row class="mt-5">
                        <div class="logo pl-1 pl-md-4 mb-3">
                            <img :src="info.logo_footer.path" v-if="info.logo_footer" class="m-2 ml-lg-0 mt-lg-0">
                        </div>
                        <b-col>
                            <b-row class="d-none d-lg-flex">
                                <b-col cols="12" xl="6" class="p-0 mb-3 d-none d-xl-block">
                                    <div class="link mt-2" v-for="(item, index) in menu" :key="'l'+index">
                                        <a :href="item.url">{{item.title}}</a>
                                    </div>
                                </b-col>
                                <b-col cols="12" xl="6" class="p-0 mb-3">
                                    <div class="link mt-2">
                                        <a href="projects">Региональные проекты</a>
                                    </div>
                                    <div class="link mt-2">
                                        <a href="contacts">Контакты</a>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                        <div class="contacts mb-3 ml-0 mr-0 pr-0 pr-md-3">
                            <b-row class="link mt-2">
                                <b-col>
                                <Icon icon="ci:location" color="white" height="30" style="width: 40px" class="float-left" :rotate="2" :verticalFlip="true" />
                                <div v-html="info.adress" class="nl2br float-left ml-2"></div>
                                </b-col>
                            </b-row>
                            <b-row class="link mt-2">
                                <b-col>
                                    <Icon icon="carbon:phone-voice-filled" color="white" style="width: 40px" height="22" :rotate="2"
                                          :horizontalFlip="true" :verticalFlip="true" />
                                    &nbsp;{{info.phone}}
                                </b-col>
                            </b-row>
                            <b-row class="link mt-2">
                                <b-col>
                                    <Icon icon="entypo:email" color="white" height="20" style="width: 40px" :rotate="2" :horizontalFlip="true"
                                          :verticalFlip="true"/>
                                    &nbsp;{{info.mail}}
                                </b-col>
                            </b-row>
                            <b-row class="link mt-2" v-if="info.vk">
                                <b-col>
                                    <a :href="info.vk" target="_blank">
                                        <Icon icon="cib:vk" color="white" :rotate="2" style="width: 40px" :horizontalFlip="true"
                                              :verticalFlip="true"
                                              height="20"/>
                                        &nbsp;{{shortLink(info.vk)}}
                                    </a>
                                </b-col>
                            </b-row>
                            <div class="link mt-2" v-if="info.instagram">
                                <a :href="info.instagram" target="_blank">
                                    <Icon icon="uil:instagram-alt" color="white" height="20" :rotate="2"
                                          :horizontalFlip="true" :verticalFlip="true"/>
                                    &nbsp;{{shortLink(info.instagram)}}
                                </a>
                            </div>
                        </div>
                    </b-row>
                </b-container>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import {Icon} from '@iconify/vue2';

    export default {
        name: "Footer",
        components: {
            Icon
        },
        computed: {
            info: function () {
                return this.$store.getters.allInfo
            },
            menu: function () {
                return this.$store.getters.allFooterMenu
            },
            getTheme() {
                return this.$store.getters.userTheme
            }
        },
        mounted() {
            this.$store.dispatch('getFooterMenu')
        },
        methods: {
            shortLink: link => {
                let l = link.split('/');
                return l[l.length - 1]
            }
        }
    }
</script>

<style scoped>
    .footer {
        min-height: 280px;
        padding-right: 40px;
    }

    .footer img {
        width: 100%;
        max-width: 280px;
        min-width: 120px;
    }

    .logo{
        width: 360px;
    }

    .contacts
    {
        width: 220px;
    }

    .link, .link a {
        font-family: SF LIGHT, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #FFF;
        text-decoration: none;
    }

    .link a:hover {
        color: #f1a84e;
    }

    .nl2br {
        white-space: pre;
    }

    @media all and (max-width: 1220px) {
        .logo{
            width: 420px !important;
        }
    }

    @media all and (max-width: 920px) {
        .logo {
            width: 300px !important;
        }
    }

    @media all and (max-width: 640px) {
        .link, .link a {
            font-size: 12px;
            line-height: 16px;
        }
        .contacts
        {
            width: 180px;
        }
        .logo {
            width: 180px !important;
        }
    }
</style>