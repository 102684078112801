<template>
    <b-container fluid="xl" class="pl-3 pr-3 pl-lg-3 pr-lg-5 pl-xl-3 pr-xl-3">
        <b-row align-v="top">
            <b-col cols="12" lg="6">
                <b-row v-if="about.description">
                    <b-col class="content pl-0" cols="12" xl="12" v-html="description[0]">
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" lg="6" class="text-right">
                <b-row>
                    <b-col class="pl-0 description_image">
                        <img src="../../assets/img/about/about_image1.png">
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-col cols="6" sm="3" v-for="(item, index) in stat" :key="'stat'+index" class="pl-1 pr-1">
                        <div class="stat_num">
                            {{item.number}}
                        </div>
                        <div class="stat_text">
                            {{item.name}}
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row class="mt-4 mb-4" v-if="about.description">
            <b-col cols="12" lg="4" class="pl-0 description_image d-block d-lg-none text-center">
                <img :src="about.image_description.path" v-if="about.image_description">
            </b-col>
            <b-col class="content pl-0 mt-4 mt-lg-0" cols="12" lg="8" v-html="description[1]">
            </b-col>
            <b-col cols="12" lg="4" class="description_image d-none d-lg-block text-center">
                <img :src="about.image_description.path" v-if="about.image_description">
            </b-col>
        </b-row>
        <b-row>
            <b-col class="pl-0">
                <iframe src="https://old.dvpion.ru/konkurs/2021/vr_tour/virtour-dvpion.html" width=1100 height=450 style="border: 0px">
                </iframe>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="footer_image mt-2 pl-0">
                <img src="../../assets/img/about/about_image2.png" class="image_description">
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Description",
        computed: {
            about: function () {
                return this.$store.getters.allPages;
            },
            stat: function () {
                return this.$store.getters.allInfoStat
            },
            description: function () {
                return this.about.description.split("<hr>");
            }
        }
    }
</script>

<style scoped>

    .stat_num {
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-size: 32px;
        line-height: 32px;
        color: #FF6F03;
        text-align: center;
        margin-bottom: 4px;
    }

    .stat_text {
        font-family: SF, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 10px;
        line-height: 16px;
        color: var(--text-secondary-color);
        text-align: center;
    }

    .content {
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        color: var(--text-secondary-color);
    }

    .footer_image img, .description_image img{
        width: 100%;
    }
</style>