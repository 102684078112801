<template>
    <b-container fluid="xl" class="wrapper pl-1 pl-sm-3 pl-xl-0 pr-md-0">
        <b-row v-if="info" class="pl-5 pl-xl-4 pr-xl-0 pr-5 d-none d-md-block">
            <div class="logo float-left">
                <a :href="info.href" target="_blank"><img :src="info.image.path" v-if="info.image"></a>
            </div>
            <b-col>
                <b-row>
                    <b-col cols="12" md="5" class="title pl-4">
                        <a :href="info.href" target="_blank">{{info.name}}</a>
                    </b-col>
                    <b-col cols="12" md="7" class="description pt-3">
                        {{info.description}}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row v-if="info" class="pl-1 pl-sm-5 pl-xl-4 pr-1 pr-sm-5 pr-xl-0 pr-1 pr-sm-5 d-flex d-md-none" align-h="center">
                <b-col cols="3" md="3"  class="logo">
                    <a :href="info.href" target="_blank"><img :src="info.image.path" v-if="info.image"></a>
                </b-col>
                <b-col cols="6" md="5" class="title_md pl-2">
                    <a :href="info.href" target="_blank">{{info.name}}</a>
                </b-col>
        </b-row>
        <b-row class="d-block d-md-none mt-5 pl-1 pl-sm-5 pl-xl-4 pr-1 pr-sm-5 pr-xl-0">
            <b-col cols="12" lg="7" xl="7" class="description pt-0">
                {{info.description}}
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Navigator",
        computed: {
            info: function () {
                return this.$store.getters.allNavigator
            }
        }
    }
</script>

<style scoped>

    .logo{
        width: 132px;
    }

    .logo img {
        width: 100%;
    }
    .title {
        padding-left: 28px;
        padding-top: 16px;
    }

    .title_md {
        padding-left: 28px;
        padding-top: 16px;
    }

    .title a, .title_md a {
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-size: 24px;
        line-height: 28px;
        color: var(--text-primary-color);
        text-transform: uppercase;
        text-decoration: none;
    }

    .description {
        font-family: SF LIGHT, sans-serif;
        padding-left: 32px;
        margin-top: -12px;
        text-align: right;
        font-style: normal;
        color: var(--text-secondary-color);
        font-size: 14px;
        line-height: 20px;

    }

    @media (max-width: 1200px) {
        .description {
            font-size: 12px;
            line-height: 18px;
        }

        .title a {
            font-size: 18px;
            line-height: 22px;
        }
    }

    @media (max-width: 919px) {
        .description {
            font-size: 10px;
            line-height: 14px;
        }

        .title {
            padding-top: 0px;
        }

        .title a {
            font-size: 16px;
            line-height: 16px;
        }

        .logo{
            width: 80px;
        }
    }

    @media (max-width: 640px) {

        .logo{
            width: 70px !important;
            max-width: 110px;
        }

        .description {
            font-size: 12px;
            line-height: 16px;
            text-align: center !important;
        }

        .title_md {
            padding-top: 0px;
            line-height: 16px !important;
        }

        .title_md a {
            font-size: 14px !important;
            line-height: 14px !important;
        }
    }
</style>