<template>
    <b-container fluid>
        <b-row>
            <b-col class="mb-1 p-0">
                <multilab-description />
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-1 p-0">
                <multilab-gallery />
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-5 p-0">
                <multilab-contacts />
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-5 p-0">
                <multilab-footer />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import Gallery from "./Multilab/Gallery";
    import Description from "./Multilab/Description";
    import Contacts from "./Multilab/Contacts";
    import Footer from "./Multilab/Footer";

    export default {
        name: "ProjectBDD",
        components: {
            'multilab-gallery': Gallery,
            'multilab-description': Description,
            'multilab-contacts':Contacts,
            'multilab-footer': Footer
        },
        mounted() {
            this.$store.dispatch('getProject', 'multilab');
            this.$store.dispatch('getDepartBySlug', 'tech');
        }
    }
</script>

<style scoped>

</style>