<template>
    <b-container fluid>
        <b-row>
            <b-col class="mb-1 p-0">
                <svo-title />
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-1 p-0">
                <svo-description />
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-0 p-0">
                <svo-docs />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import Title from "./SVO/Title";
    import Description from "./SVO/Description";
    import Docs from "./SVO/Gallery";

    export default {
        name: "SVO",
        components: {
            'svo-title': Title,
            'svo-description': Description,
            'svo-docs': Docs
        },
        mounted() {
            this.$store.dispatch('getPagesInfo', 'svo');
            this.$store.dispatch('getGallery');
        }
    }
</script>

<style scoped>

</style>