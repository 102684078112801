import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex);

const link = (process.env.NODE_ENV=="development")?"http://localhost/dvpion/api":"https://www.dvpion.ru/api";

// eslint-disable-next-line no-unused-vars
export const eventStore = {
    state: {
        eventList: [],
        eventPage: [],
        currentEvent: []
    },
    getters: {
        allEvents: state=> state.eventList,
        eventPage: state=> state.eventPage,
        currentEvent: state=> state.currentEvent,
    },
    mutations: {
        GET_EVENT_LIST: (state, list) => {
            state.eventList = list
        },
        GET_EVENT_PAGE: (state, event) => {
            state.eventPage = event
        },
        GET_EVENT: (state, event) => {
           state.currentEvent = event
        }

    },
    actions:{
        getEventList({ commit, state }) {
            if (!state.eventList.length) {
                return axios.post(link+'/events/list/get').then(response => {
                    commit('GET_EVENT_LIST', response.data)
                })
            }
        },
        getEventPage({ commit, state }) {
           /* if (!state.eventPage.length) {
                return axios.post(link+'/events/page/get').then(response => {
                    commit('GET_EVENT_PAGE', response.data)
                })
            }*/
            if (!state.eventPage.length) {
                axios.post(link + '/pages/get/').then(response => {
                    let data = response.data.filter(item => {
                        return (item.slug == 'events')
                    });
                    commit('GET_EVENT_PAGE', data[0])
                })
            }
        },
        getCurrentEvent({ commit }, id){
                return axios.post(link+'/events/one/get', {id: id}).then(response => {
                    commit('GET_EVENT', response.data)
                })

        }
        /*async getCurrentEvent({ dispatch, commit, state }, id){
            if (!state.eventList.length) await dispatch('getEventList')
            let event = null;
            state.eventList.forEach((item) => {
                event = item.event.filter((jtem) => {
                    return (jtem.id == id)
                })
            })
            commit('GET_EVENT', event[0])
        }*/
    }
}