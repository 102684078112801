<template>
    <b-container fluid v-if="depart" class="p-0">
        <b-row>
            <b-col :style="(depart.image_title)?'background: url('+depart.image_title.path+') no-repeat; background-position: center; background-size: cover;':''"
                   class="title_block pl-0 pl-md-3">
                <b-container fluid="xl">
                    <b-row class="pl-3 pr-3 pl-lg-5 pl-xl-0">
                        <div class="title pl-2 pl-xl-0" :style="'color: '+depart.name_color">{{depart.name}}</div>
                    </b-row>
                    <b-row>
                        <b-col class="d-none d-xl-block"></b-col>
                        <div class="menu_wrapper pl-4 pl-lg-5 pl-xl-5` pl-xl-0">
                            <div class="menu ml-lg-0 pt-2">
                                <div class="icon_back"
                                     :style="(depart.icon)?'background: url('+depart.icon.path+') no-repeat; background-size: 260px; background-position: right bottom':''"></div>

                                <transition name="fadeHeight" mode="out-in">
                                    <div class="course_list pb-5">
                                        <b-list-group class="ml-3 mt-3">
                                            <b-list-group-item v-for="(course, index) in courses[0]" :key="'course'+index"
                                                               class="course">
                                                <router-link :to="{name: 'course', params: { slug: course.slug}}" class="href">
                                                    {{course.name}}
                                                </router-link>
                                            </b-list-group-item>
                                            <b-collapse v-model="isShow" v-if="courses[1]">
                                                <b-list-group-item v-for="(course, index) in courses[1]" :key="'course'+index"
                                                                   class="course">
                                                    <router-link :to="{name: 'course', params: { slug: course.slug}}" class="href">
                                                        {{course.name}}
                                                    </router-link>
                                                </b-list-group-item>
                                            </b-collapse>

                                        </b-list-group>
                                    </div>
                                </transition>
                                <div class="down" @click="openCourses" v-if="courses[1].length">
                                    <Icon icon="ep:arrow-down-bold" color="#fafafa" height="30" :rotate="isShow?4:2"
                                          :horizontalFlip="true" :verticalFlip="true"/>
                                </div>
                            </div>
                        </div>
                        <b-col></b-col>
                    </b-row>
                </b-container>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>

    import {Icon} from '@iconify/vue2';

    export default {
        name: "Courses",
        data() {
            return {
                isShow: false,
                courseCountInBox: 10
            }
        },
        components: {
            Icon,
        },
        computed: {
            depart: function() {
                return this.$store.getters.currentDepart;
            },
            courses: function() {
                let list = [];
                if (this.depart){
                    list[0] = this.depart.courses.slice(0,this.courseCountInBox);
                    list[1] = this.depart.courses.slice(this.courseCountInBox);
                }
                return list;
            }
        },
        methods: {
            openCourses: function(){
                this.isShow = !this.isShow;
            }
        }
    }
</script>

<style scoped>
    .title_block {
        height: 420px;
    }

    .course {
        background: none;
        border: 0px;
        margin: 0px !important;
        padding: 2px 12px !important;
        z-index: 100;
        line-height: 20px;

    }

    .course_list {
        overflow: hidden;
    }

    .href {
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        color: #FFF;
        text-decoration: none;
    }

    .href:hover {
        color: #ebf338;
    }

    .title {
        margin-top: 36px;
        width: 600px;
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-size: 48px;
        line-height: 44px;
        color: #FFF;
    }

    @media (max-width: 800px) {
        .title {
            font-size:32px ;
            line-height: 32px;
        }
    }

    .menu_wrapper {
        width: 1200px;
    }

    .menu {
        position: relative;
        top: 36px;
        min-height: 310px;
        min-width: 380px;
        max-width: 380px;
        z-index: 5;
        background: rgb(0, 160, 227);
        background: linear-gradient(177deg, rgba(0, 160, 227, 1) 0%, rgba(174, 74, 132, 1) 100%);

        transition: all 1s;
    }

    .down {
        position: absolute;
        bottom: 8px;
        left: 46%;
        opacity: 0.6;
        cursor: pointer;
        z-index: 99;
    }

    .icon_back {
        position: absolute;
        min-height: 310px;
        width: 380px;
        opacity: 0.1;
        z-index: 10;
    }
</style>