<template>
    <b-container fluid class="pl-3 pb-0">
        <b-row class="mt-5 d-block d-lg-none">
            <b-col cols="12" class="title pr-4">{{info.name}}</b-col>
        </b-row>
        <b-row class="mt-3 mt-lg-5 mb-4">
            <b-col cols="12" md="5" v-if="info.poster">
                <img :src="info.poster.path" class="poster mb-4">
            </b-col>
            <b-col cols="12" md="7" class="pr-4">
                <b-row class="d-none d-lg-block">
                    <b-col cols="10" class="title">{{info.name}}</b-col>
                </b-row>
                <b-row class="mt-4" v-if="info.description">
                    <b-col cols="12" xl="6" v-html="description[0]" class="description"></b-col>
                    <b-col cols="12" xl="6" class="description">
                        <b-row>
                            <b-col v-html="description[1]">

                            </b-col>
                        </b-row>
                        <b-row class="mt-0 mt-lg-2" v-if="info.maindoc">
                            <b-col class="href">
                                <a :href="info.maindoc.path" target="_blank">
                                    <b-icon icon="file-earmark-text" class="icon-list mr-1 pt-1"></b-icon>
                                    {{info.maindoc.title}}
                                </a>
                            </b-col>
                        </b-row>
                        <!-- 
                        <b-row>
                            <b-col class="mt-2 description_small">
                                Согласие на обработку персональных данных
                            </b-col>
                        </b-row>
                        -->
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Title",
        computed: {
            info: function(){
                return this.$store.getters.currentEvent;
            },
            description: function(){
                return this.info.description.split("<hr>");
            }
        }
    }
</script>

<style scoped>
    .title {
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-size: 22px;
        line-height: 26px;
        color: var(--text-primary-color);
    }

    .description {
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        color: var(--text-secondary-color);
    }

    .description_small {
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        color: var(--text-primary-color);
    }

    .poster {
        width: 100%;
    }

    .icon-list {
        font-size: 24px;
        color: #F2913A;
    }

    .href {
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 24px;
        color: #F2913A;
        text-decoration: none;
        cursor: pointer;
    }

    .href:hover {
        color: #af483a;
    }


    .href:hover .icon-list {
        color: #af483a;
    }

    @media all and (max-width: 420px) {
        .title {
            font-size: 20px;
            line-height: 24px;
        }

        .description {
            font-size: 14px;
            line-height: 18px;
        }
    }
</style>