import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex);


const link = (process.env.NODE_ENV=="development")?"http://localhost/dvpion/api":"https://www.dvpion.ru/api";


// eslint-disable-next-line no-unused-vars
export const mainStore = {
    state: {
        footerMenu: [],
        info: [],
        carousel: [],
        partners: [],
        menu: [],
        websites: [],
        infoStat: [],
        gallery: [],
        news: [],
        newsDay: '',
        filteredNews: [],
        navigator: [],
        pages: [],
        org: [],
        team: [],
        rrc_functions: [],
        rrc_docs: [],
        ovz_detail: [],
        quiz: [],
        project: [],
        kassa: [],
        userTheme: 'null'
    },
    getters: {
        allMenu: state=> state.menu,
        allFooterMenu: state=> state.footerMenu,
        allInfo: state=> state.info,
        allCarousel: state=> state.carousel,
        allPartners: state=> state.partners,
        allWebsites: state=> state.websites,
        allInfoStat: state=> state.infoStat,
        allGallery: state=> state.gallery,
        allNews: state=> state.filteredNews,
        allNavigator: state=> state.navigator,
        allPages: state=> state.pages,
        allOrg: state=> state.org,
        allTeam: state=> state.team,
        newsDay: state=> state.newsDay,
        rrcFunctions: state=> state.rrc_functions,
        rrcDocs: state=> state.rrc_docs,
        ovzDetail: state=> state.ovz_detail,
        allQuiz:  state=> state.quiz,
        allKassa:  state=> state.kassa,
        userTheme: state=> state.userTheme,
        getProject:  state=> state.project
    },
    mutations: {
        GET_MENU: (state, menu) => {
            state.menu = menu
        },
        GET_FOOTER_MENU: (state, footerMenu) => {
            state.footerMenu = footerMenu
        },
        GET_INFO: (state, info) => {
            state.info = info
        },
        GET_INFO_STAT: (state, infoStat) => {
            state.infoStat = infoStat
        },
        GET_CAROUSEL: (state, carousel) => {
            state.carousel = carousel
        },
        GET_PARTNERS: (state, partners) => {
            state.partners = partners
        },
        GET_WEBSITES: (state, websites) => {
            state.websites = websites
        },
        GET_GALLERY: (state, gallery) => {
            state.gallery = gallery
        },
        GET_NEWS: (state, news) => {
            state.filteredNews = news;
            state.news = news;
        },
        GET_ALL_NEWS: (state) => {
            state.filteredNews = state.news;
        },
        GET_NEWS_BY_DATE: (state, date) => {
            state.filteredNews = state.news.filter(item => {
                return (item.date == date)
            });
        },
        GET_NAVIGATOR: (state, navigator) => {
            state.navigator = navigator
        },
        GET_PAGES: (state, page) => {
            state.pages = page
        },
        SET_NEWS_DAY: (state, day) => {
            state.newsDay = day;
        },
        GET_ORG: (state, list) => {
            Vue.set(state, 'org', list);
        },
        GET_TEAM: (state, list) => {
            Vue.set(state, 'team', list);
        },
        GET_RRC_FUNCTIONS: (state, list) => {
            Vue.set(state, 'rrc_functions', list);
        },
        GET_RRC_DOCS: (state, list) => {
            Vue.set(state, 'rrc_docs', list);
        },
        GET_OVZ_DETAIL: (state, list) => {
            Vue.set(state, 'ovz_detail', list);
        },
        GET_QUIZ: (state, list) => {
            Vue.set(state, 'quiz', list);
        },
        GET_KASSA: (state, list) => {
            Vue.set(state, 'kassa', list);
        },
        SET_USER_THEME: (state, theme) => {
            Vue.set(state, 'userTheme', theme);
        },
        SET_PROJECT: (state, data) => {
            Vue.set(state, 'project', data);
        }
    },
    actions:{
        getMenu({ commit }) {
            axios.post(link+'/menu/get').then(response => {
                commit('GET_MENU', response.data)
            })
        },
        getFooterMenu({ commit }) {
            axios.post(link+'/menu/getFooter').then(response => {
                commit('GET_FOOTER_MENU', response.data)
            })
        },
        getSiteInfo({ commit }) {
            axios.post(link+'/info/get').then(response => {
                commit('GET_INFO', response.data)
            })
        },
        getCarousel({ commit }) {
            axios.post(link+'/carousel/get').then(response => {
                commit('GET_CAROUSEL', response.data)
            })
        },
        getPartners({ commit }) {
            axios.post(link+'/partners/get').then(response => {
                commit('GET_PARTNERS', response.data)
            })
        },
        getWebsites({ commit, state }) {
            if (!state.websites.length) {
                axios.post(link+'/websites/get').then(response => {
                    commit('GET_WEBSITES', response.data)
                })
            }
        },
        getInfoStat({ commit }) {
            axios.post(link+'/infoStat/get').then(response => {
                commit('GET_INFO_STAT', response.data)
            })
        },
        getGallery({ commit }) {
            axios.post(link+'/gallery/get').then(response => {
                commit('GET_GALLERY', response.data)
            })
        },
        getNews({ commit }, value) {
            //if (!state.filteredNews.length) {
                axios.post(link + '/news/get/list/' + value).then(response => {
                    commit('GET_NEWS', response.data)
                })
           //}
        },
        getPagesInfo({ commit, state }, slug) {
            if (!state.pages.length) {
                axios.post(link + '/pages/get/').then(response => {
                    let data = response.data.filter(item => {
                        return (item.slug==slug)
                    });
                    commit('GET_PAGES', data[0])
                })
            }
        },
        getNavigatorInfo({ commit }) {
            axios.post(link+'/navigator/get/').then(response => {
                commit('GET_NAVIGATOR', response.data)
            })
        },
        getOrg({ commit, state }) {
            if (!state.org.length) {
                axios.post(link + '/org/get').then(response => {
                    commit('GET_ORG', response.data)
                })
            }
        },
        getTeam({ commit, state }) {
            if (!state.team.length) {
                axios.post(link + '/team/get').then(response => {
                    commit('GET_TEAM', response.data)
                })
            }
        },
        getRRCFunctions({ commit, state }) {
            if (!state.rrc_functions.length) {
                axios.post(link + '/rrc/functions/get').then(response => {
                    commit('GET_RRC_FUNCTIONS', response.data)
                })
            }
        },
        getRRCDocs({ commit, state }) {
            if (!state.rrc_docs.length) {
                axios.post(link + '/rrc/docs/get').then(response => {
                    commit('GET_RRC_DOCS', response.data)
                })
            }
        },
        getOVZDetails({ commit, state }) {
            if (!state.ovz_detail.length) {
                axios.post(link + '/ovz/details/get').then(response => {
                    commit('GET_OVZ_DETAIL', response.data)
                })
            }
        },
        getQuiz({ commit, state }) {
            if (!state.quiz.length) {
                axios.post(link + '/quiz/get').then(response => {
                    commit('GET_QUIZ', response.data)
                })
            }
        },
        getKassa({ commit, state }) {
            if (!state.kassa.length) {
                axios.post(link + '/kassa/get').then(response => {
                    commit('GET_KASSA', response.data)
                })
            }
        },
        setUserTheme({ commit }, value) {
            localStorage.setItem("user-theme", value);
            commit('SET_USER_THEME', value)
        },
        getProject({ commit }, slug) {
            axios.get(link + '/projects/get/' + slug).then(response => {
                commit('SET_PROJECT', response.data)
            })
        },
        // eslint-disable-next-line no-unused-vars
        async saveQuestion({ commit }, value) {
            return await axios.post(link + '/question/', value).then(response => {
                return response.data
            })
        }
    }
}