<template>
    <b-container fluid class="p-0">
        <b-row class="m-0">
            <b-col class="title_block pb-5 pl-4 pr-4">
                <b-card class="form p-0">
                    <b-row>
                        <b-col class="title m-4 p-0 pl-1 p-md-3 pl-md-4">
                            ЗАДАЙТЕ ВОПРОС
                        </b-col>
                    </b-row>
                    <b-row class="m-3">
                        <b-col cols="12" md="6" xl="4" class="p-0 p-md-3">
                            <b-input v-model="form.fio" placeholder="Имя" class="input"></b-input>
                            <b-input v-model="form.phone" placeholder="Телефон"  v-mask="'8 (###) ###-##-##'" class="input mt-3"></b-input>
                            <b-input v-model="form.mail" type="email" placeholder="Электронная почта" class="input mt-3"></b-input>
                        </b-col>
                        <b-col cols="12" md="6" xl="5" class="p-0 p-md-3 mt-3 mt-md-0">
                            <b-textarea v-model="form.message" class="input-textarea" placeholder="Введите текст"></b-textarea>
                        </b-col>
                        <b-col cols="12" md="6" xl="3" offset="0" offset-md="6" offset-xl="0" class="p-0 p-md-3 mt-3 mt-lg-0 text-right pt-lg-5">
                                    <b-row class="input-checkbox mb-3 mt-3">
                                        <b-col class="text-right">
                                            <div class="checkbox_block" @click="plus=!plus"><b-icon icon="check" font-scale="1.4" v-if='plus'></b-icon></div>
                                            <div class="check_text" @click="plus=!plus">
                                                Даю согласие на обработку данных
                                            </div>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <b-button class="button" @click="saveQuestion" :disabled="!plus">ОТПРАВИТЬ</b-button>
                                        </b-col>
                                    </b-row>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-modal v-model="show" hide-footer hide-header size="xl" content-class="testWindow">
            <b-row class="p-4"
                   :style="'background: url('+require('/src/assets/img/test/back.png')+'); border-radius: 16px'">
                <b-col cols="12">
                    <b-card class="quiz_back">
                        <b-row>
                            <b-col class="text-center">
                                <h5>Ваш вопрос направлен специалисту</h5>
                                <div class="description">
                                    Ожидайте ответа в течение 24 часов</div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col cols="12" class="mt-3 text-center">
                    <b-btn @click="show = false" class="pt-1">Закрыть</b-btn>
                </b-col>
            </b-row>
        </b-modal>
    </b-container>
</template>

<script>
    export default {
        name: "Question",
        data() {
            return {
                show: false,
                agree: false,
                plus: false,
                form: {fio: '', mail: '', phone: '', message: ''}
            }
        },
        methods: {
            saveQuestion() {
                this.$store.dispatch('saveQuestion', this.form).then(data => {
                    if (data>0){ 
                        this.show = true
                        this.form = {fio: '', mail: '', phone: '', message: ''}
                        this.agree = false
                    }
                })
                
            }
        },
    }
</script>

<style scoped>
    .title_block {
        background-color: var(--background-color-question);
        min-height: 500px;
        padding-top: 48px;
    }

    .title {
        width: 600px;
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-size: 22px;
        line-height: 32px;
        color: var(--text-secondary-color);
    }

    .form {
        background-color: var(--background-color-secondary);
        width: 1200px;
        border: 0px;
        padding: 0px 48px 24px 48px;
        margin: 0px auto;
    }

    .quiz_back {
        background-color: var(--background-color-primary);
    }

    :deep(.testWindow) {
        border-radius: 16px;
    }
    .description {
        color: var(--text-secondary-color);
    }

    @media (max-width: 1200px) {
        .form {
            width: 100%;
            padding: 24px 16px 48px 16px;
        }
    }

    @media (max-width: 640px) {
        .title {
            text-align: center;
        }
    }

    .input {
        font-family: SF LIGHT, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
        color: #727271;
        border: 0px;
        text-align: center;
        padding: 16px;
        background-color: var(--background-input);
    }

    .input-textarea {
        font-family: SF LIGHT, sans-serif;
        height: 164px;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
        color: #727271;
        border: 0px;
        padding: 16px;
        background-color: var(--background-input);
    }

    .input-checkbox{
        min-width:280px;
    }

    .check_text{
        float: right;
        width: 200px;
        font-family: SF LIGHT, sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 18px;
        color: #9D9E9E;
        text-align: right;
        cursor: pointer;
    }

    .checkbox_block {
        cursor: pointer;
        float: right;
        margin-top: 4px;
        margin-right: 4px;
        margin-left: 12px;
        width: 30px;
        height: 30px;
        border: 1px #ccc solid;
        border-radius: 6px;
        background-color: #FFF;
        font-family: SF, sans-serif;
        font-weight: 500;
        color: #9D9E9E;
        font-size: 20px;

    }

    .button {

        width: 250px;
        font-family: SF, sans-serif;
        font-weight: 500;
        font-size: 20px;
        background: rgb(252,215,5);
        background: linear-gradient(138deg, rgba(252,215,5,1) 0%, rgba(247,183,13,1) 100%);
        border: 0px;

    }
    .button:hover {
        opacity: 0.8;
    }
</style>