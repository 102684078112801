<template>
    <b-container fluid class="pt-4 pb-5 back">
        <b-row v-for="(type, i) in list" :key="'type'+i">
            <b-col cols="12" v-if="type.event.length" class="p-3 mb-4 title">
                {{type.name}}
            </b-col>
                <b-container class="pl-2 pl-xl-0">
                    <b-col class="p-0 pb-4 d-none d-md-block">
                        <b-row>
                            <b-col cols="12" md="6" lg="4" v-for="(item,j) in type.event" :key="'item'+j" class="pb-4">
                                <b-card :img-src="item.poster.path"
                                        class="shadow p-3"
                                v-if="item.poster">
                                    <b-card-title class="card_title">
                                        {{item.name}}
                                    </b-card-title>
                                    <b-card-footer class="card_more p-0 mt-4">
                                        <router-link class="href" :to="{name: 'event', params: { id: item.id}}">Подробнее</router-link>
                                    </b-card-footer>
                                </b-card>
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col class="mb-4 p-0 d-block d-md-none">
                        <b-row>
                            <b-col cols="12" v-for="(item,j) in type.event" :key="'item'+j" class="mb-4">
                                <b-card
                                        class="shadow"
                                        no-body
                                        v-if="item.poster">
                                        <b-row no-gutters>
                                            <b-col cols="4">
                                                <b-card-img :src="item.poster.path"></b-card-img>
                                            </b-col>
                                            <b-col cols="8" class="p-3">
                                                <b-card-title class="card_title_small">
                                                    {{item.name}}
                                                </b-card-title>
                                                <b-card-footer class="card_more_small p-0 mt-2 mt-md-4">
                                                    <router-link class="href" :to="{name: 'event', params: { id: item.id}}">Подробнее</router-link>
                                                </b-card-footer>
                                            </b-col>
                                        </b-row>
                                </b-card>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-container>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "List",
        computed: {
            list: function () {
                return this.$store.getters.allEvents;
            }
        }
    }
</script>

<style scoped>
    .back {
        background-color: var(--background-color-secondary);
    }

    .title {
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: var(--text-secondary-color);
    }

    .card_title {
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-size: 16px !important;
        line-height: 18px !important;
        font-weight: 500;
        color: var(--text-primary-color);
        min-height: 100px;
    }


    .card_title_small {
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-size: 12px !important;
        line-height: 16px !important;
        font-weight: 500;
        color: var(--text-primary-color);
    }

    .card_more {
        border: 0px;
        text-align: left;
        background-color: var(--background-card-color) !important;
    }

    .card_more_small {
        border: 0px;
        text-align: left;
        background-color: #fff !important;
        font-size: 14px !important;
        line-height: 16px !important;
    }


    .href {
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        color: #F2913A;
        text-decoration: none;
        cursor: pointer;
    }
    .href:hover {
        color: #af483a;
    }


    .card_more_small .href {
        font-size: 14px !important;
        line-height: 16px !important;

    }

    .shadow {
        background-color: var(--background-card-color);
    }

</style>