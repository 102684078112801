<template>
    <b-container fluid="xl" class="pl-3 pl-sm-3 ml-sm-0 pl-xl-3 pr-3 pr-sm-0 pr-xl-0 mb-5">

            <b-row v-if="info" class="m-0 p-0 d-none d-md-flex">
                <b-col cols="12" md="6" lg="4" xl="3" v-for="(item, index) in info" :key="'p'+index" class="p-0 pr-3 pr-1 mb-3" @click="chooseDepart(item)">

                        <div class="item" :style="'background: url('+item.icon_back.path+')'">
                            <div class="color_back" :style="'background-color: '+item.menu_color"></div>
                            <div class="icon m-auto">
                                <img :src="item.icon.path">
                            </div>
                            <div class="title">
                                {{item.name}}
                            </div>
                        </div>
                </b-col>
            </b-row>

            <b-row v-if="info" class="d-flex d-md-none">
                <b-col cols="12" md="6" lg="4" xl="3" v-for="(item, index) in info" :key="'p'+index" class="p-0 mb-3" @click="chooseDepart(item)">
                        <div class="item_small" :style="'background: url('+item.icon_back.path+')'">
                            <div class="color_back_small" :style="'background-color: '+item.menu_color"></div>
                            <div class="icon">
                                <img :src="item.icon.path">
                            </div>
                            <div class="title">
                                <b-row align-v="center" style="height: 76px; margin-top: -120px;">
                                    <b-col>
                                        {{item.name}}
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                </b-col>
            </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Departs",
        computed: {
            info: function () {
                return this.$store.getters.allDeparts
            }
        },
        methods: {
            chooseDepart: function(data){
                this.$store.commit('SET_DEPART', data);
            }
        }
    }
</script>

<style scoped>

    .item {
        position: relative;
        width: 100%;
        min-height: 206px;
        min-width: 268px;
        max-width: 268px;
        background-size: 100% !important;
        background-repeat: no-repeat !important;
        border-radius: 16px;
    }

    .color_back {
        position: absolute;
        z-index:1;
        opacity: 0.8;
        min-height: 206px;
        min-width: 268px;
        max-width: 268px;
        width: 100%;
        border-radius: 16px;
        margin-right:32px;
    }

    .item_small {
        position: relative;
        width: 100%;
        height: 104px;
        min-width: 270px;
        max-width: 640px;
        background-size: 166px !important;
        background-repeat: no-repeat !important;
        border-radius: 16px;
    }

    .color_back_small {
        position: absolute;
        z-index:1;
        opacity: 0.8;
        min-height: 104px;
        min-width: 270px;
        max-width: 640px;
        width: 100%;
        border-radius: 16px;
        margin-right:32px;
    }

    .item:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    .item .icon {
        width: 130px;
        height: 120px;
        padding-top: 24px;
        position: relative;
        z-index:2;
    }

    .item_small .icon {
        width: 130px;
        height: 103px;
        padding-top: 8px;
        position: relative;
        z-index:2;
    }

    .item_small .icon img {
        margin-left: 30%;
        margin-top: 8px;
        width: 85px;
    }

    .item .icon img {
        width: 125px;
    }

    .item .title {
        position: relative;
        width: 240px;
        margin: 20px auto;
        text-align: center;
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        text-decoration: none;
        color: #FFF;
        z-index:2;
    }

    .item_small .title {
        position: relative;
        margin-top: 32px;
        margin-left: 182px;
        padding-right: 16px;
        text-align: left;
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        text-decoration: none;
        color: #FFF;
        z-index:2;
    }

    @media all and (max-width: 1199px) {
        .item {
            min-width: 250px;
            max-width: 330px;
        }
        .color_back {
            min-width: 250px;
            max-width: 330px;
        }
    }

    @media all and (max-width: 919px) {
        .item {
            min-width: 244px;
            max-width: 380px;
            margin-bottom: 16px;
        }
        .color_back {
            min-width: 244px;
            max-width: 380px;
        }
    }

    @media all and (max-width: 420px) {
        .item_small .title {
            font-size: 16px;
            margin-left: 152px;
        }

        .item_small {
            background-size: 136px !important;
            background-repeat: no-repeat !important;
            border-radius: 16px;
        }
        .item_small .icon img {
            margin-left: 20%;
        }
    }

</style>