<template>
    <b-container fluid>
        <b-row>
            <b-col class="mb-1 p-0">
                <about-title></about-title>
            </b-col>
        </b-row>
        <b-container fluid="xl" class="p-0 pl-2 pl-md-4 pr-2 pl-lg-4 pl-xl-1 mb-5">
            <b-row class="mt-4 mb-4">
                <b-col class="title mb-2 mt-2" cols="12">
                    Слово директора
                </b-col>
            </b-row>
            <b-row>
                <b-col class="mt-0 pl-4">
                    <about-director></about-director>
                </b-col>
            </b-row>
            <b-row class="mt-4 mb-4">
                <b-col class="title mb-2 mt-2" cols="12">
                    О Дворце
                </b-col>
            </b-row>
            <b-row>
                <b-col class="mt-0 pl-4">
                    <about-description :slug="slug"></about-description>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>
    import Title from './About/Title';
    import Description from './About/Description';
    import Director from './About/Director'

    export default {
        name: "About",
        components: {
            'about-title': Title,
            'about-description': Description,
            'about-director': Director
        },
        mounted() {
            this.$store.dispatch('getPagesInfo', 'about');
            this.$store.dispatch('getInfoStat');
        }
    }
</script>

<style scoped>

    .title {
        width: 600px;
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-size: 48px;
        line-height: 56px;
        color: #9D9E9E;
    }

</style>