<template>
    <b-container fluid="xl" class="p-0 m-xl-auto" v-if="info">
        <ovz-photo :photo-data="photo" :gallery-data="info.photo" :showWindow="showWindow" @hideWindow="showWindow = false" />
        <b-row class="grey mr-4 ml-4 mr-xl-0 ml-xl-0 pl-0 pl-xl-1 pr-2">
            <b-col cols="12" lg="4" xl="6">
               <b-row>
                   <b-col class="info_text pl-4 pt-4 pr-md-4 pr-lg-0 pr-xl-5" v-html="description[2]" v-if="info.description">
                   </b-col>
               </b-row>
                <b-row no-gutters class="d-lg-none d-flex gallery ml-auto mr-auto mb-4" v-if="info.photo">
                    <b-col class="pb-1 text-center gallery_item" v-for="(index) in 5" :key="'image'+index" >
                        <img alt="изображение входных групп" :src="img_path+info.photo[index+5].photo" @click="showPhoto(index, info.photo)" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="info_text  pl-3 pl-lg-4 pr-md-4 pr-lg-0 pr-xl-5 pt-1" v-html="description[3]" v-if="info.description">
                    </b-col>
                </b-row>
                <b-row class="mt-3" v-if="info.document">
                    <div class="href p-0 mb-5">
                        <a :href="info.document.path">
                            <b-icon icon="file-earmark-text" class="icon-list mr-2"></b-icon>
                            <div class="float-right">Паспорт доступности<br>Дворца пионеров</div>
                        </a>
                    </div>
                </b-row>
                <b-row no-gutters class="d-lg-none d-flex gallery ml-auto mr-auto mb-5" v-if="info.photo">
                    <b-col class="pb-1 text-center gallery_item" v-for="(index) in 6" :key="'image'+index" >
                        <img alt="изображение групп внутри дворца" :src="img_path+info.photo[index-1].photo" @click="showPhoto(index, info.photo)" />
                    </b-col>
                </b-row>
            </b-col>
            <div class="gallery mt-4 mr-3 ml-auto d-none d-lg-block ">
                <b-row no-gutters v-if="info.photo">
                    <b-col class="pb-1 text-center gallery_item" v-for="(image, index) in info.photo" :key="'image'+index">
                        <img alt="изображение входных групп и групп гутри дворца" @click="showPhoto(index, info.photo)"  :src="img_path+image.photo" />
                    </b-col>
                </b-row>
            </div>
        </b-row>
    </b-container>
</template>

<script>
    import Photo from "./../../components/Photo";
    export default {
        components: {
            'ovz-photo': Photo
        },
        name: "Gallery",
        data() {
            return {
                img_path: process.env.VUE_APP_IMG,
                showWindow: false,
                photo: null
            }
        },
        computed: {
            info: function () {
                return this.$store.getters.ovzDetail;
            },
            description: function(){
                return this.info.description.split("<hr>");
            }
        },
        methods: {
            showPhoto: function (path) {
                this.photo = path;
                this.showWindow = true;
            }
        }
    }
</script>

<style scoped>
    .grey {
        background-color: var(--background-color-secondary);
    }

    .info_text {
        font-family: SF, sans-serif;
        font-size: 14px;
        line-height: 21px;
        color: var(--text-secondary-color);
    }

    .gallery {
        width: 534px;
    }

    .gallery_item:hover {
        cursor: pointer;
    }


    .href {
        width: 208px;
        line-height: 16px;
        margin-left: 24px;
    }
    .icon-list {
        font-size: 32px;
        color: #F2913A;
    }

    @media all and (max-width: 600px) {
        .gallery {
            width: 360px;
        }
    }
</style>