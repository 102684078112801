<template>
    <b-container fluid>
        <b-row>
            <b-col class="mb-1 p-0">
                <rrc-title></rrc-title>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-1 p-0">
                <rrc-description></rrc-description>
            </b-col>
        </b-row>
        <!--<b-row>
            <b-col class="mt-5 p-0">
                <rrc-functions></rrc-functions>
            </b-col>
        </b-row>-->
        <b-row>
            <b-col class="mt-5 p-0">
                <rrc-docs></rrc-docs>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import Title from "./RRC/Title";
    import Description from "./RRC/Description";
    import Docs from "./RRC/Docs";
    // import Functions from "./RRC/Functions";

    export default {
        name: "RRC",
        components: {
            'rrc-title': Title,
            'rrc-description': Description,
            'rrc-docs': Docs
            // 'rrc-functions': Functions
        },
        mounted() {
            this.$store.dispatch('getPagesInfo', 'rrc');
            this.$store.dispatch('getRRCFunctions');
            this.$store.dispatch('getRRCDocs');
        }
    }
</script>

<style scoped>

</style>