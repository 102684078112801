<template>
    <b-container fluid="xl" class="pl-4 pl-md-4 pl-lg-4 pl-xl-0 pr-2" v-if="info">
        <ovz-photo :photo-data="photo" :showWindow="showWindow" @hideWindow="showWindow = false" />
        <b-row class="mr-0 ml-0 mt-0 mb-4" no-gutters>
            <b-col class="title mt-4" cols="12">
                Карта комфортного перемещения
            </b-col>
        </b-row>
        <b-row class="mr-0 ml-0 mb-4 mt-4 back" no-gutters>
            <b-col cols="12" md="6" lg="4" class="mt-4" v-if="info.map_image1">
                <img :src="info.map_image1.path" @click="showPhoto(info.map_image1.path)" class="image_thumb">
            </b-col>
            <b-col cols="12" md="6" lg="4" class="mt-4" v-if="info.map_image2">
                <img :src="info.map_image2.path" @click="showPhoto(info.map_image2.path)" class="image_thumb">
            </b-col>
            <b-col cols="12" md="6" lg="4" class="mt-4" v-if="info.map_image3">
                <img :src="info.map_image3.path" @click="showPhoto(info.map_image3.path)" class="image_thumb">
            </b-col>
            <b-col cols="12" md="6" lg="4" class="mt-4" v-if="info.map_image4">
                <img :src="info.map_image4.path" @click="showPhoto(info.map_image4.path)" class="image_thumb">
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import Photo from "./../../components/Photo";
    export default {
        components: {
            'ovz-photo': Photo
        },
        data() {
            return {
                photo: null,
                showWindow: false
            }
        },
        name: "Maps",
        computed: {
            info: function () {
                return this.$store.getters.ovzDetail;
            }
        },
        methods: {
            showPhoto: function (path) {
                this.photo = path;
                this.showWindow = true;
            }
        }
    }
</script>

<style scoped>
    .title {
        width: 600px;
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: var(--text-primary-color);
    }

    .image_thumb {
        width: 100%;
    }

    .image_thumb:hover {
        cursor: pointer;
    }

    .back {
        background-color: #fff;
        border-radius: 8px;
    }
</style>