<template>
    <b-container fluid>
        <b-row class="mb-5">
            <b-col class="mb-5">
                <courses></courses>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-5 mb-5">
                <description></description>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-5 mb-5">
                <stat></stat>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-1">
                <team></team>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import Courses from "./Depart/Courses";
    import Description from "./Depart/Description";
    import Stat from "./Depart/Stat";
    import Team from "./Depart/Team";

    export default {
        name: "Depart",
        props: ['slug'],
        watch: {
            slug: function(newVal){
                console.log(newVal)
            }
        },
        components: {
            Description,
            Courses,
            Stat,
            Team
        },
        mounted(){
            console.log("!")
            this.$store.dispatch('getDepartBySlug', this.$route.params.slug);
        }
    }
</script>

<style scoped>

</style>