<template>
    <b-container fluid>
        <b-row>
            <b-col class="mb-0 p-0">
                <news-title></news-title>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-1 p-0">
                <news-list></news-list>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import Title from './News/Title';
    import List from './News/List';

    export default {
        name: "News",
        components: {
            'news-title': Title,
            'news-list': List
        },
        mounted() {
            this.$store.dispatch('getNews', 0);
        }
    }
</script>

<style scoped>

</style>