<template>
    <b-container fluid>
        <b-row>
            <b-col class="mb-1 p-0">
                <info-title></info-title>
            </b-col>
        </b-row>
        <b-container fluid="xl" class="p-0 pl-2 pl-md-4 pr-2 pl-lg-4 pl-xl-1 mb-5">
            <b-row class="mt-4 mb-4">
                <b-col class="title mb-2 mt-2" cols="12">
                    О Дворце
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="12" xl="3" class="p-0 mb-3 mb-lg-5">
                    <info-menu :slug="slug"></info-menu>
                </b-col>
                <b-col lg="12" xl="9" class="mt-0 pl-4">
                    <info-content :slug="slug"></info-content>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>
    import Title from './Info/Title';
    import Menu from './Info/Menu';
    import Content from "./Info/Content";

    export default {
        name: "Info",
        components: {
            'info-title': Title,
            'info-menu': Menu,
            'info-content': Content
        },
        computed: {
          slug: function(){
              return (this.$route.params.slug)?this.$route.params.slug:'osnovnye-svedeniya'
          }
        },
        mounted() {
            this.$store.dispatch('getPagesInfo', 'info');
            this.$store.dispatch('getOrg');
        }
    }
</script>

<style scoped>

    .title {
        width: 600px;
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-size: 48px;
        line-height: 56px;
        color: #808080;
    }

</style>