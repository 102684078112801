
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMask from 'v-mask'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import { BootstrapVueIcons } from 'bootstrap-vue'
import { BootstrapVue, IconsPlugin} from 'bootstrap-vue'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(BootstrapVueIcons)
Vue.use(VueMask)
Vue.use(require('vue-moment'));


import './assets/app.scss'

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

