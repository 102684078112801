<template>
        <b-container fluid="md" class="pl-3 pl-md-0 pl-xl-3 pt-0 pb-4 pr-0" v-if="info">
            <b-row v-if="description">
                <b-col cols="11" md="11" xl="11" class="description_top pt-3 pl-4 pr-4" v-html="description" />
            </b-row>
        </b-container>
</template>

<script>
    export default {
        name: "Description",
        computed: {
            info: function () {
                return this.$store.getters.allPages;
            },
            getTheme() {
                return this.$store.getters.userTheme
            },
            description: function(){
                return this.info.description;
            }
        }
    }
</script>

<style scoped>

    .description_top {
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        color: var(--text-secondary-color);
        background-color: var(--background-color-secondary);
    }
    .description {
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        color: var(--text-secondary-color);
    }

    .image_description img {
        margin-left: -8px;
        width: 100%;
        max-width: 800px !important;
    }

</style>