<template>
    <b-container fluid class="pl-3 pr-3">
        <b-modal v-model="show" hide-footer hide-header size="xl" content-class="testWindow">
            <b-row class="p-4"
                   :style="'background: url('+require('/src/assets/img/test/back.png')+'); border-radius: 16px'">
                <b-col>
                    <b-card class="quiz_back" v-if="showResult">
                        <b-row>
                            <b-col class="text-center">
                                <h5>Дорогой друг!</h5>
                                <div class="description">Ты справился с прохождением теста!<br>
                                    Тебе подходит образовательное направление...</div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="mt-4">
                                <router-link :to="{name: 'depart', params: { slug: topDepart.slug}}" style="text-decoration: none">
                                    <div class="item m-auto" :style="'background: url('+topDepart.icon_back.path+')'">
                                        <div class="color_back" :style="'background-color: '+topDepart.menu_color"></div>
                                        <div class="icon m-auto">
                                            <img :src="topDepart.icon.path">
                                        </div>
                                        <div class="title">
                                            {{topDepart.name}}
                                        </div>
                                    </div>
                                </router-link>
                            </b-col>
                        </b-row>
                        <!-- <b-row>
                            <b-col>
                                <b-list-group>
                                    <b-list-group-item v-for="(dep, i) in this.orderedDepart" :key="'d'+i">
                                        <span v-if="dep">{{dep.name}} - {{dep.value}} - {{dep.id}}</span>
                                    </b-list-group-item>
                                </b-list-group>
                            </b-col>
                        </b-row> -->
                    </b-card>
                    <b-card class="quiz_back" v-else>
                        <b-carousel ref="quizSlider" v-model="slide" :interval="0">
                            <b-carousel-slide v-for="(item, index) in quiz" :key="'q'+index">
                                <template #img class="custom-class">
                                    <b-row no-gutters>
                                        <b-col>
                                            <b-list-group>
                                                <b-list-group-item>
                                                    <b-row no-gutters>
                                                        <b-col cols="9" lg="9" xl="10" class="header">
                                                            <h6 class="mb-0 ml-0">{{item.name}}</h6>
                                                        </b-col>
                                                        <b-col cols="3" lg="3" xl="2">
                                                            <b-row no-gutters>
                                                                <b-col class="text-center"><h5>Да</h5></b-col>
                                                                <b-col class="text-center"><h5>Нет</h5></b-col>
                                                            </b-row>
                                                        </b-col>
                                                    </b-row>
                                                </b-list-group-item>
                                            </b-list-group>
                                        </b-col>
                                    </b-row>
                                    <hr />
                                    <b-row no-gutters>
                                        <b-col>
                                            <b-list-group>
                                                <b-list-group-item v-for="(variant, j) in item.variants" :key="'v'+j">
                                                    <b-row no-gutters>
                                                        <b-col cols="9" lg="9" xl="10">
                                                            <span class="var_name">{{variant.name}}</span>
                                                        </b-col>
                                                        <b-col cols="3" lg="3" xl="2">
                                                                    <b-form-radio-group size="lg" v-model="varValue[index][j]" class="float-right w-100">
                                                                        <b-row no-gutters>
                                                                            <b-col class="text-center" cols="6">
                                                                                <b-form-radio value="2" name="radio-size" button-variant="primary"></b-form-radio>
                                                                            </b-col>
                                                                            <b-col class="text-center" cols="6">
                                                                                <b-form-radio value="1" name="radio-size"></b-form-radio>
                                                                            </b-col>
                                                                        </b-row>
                                                                    </b-form-radio-group>
                                                        </b-col>
                                                    </b-row>
                                                </b-list-group-item>
                                            </b-list-group>
                                        </b-col>
                                    </b-row>
                                </template>
                            </b-carousel-slide>
                        </b-carousel>
                    </b-card>

                    <b-row>
                        <b-col class="m-3">
                            <b-button variant="info" class="pl-0 pl-sm-5 pr-0 pr-sm-5 float-left quiz_button" v-if="(slide>0)&&(slide!=(quiz.length-1))" @click="prev">Назад</b-button>

                            <b-button variant="primary" class="pl-5 pr-5 float-right" v-if="(slide==(quiz.length-1))&&(!showResult)" @click="result">Посмотреть результат</b-button>
                            <b-button variant="primary" class="pl-0 pl-sm-5 pr-0 pr-sm-5 float-right quiz_button" v-if="(slide!=(quiz.length-1))&&(!showResult)" @click="next">Далее</b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-modal>
        <b-row>
            <b-col class="start_block p-0">
                <div :style="(getTheme=='light-theme')?'background: url('+require('/src/assets/img/parents_choose.png')+') no-repeat':'background: url('+require('/src/assets/img/parents_choose_dark.png')+') no-repeat'"
                     class="start_image" @click="showQuiz"></div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Quiz",
        data() {
            return {
                show: false,
                slide: 0,
                varValue: [],
                depart: [],
                showResult: false
            }
        },
        methods: {
            showQuiz(){
                this.show = true;

                this.quiz.forEach((q,i) => {
                    this.varValue[i] = []
                    q.variants.forEach((v,j) => {
                        this.varValue[i][j] = 0;
                    })
                })

                this.departs.forEach(d => {
                    this.depart[d.id] = {};
                    this.depart[d.id].name=d.name;
                    this.depart[d.id].id=d.id;
                    this.depart[d.id].value=0;
                })

            },
            prev() {
                this.$refs.quizSlider.prev()
            },
            next() {
                this.$refs.quizSlider.next()
            },
            result() {
                this.varValue.forEach((q, i) => {
                    q.forEach((v,j)=> {
                        if (v==2) this.depart[this.quiz[i].variants[j].depart].value++;
                    });
                })


                this.showResult = true
            }
        },
            computed: {
                quiz: function () {
                    return this.$store.getters.allQuiz;
                },
                departs: function () {
                    return this.$store.getters.allDeparts;
                },
                orderedDepart: function () {

                    function compare(a, b) {
                        if (a.value < b.value)
                            return 1;
                        if (a.value > b.value)
                            return -1;
                        return 0;
                    }

                    return this.depart.slice().sort(compare);
                },
                topDepart: function () {
                    return this.departs.find( item => (item.id==this.orderedDepart[0].id));
                },
                getTheme() {
                    return this.$store.getters.userTheme
                }
        },
        mounted() {
            this.$store.dispatch('getQuiz');
        }
    }
</script>

<style scoped>

    .start_block {
    }

    .quiz_back {
        background-color: var(--background-color-primary);
    }

    .item {
        position: relative;
        width: 100%;
        min-height: 206px;
        min-width: 268px;
        max-width: 268px;
        background-size: 100% !important;
        background-repeat: no-repeat !important;
        border-radius: 16px;
    }


    .item:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    .item .icon {
        width: 130px;
        height: 120px;
        padding-top: 24px;
        position: relative;
        z-index:2;
    }


    .item .icon img {
        width: 125px;
    }

    .item .title {
        position: relative;
        width: 240px;
        margin: 20px auto;
        text-align: center;
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        text-decoration: none;
        color: #FFF;
        z-index:2;
    }

    .color_back {
        position: absolute;
        z-index:1;
        opacity: 0.8;
        min-height: 206px;
        min-width: 268px;
        max-width: 268px;
        width: 100%;
        border-radius: 16px;
        margin-right:32px;
    }

    h6 {
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: var(--text-secondary-color);
    }

    hr {
        color:#f1a84e;
        background-color: #f1a84e;
        height: 1px;
    }

    h5 {
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: #f1a84e;
    }

    /deep/ .testWindow {
        border-radius: 16px;
    }

    /deep/ .list-group-item {
        border: 0px !important;
        padding: 16px !important;
    }

    /deep/ .list-group-item {
        background-color: var(--background-color-primary);
    }

    .header {
        background-color: var(--background-color-primary);
    }

    /deep/ .modal-body {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    /deep/ .custom-control-inline {
        margin: 0px !important;

    }

    .var_name {
        font-family: SF, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: var(--text-secondary-color);
    }

    .start_image {
        cursor: pointer;
        position: relative;
        height: 178px;
        background-size: 1901px !important;
        background-position: center !important;
    }

    .description {
        color: var(--text-secondary-color);
    }

    @media all and (max-width: 1200px) {

        .start_image {
            position: relative;
            height: 178px;
            background-size: 1901px !important;
            background-position: -325px !important;
        }
    }

    @media all and (max-width: 920px) {

        .start_image {
            background-size: 1250px !important;
            background-position-x: -200px !important;
        }
    }

    @media all and (max-width: 640px) {

        /deep/ .list-group-item {
            padding: 8px 0px !important;
        }

        .start_image {
            height: 66px;
            width: 100%;
            background-size: 840px !important;
            background-position-x: -120px !important;
        }
    }

    @media all and (max-width: 456px) {

        .quiz_button {
            width: 100px;
        }
    }

</style>