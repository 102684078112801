<template>
     <b-container class="p-0 mt-3">
        <b-modal id="BigImage" title="BigImage"  hide-footer hide-header size="xl" v-if="info">
            <b-row>
                <b-col>
                    <b-carousel
                            id="images"
                            v-model="imageIndex"
                            :interval="0"
                            controls
                            indicators
                            background="#ababab"
                            img-width="1024"
                            img-height="480"
                            label-next=""
                            label-prev=""
                    >
                        <b-carousel-slide
                                :img-src="img_path+image1"
                        ></b-carousel-slide>
                        <b-carousel-slide
                                :img-src="img_path+image2"
                        ></b-carousel-slide>
                    </b-carousel>
                </b-col>
            </b-row>
        </b-modal>
        <b-row class="mr-0 ml-0 mt-3 mb-4" no-gutters>
            <b-col class="title mt-4" cols="12">
                <h3>Галерея</h3>
            </b-col>
        </b-row>
        <b-row class="mt-5 mb-0" v-if="info">
            <b-col cols="12" md="6" class="mb-4">
                <b-card :img-src="img_path+image1"
                        class="shadow href"
                        @click="showImage(0)" no-body></b-card>
            </b-col>
            <b-col cols="12" md="6" class="mb-4">
                <b-card :img-src="img_path+image2"
                        class="shadow href"
                        @click="showImage(1)" no-body></b-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "ProjectBddSmena",
        data() {
            return {
                imageIndex: null,
                img_path: process.env.VUE_APP_IMG
            }
        },        
        computed: {
            info() {
                return this.$store.getters.getProject
            },
            image1() {
                return this.info.pics.find(item => item.label == "gallery1").images
            },
            image2() {
                return this.info.pics.find(item => item.label == "gallery2").images
            },
            description: function(){
                return this.info.description.split("<hr>")
            }
        },
        methods: {
            showImage: function (i) {
                this.imageIndex = i;
                this.$bvModal.show('BigImage');
            }
        }
    }
</script>

<style lang="scss" scoped>
  h3 {
        font-family: 'SF MEDIUM';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 20px;

        color: var(--text-primary-color);
    }

    .content {
        font-family: 'SF Light';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        color: var(--text-primary-color);
    }

    .image {
        width: 100%;
        max-width: 575px;
    }

    .href {
        cursor: pointer;
    }

    .href:hover {
        opacity: 0.8;
    }

    .carousel-control-prev-icon {
        width: 30px;
        height: 48px;
    }
    .carousel-control-next-icon {
        width: 30px;
        height: 48px;
    }

    .img-size{
        height: 750px;
        width: 1000px;
        background-size: cover;
        overflow: hidden;
    }

    .modal-body {
        padding: 0;
    }
</style>