import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

import { mainStore } from './store/main'
import { lmsStore } from './store/lms'
import { eventStore } from './store/events'

export default new Vuex.Store({
    modules: {
        main: mainStore,
        lms: lmsStore,
        event: eventStore,
    }
})