<template>
    <b-container fluid>
        <b-row>
            <b-col class="mb-1 p-0">
                <contact-title></contact-title>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-1 p-0">
                <contact-map></contact-map>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-5 p-0">
                <contact-list></contact-list>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import Title from './Contacts/Title'
    import Map from './Contacts/Map'
    import List from "./Contacts/List";
    export default {
        name: "Contacts",
        components: {
          'contact-title': Title,
          'contact-map': Map,
          'contact-list': List,
        },
        mounted() {
            this.$store.dispatch('getPagesInfo', 'contacts');
            this.$store.dispatch('getTeam');
        }
    }
</script>

<style scoped>

</style>