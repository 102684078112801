<template>
    <b-container class="p-3" v-if="depart">
        <b-row>
            <b-col cols="12" :md="(checkPosition(person.position))?12:6" v-for="(person, index) in depart.depart_team" :key="'person'+index" style="margin-bottom: 64px">
                <b-row style="min-width: 420px">
                    <div style="width: 180px" class="d-none d-lg-block">
                        <b-avatar  :src="img_path+person.avatar" size="10rem"></b-avatar>
                    </div>
                    <div style="width: 100px" class="d-block d-lg-none">
                        <b-avatar  :src="img_path+person.avatar" size="6rem"></b-avatar>
                    </div>
                    <b-col cols="6" sm="7" xl="6">
                        <div class="title">{{person.fio}}</div>
                        <div class="item mt-1">{{person.position || ''}}</div>
                        <div class="item mt-3" v-if="person.phone"><Icon icon="carbon:phone-voice-filled" color="#333" height="18" :rotate="2" class="mr-2"
                                                                         :horizontalFlip="true" :verticalFlip="true" /> {{person.phone || '-'}}</div>
                        <div class="item" v-if="person.mail"> <Icon icon="cib:mail-ru" color="#333" height="16" :rotate="2" :horizontalFlip="true" class="mr-2"
                                                                    :verticalFlip="true"/> {{person.mail || '-'}}</div>
                        <div class="item" v-if="person.cabinet"><Icon icon="fluent:conference-room-24-filled" color="#333" height="18" :rotate="2" :horizontalFlip="true" :verticalFlip="true"  class="mr-2" />
                            кабинет: {{person.cabinet || '-'}}</div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import {Icon} from '@iconify/vue2';
    export default {
        name: "Team",
        data() {
            return {
                img_path: process.env.VUE_APP_IMG
            }
        },
        components: {
            Icon
        },
        computed: {
            depart: function () {
                return this.$store.getters.currentDepart;
            }
        },
        methods: {
            checkPosition: function(position) {
                return position.toLowerCase().includes('заведующий')||position.toLowerCase().includes('руководитель');
            }
        }
    }
</script>

<style scoped>
.title {
    font-family: SF MEDIUM, sans-serif;
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    color: var(--text-primary-color);
}

.item {
    font-family: SF LIGHT, sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-secondary-color);
}

</style>