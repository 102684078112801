<template>
    <b-container fluid>
        <b-container class="p-0">
        <b-card class="mb-5 list_card shadow-sm">
            <b-card-body class="pl-3 pr-3 pb-3 pt-0 m-0">
                <b-row v-for="(group, i) in list" :key="'item'+i" :class="(i%2)?'grey pl-3 pr-3 pb-3 pt-0':'pl-3 pr-3 pb-3 pt-0'">
                    <b-col v-if="group.team">
                        <b-row class="pl-3">
                            <b-col cols="12" md="6" lg="4" v-for="(person, j) in group.team" :key="'item'+j" class="mt-4">
                                <b-row>
                                    <div class="position_title pl-0 mb-4">
                                        {{person.position || ''}}
                                    </div>
                                </b-row>
                                <b-row>
                                    <div style="width: 90px" class="d-none d-md-block" v-if="person.avatar">
                                        <b-avatar  :src="img_path+person.avatar" size="5rem"></b-avatar>
                                    </div>
                                    <div style="width: 100px" class="d-block d-md-none" v-if="person.avatar">
                                        <b-avatar  :src="img_path+person.avatar" size="6rem"></b-avatar>
                                    </div>
                                    <b-col>
                                        <div class="fio" v-html="fio(person.fio)" v-if="person.fio"></div>
                                        <div class="item mt-3" v-if="person.phone"><Icon icon="carbon:phone-voice-filled" color="var(--text-secondary-color)" height="18" :rotate="2" class="mr-2"
                                                                                         :horizontalFlip="true" :verticalFlip="true" /> {{person.phone || '-'}}</div>
                                        <div class="item" v-if="person.mail"> <Icon icon="cib:mail-ru" color="var(--text-secondary-color)" height="16" :rotate="2" :horizontalFlip="true" class="mr-2"
                                                                                    :verticalFlip="true"/> {{person.mail || '-'}}</div>
                                        <div class="item" v-if="person.cabinet"><Icon icon="fluent:conference-room-24-filled" color="var(--text-secondary-color)" height="18" :rotate="2" :horizontalFlip="true" :verticalFlip="true"  class="mr-2" />
                                            кабинет: {{person.cabinet || '-'}}</div>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        </b-container>
    </b-container>
</template>

<script>
    import {Icon} from '@iconify/vue2';
    export default {
        name: "List",
        components: {
            Icon
        },
        data() {
            return {
                img_path: process.env.VUE_APP_IMG
            }
        },
        computed: {
            list: function () {
                return this.$store.getters.allTeam;
            }
        },
        methods: {
            fio: function (item) {
                const f = item.toString().split(' ')
                return f[0]+"<br>"+f[1]+" "+f[2]
            }
        }
    }
</script>

<style scoped>

    .list_card {
        border-radius: 16px !important;
        background-color: var(--background-color-secondary);
    }

    .position_title {
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 14px;
        width: 240px;
        line-height: 17px;
        color: var(--text-secondary-color);
        height: 32px;
    }

    .fio {
        font-family: SF LIGHT, sans-serif;
        font-style: normal;
        font-size: 12px;
        line-height: 14px;
        color: var(--text-secondary-color);
    }

    .item {
        font-family: SF LIGHT, sans-serif;
        font-style: normal;
        font-size: 12px;
        line-height: 18px;
        color: var(--text-secondary-color);
    }

    .grey {
        background-color: var(--background-color-question);
    }
</style>