<template>
    <b-container fluid class="p-0 m-0">
        <b-container fluid="lg" class="pl-0 pl-lg-3 pl-xl-0">
            <h1 class="ml-0">Активируй<br>свои<br>таланты!</h1>
            <!-- <img :src="require('/src/assets/img/carousel/note.png')" class="note">
            <img :src="require('/src/assets/img/carousel/pointe.png')" class="pointe d-none d-md-block"> -->
        </b-container>
        <b-carousel
                v-model="slide"
                :interval="4000"
                v-if="info"
                label-next=""
                label-prev=""
        >
            <b-carousel-slide
                    style="z-index: 2"
                    caption=""
                    :img-src="slide.image.path"
                    v-for="(slide, index) in info" :key="'s'+index">
            </b-carousel-slide>
            <img :src="require('/src/assets/img/carousel/circles.png')" class="circle">
        </b-carousel>
        <b-container fluid="lg" class="pl-0 pl-lg-3 pl-xl-0 d-none d-lg-block" v-show="loaded">
            <div class="subtitle_box">
                <div class="subtitle_subbox">
                    <div class="subtitle mr-3 float-left">Пройди<br/>викторину</div>
                    <div class="subtitle mt-2 ml-1 float-left">
                        <Icon :icon="icons.arrowDown" color="#fff" height="24" rotate="3"/>
                    </div>
                    <div class="subtitle_bold ml-3 float-left"><span>Выбери</span><br/> направление</div>
                    <div>
                        <b-button class="start_button" @click="showQuiz">Старт!</b-button>
                    </div>
                </div>
            </div>
        </b-container>
        <b-container fluid="lg" class="d-block d-lg-none" v-show="loaded">
            <div class="subtitle_box">
                <div class="subtitle_subbox">
                    <div class="subtitle">Пройди<br/>викторину</div>
                    <div class="subtitle mt-1 ml-4">
                        <Icon :icon="icons.arrowDown" color="#fff" height="20"/>
                    </div>
                    <div class="subtitle_bold mt-1"><span>Выбери</span><br/> направление!</div>
                    <div>
                        <b-button class="start_button" @click="showQuiz">Старт!</b-button>
                    </div>
                </div>
            </div>
        </b-container>
        <b-modal v-model="show" hide-footer hide-header size="xl" content-class="testWindow">
            <b-row class="p-4"
                   :style="'background: url('+require('/src/assets/img/test/back.png')+'); border-radius: 16px'">
                <b-col>
                    <b-card class="quiz_back" v-if="showResult">
                        <b-row>
                            <b-col class="text-center">
                                <h5>Дорогой друг!</h5>
                                <div class="description">Ты справился с прохождением теста!<br>
                                    Тебе подходит образовательное направление...
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="mt-4">
                                <router-link :to="{name: 'depart', params: { slug: topDepart.slug}}"
                                             style="text-decoration: none">
                                    <div class="item m-auto" :style="'background: url('+topDepart.icon_back.path+')'">
                                        <div class="color_back"
                                             :style="'background-color: '+topDepart.menu_color"></div>
                                        <div class="icon m-auto">
                                            <img :src="topDepart.icon.path">
                                        </div>
                                        <div class="title">
                                            {{topDepart.name}}
                                        </div>
                                    </div>
                                </router-link>
                            </b-col>
                        </b-row>
                        <!-- <b-row>
                            <b-col>
                                <b-list-group>
                                    <b-list-group-item v-for="(dep, i) in this.orderedDepart" :key="'d'+i">
                                        <span v-if="dep">{{dep.name}} - {{dep.value}} - {{dep.id}}</span>
                                    </b-list-group-item>
                                </b-list-group>
                            </b-col>
                        </b-row> -->
                    </b-card>
                    <b-card class="quiz_back" v-else>
                        <b-carousel ref="quizSlider" v-model="slide_quiz" :interval="0">
                            <b-carousel-slide v-for="(item, index) in quiz" :key="'q'+index" style="background: #fff !important">
                                <template #img class="custom-class">
                                    <b-row no-gutters>
                                        <b-col>
                                            <b-list-group>
                                                <b-list-group-item>
                                                    <b-row no-gutters>
                                                        <b-col cols="9" lg="9" xl="10" class="header">
                                                            <h6 class="mb-0 ml-0">{{item.name}}</h6>
                                                        </b-col>
                                                        <b-col cols="3" lg="3" xl="2">
                                                            <b-row no-gutters>
                                                                <b-col class="text-center"><h5>Да</h5></b-col>
                                                                <b-col class="text-center"><h5>Нет</h5></b-col>
                                                            </b-row>
                                                        </b-col>
                                                    </b-row>
                                                </b-list-group-item>
                                            </b-list-group>
                                        </b-col>
                                    </b-row>
                                    <hr/>
                                    <b-row no-gutters>
                                        <b-col>
                                            <b-list-group>
                                                <b-list-group-item v-for="(variant, j) in item.variants" :key="'v'+j">
                                                    <b-row no-gutters>
                                                        <b-col cols="9" lg="9" xl="10">
                                                            <span class="var_name">{{variant.name}}</span>
                                                        </b-col>
                                                        <b-col cols="3" lg="3" xl="2">
                                                            <b-form-radio-group size="lg" v-model="varValue[index][j]"
                                                                                class="float-right w-100">
                                                                <b-row no-gutters>
                                                                    <b-col class="text-center" cols="6">
                                                                        <b-form-radio value="2" name="radio-size"
                                                                                      button-variant="primary"></b-form-radio>
                                                                    </b-col>
                                                                    <b-col class="text-center" cols="6">
                                                                        <b-form-radio value="1"
                                                                                      name="radio-size"></b-form-radio>
                                                                    </b-col>
                                                                </b-row>
                                                            </b-form-radio-group>
                                                        </b-col>
                                                    </b-row>
                                                </b-list-group-item>
                                            </b-list-group>
                                        </b-col>
                                    </b-row>
                                </template>
                            </b-carousel-slide>
                        </b-carousel>
                    </b-card>

                    <b-row>
                        <b-col class="m-3">
                            <b-button variant="info" class="pl-0 pl-sm-5 pr-0 pr-sm-5 float-left quiz_button"
                                      v-if="(slide_quiz>0)&&(slide_quiz!=(quiz.length-1))" @click="prev">Назад
                            </b-button>

                            <b-button variant="primary" class="pl-5 pr-5 float-right"
                                      v-if="(slide_quiz==(quiz.length-1))&&(!showResult)" @click="result">Посмотреть
                                результат
                            </b-button>
                            <b-button variant="primary" class="pl-0 pl-sm-5 pr-0 pr-sm-5 float-right quiz_button"
                                      v-if="(slide_quiz!=(quiz.length-1))&&(!showResult)" @click="next">Далее
                            </b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-modal>
    </b-container>
</template>

<script>

    import {Icon} from '@iconify/vue2';
    import arrowDown from '@iconify/icons-bi/arrow-down';

    export default {
        name: "Slider",
        components: {
            Icon,
        },
        data() {
            return {
                icons: {
                    arrowDown,
                },
                slide: 0,
                sliding: null,
                show: false,
                slide_quiz: 0,
                varValue: [],
                depart: [],
                showResult: false,
                loaded: false
            }
        },
        computed: {
            info() {
                return this.$store.getters.allCarousel
            },
            quiz: function () {
                return this.$store.getters.allQuiz;
            },
            departs: function () {
                return this.$store.getters.allDeparts;
            },
            orderedDepart: function () {

                function compare(a, b) {
                    if (a.value < b.value)
                        return 1;
                    if (a.value > b.value)
                        return -1;
                    return 0;
                }

                return this.depart.slice().sort(compare);
            },
            topDepart: function () {
                return this.departs.find( item => (item.id==this.orderedDepart[0].id));
            }
        },
        methods: {
            showQuiz() {
                console.log("!!!");
                this.show = true;

                this.quiz.forEach((q, i) => {
                    this.varValue[i] = []
                    q.variants.forEach((v, j) => {
                        this.varValue[i][j] = 0;
                    })
                })

                this.departs.forEach(d => {
                    this.depart[d.id] = {};
                    this.depart[d.id].name = d.name;
                    this.depart[d.id].id = d.id;
                    this.depart[d.id].value = 0;
                })

            },
            prev() {
                this.$refs.quizSlider.prev()
            },
            next() {
                this.$refs.quizSlider.next()
            },
            result() {
                this.varValue.forEach((q, i) => {
                    q.forEach((v, j) => {
                        if (v == 2) this.depart[this.quiz[i].variants[j].depart].value++;
                    });
                })


                this.showResult = true
            }
        },
        mounted() {
            this.$store.dispatch('getQuiz');
        }
    }
</script>

<style scoped>

    ::v-deep .carousel-control-prev-icon {
        width: 45px;
        height: 72px;
    }

    ::v-deep .carousel-control-next-icon {
        width: 45px;
        height: 72px;
    }

    ::v-deep .carousel-inner {
        background-size: 100%;
        background-position: center;
        background-image: url("../../assets/img/carousel/back2.png");
        background-repeat: no-repeat;
        max-height: 651px !important;
        min-height: 270px !important;
    }

    .circle {
        float: left;
        margin-left: 10%;
        z-index: 1;
        width: 100%;
        max-width: 1600px;
        max-height: 651px;
        min-height: 270px !important;
    }

    ::v-deep .img-fluid {
        min-height: 270px !important;
        margin-left: 10%;
        max-width: 1600px;
    }

    h1 {
        margin: auto;
        position: absolute;
        margin-top: 7%;
        z-index: 1;
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-size: 68px;
        line-height: 54px;
        color: #FEFEFE;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }


    .subtitle_box {
        position: relative;
        margin: auto;
    }

    .subtitle_subbox {
        margin-top: -12%;
        z-index: 0;
        margin-bottom: 12%;
    }


    .start_button {
        font-family: SF MEDIUM, sans-serif;
        position: relative;
        margin-left: -264px;
        margin-top: 48px;
        font-size: 20px;
        color: #000;
        width: 160px;
        background: #FDC903 !important;
        border: 0px !important;
        cursor: pointer !important;
        z-index: 10 !important;
    }

    .start_button:hover {
        cursor: pointer !important;
        background: #da5659 !important;
    }


    .subtitle {
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        line-height: 17px;
        color: #FEFEFE;
    }

    .subtitle_bold {
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 17px;
        color: #FEFEFE;
    }

    .subtitle_bold span {
        font-family: SF BOLD, sans-serif;
        font-size: 21px;
    }

    .note {
        position: absolute;
        margin-left: 120px;
        top: 50%;
        z-index: 1;
        opacity: 0.5;
    }

    .pointe {
        position: absolute;
        margin-left: 350px;
        top: 50%;
        z-index: 1;
        opacity: 0.5;
        width: 90px;
    }

    .quiz_back {
        background-color: var(--background-color-primary);
    }

    .item {
        position: relative;
        width: 100%;
        min-height: 206px;
        min-width: 268px;
        max-width: 268px;
        background-size: 100% !important;
        background-repeat: no-repeat !important;
        border-radius: 16px;
    }


    .item:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    .item .icon {
        width: 130px;
        height: 120px;
        padding-top: 24px;
        position: relative;
        z-index:2;
    }


    .item .icon img {
        width: 125px;
    }

    .item .title {
        position: relative;
        width: 240px;
        margin: 20px auto;
        text-align: center;
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        text-decoration: none;
        color: #FFF;
        z-index:2;
    }

    .color_back {
        position: absolute;
        z-index:1;
        opacity: 0.8;
        min-height: 206px;
        min-width: 268px;
        max-width: 268px;
        width: 100%;
        border-radius: 16px;
        margin-right:32px;
    }

    h6 {
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: var(--text-secondary-color);
    }

    hr {
        color:#f1a84e;
        background-color: #f1a84e;
        height: 1px;
    }


    h5 {
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: #f1a84e;
    }

    ::v-deep .testWindow {
        border-radius: 16px;
    }

    ::v-deep .list-group-item {
        border: 0px !important;
        padding: 16px !important;
    }

    ::v-deep .list-group-item {
        background-color: var(--background-color-primary);
    }

    .header {
        background-color: var(--background-color-primary);
    }

    ::v-deep .modal-body {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    ::v-deep .custom-control-inline {
        margin: 0px !important;

    }

    .var_name {
        font-family: SF, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: var(--text-secondary-color);
    }


    .description {
        color: var(--text-secondary-color);
    }


    @media all and (max-width: 1200px) {
        h1 {
            font-size: 58px;
            line-height: 50px;
            margin-top: 5%;
        }

        .note {
            top: 60%;
            width: 40px;
        }

        .pointe {
            top: 200px;
            width: 80px;
        }
    }

    @media all and (max-width: 919px) {

        h1 {
            font-size: 40px;
            padding-left: 44px;
            line-height: 32px;
            margin-top: 16px;
        }

        .subtitle_subbox {
            margin-top: -150px;
            padding-left: 32px;
        }

        .subtitle {
            font-size: 14px !important;
        }

        .subtitle span {
            font-size: 14px !important;
        }

        .note {
            top: 60%;
            width: 30px;
        }

        .pointe {
            top: 100px;
            width: 60px;
        }

        .subtitle_bold {
            font-size: 13px;
            line-height: 14px;
        }

        .subtitle_bold span {
            font-size: 14px;
        }

        .start_button {
            margin-left: 0px;
            margin-top: 12px;
            color: #000;
            font-size: 16px;
            width: 160px;
            background: #FDC903 !important;
            border: 0px !important;
            cursor: pointer !important;
            z-index: 10 !important;
        }
    }

    @media all and (max-width: 639px) {

        h1 {
            margin-top: 8%;
            font-size: 32px;
            padding-left: 16px;
            line-height: 28px;
        }

        .subtitle_subbox {
            margin-top: -150px;
            padding-left: 8px;
        }

        .subtitle {
            font-size: 16px !important;
        }


        .subtitle_bold {
            font-size: 13px;
            line-height: 14px;
        }

        .subtitle_bold span {
            font-size: 14px;
        }

        .circle {
            width: 160%;
            margin-left: -120px;
        }

        ::v-deep .img-fluid {

            margin-left: -120px;
            width: 160% !important;
        }

        .subtitle {
            font-size: 16px;
        }

        ::v-deep .list-group-item {
            padding: 8px 0px !important;
        }
    }


    @media all and (max-width: 456px) {

        h1 {
            margin-top: 8%;
            font-size: 24px;
            padding-left: 20px;
            line-height: 20px;
        }

        .subtitle_subbox {
            margin-top: -170px;
            padding-left: 8px;
        }

        .subtitle_bold {
            font-size: 14px;
            line-height: 14px;
        }

        .subtitle_bold span {
            font-size: 16px;
        }

        .quiz_button {
            width: 100px;
        }
    }

</style>