<template>
    <b-container class="p-3 mb-0" v-if="info">
        <b-row>
            <b-col class="text-center mt-0 mb-5" v-if="info.pics">
                <img :src="img_path+image_logo" class="image_logo" />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    
    export default {
        name: 'ProjectsJuniorFooter',
        data() {
            return {
                img_path: process.env.VUE_APP_IMG
            }
        },        
        computed: {
            info() {
                return this.$store.getters.getProject
            },
            image_logo() {
                return this.info.pics.find(item => item.label == "logo").images
            }
        }
    }
</script>

<style scoped>

    h3 {
        font-family: 'SF';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        color: var(--text-primary-color);
    }

    .content {
        font-family: 'SF Light';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
    }

    .image {
        width: 100%;
    }

    .image_footer {
        width: 80%;
    }

    .image_logo{
        width: 300px;
    }
</style>