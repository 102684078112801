<template>
    <b-container fluid="xl" class="p-0 pl-3 pl-xl-0 pr-md-0">
        <b-row>
            <b-col class="title mt-3 ml-3 ml-lg-0 ml-xl-3 mb-5">
                ПАРТНЁРЫ
            </b-col>
        </b-row>
        <b-row>
            <b-col v-if="info" class="text-center">
                <b-row class="pl-5">
                    <b-col>
                        <carousel
                                :perPageCustom="[[720, 2], [820, 3], [1024, 4], [1224, 5]]"
                                mouseDrag
                                autoplay
                                loop
                                :speed="2000"
                                :autoplay-timeout="5000">
                            <slide v-for="(item, index) in info" :key="'p'+index" class="item">
                                <img :src="(getTheme=='light-theme')?item.image.path:(item.image_dark)?item.image_dark.path:item.image.path" >
                            </slide>
                        </carousel>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>


<script>
    import { Carousel, Slide } from 'vue-carousel';

    export default {
        name: "Partners",
        components: {
            'carousel': Carousel,
            'slide': Slide
        },
        data() {
            return {

            };
        },
        computed: {
            info: function () {
                return this.$store.getters.allPartners
            },
            getTheme() {
                return this.$store.getters.userTheme
            }
        }
    }
</script>

<style scoped>
    .item img {
        width: 130px;
    }



</style>