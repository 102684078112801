<template>
   <b-row class="mb-5">
      <b-col cols="12">
         <b-row v-if="age&&type">
            <b-col cols="12" md="6" lg="4" xl="3" v-for="(course, index) in filteredCourses" :key="'course'+index">
                     <b-card
                             class="item mt-3"
                             :img-src="course.image_description.path"
                           img-alt="Изображение курса"
                           img-top
                           img-height="220">
                        <b-card-text>
                           <div class="age">{{course.age_in}} - {{course.age_out}} лет</div>
                           <div class="course_item">{{course.name}}</div>
                           <div class="mt-3">
                              <router-link  class="href_small" :to="{name: 'course', params: {slug: course.slug}}">Подробнее</router-link>
                           </div>
                        </b-card-text>
                     </b-card>
            </b-col>
         </b-row>
         <div v-if="!filteredCourses.length" class="course_item">Извините, курсов с такими условиями нет</div>
      </b-col>
      <!--<b-col class="mt-4">
         <b-button @click="refreshFilter" variant="secondary">очистить фильтр</b-button>
      </b-col>-->
   </b-row>
</template>

<script>

    export default {
       name: "Courses",
       computed: {
          age: function(){
             return this.$store.getters.filterAge
          },
          type: function(){
             return this.$store.getters.filterType
          },
          depart: function(){
             return this.$store.getters.currentDepart
          },
          filteredCourses: function(){
             return this.depart.courses.filter(item => {
                return ((item.course_type_id==this.type.id)&&(((item.age_in>=this.age.age_from)&&(item.age_in<=this.age.age_to))||((item.age_out>=this.age.age_from)&&(item.age_out<=this.age.age_to))||((item.age_in<this.age.age_from)&&(item.age_out>this.age.age_to))))
             })
          }
       },
       methods: {
          refreshFilter: function(){
             this.$store.commit('SET_DEPART', null);
             this.$store.commit('SET_FILTER_AGE', null);
             this.$store.commit('SET_FILTER_TYPE', null);

          }
       }
    }
</script>

<style scoped>
   .item {
      min-width: 220px;
      float: left;
      background-color: var(--background-color-secondary);
   }

   .item .icon {
      width: 130px;
      height: 120px;
      padding-top: 24px;
   }

   .item .icon img {
      width: 130px;
      height: 110px;
   }

   .item .title {
      width: 240px;
      margin: 36px auto;
      text-align: center;
      font-family: SF, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-decoration: none;
      color: var(--text-secondary-color);
   }

   .age {
      font-family: SF BOLD, sans-serif;
      top: 12px;
      left: 0px;
      position: absolute;
      background-color: #FECC00;
      color: #2B2A29;
      font-size: 12px;
      padding: 4px 8px;

   }

   .course_item {
      font-family: SF BOLD, sans-serif;
      height: 60px;
      padding: 4px;
      font-size: 14px;
      line-height: 16px;
      color: var(--text-secondary-color);
   }

   .course_image {
      height: 40px;
      float:left;
      margin-right: 16px;
   }

</style>