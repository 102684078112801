<template>
    <b-container fluid class="pl-3 pr-3">
        <b-row>
            <b-col :style="(getTheme=='light-theme')?'background: url('+require('/src/assets/img/parents_buttons.png')+') #F7F7F7 no-repeat':'background: url('+require('/src/assets/img/parents_buttons.png')+') var(--background-color-primary) no-repeat'" class="title_block">
                <b-container>
                <b-row>
                    <b-col class="title_prefix">ДВОРЕЦ ON LINE</b-col>
                </b-row>
                <b-row>
                    <b-col class="title">Дистанционное<br>образование</b-col>
                </b-row>
                <b-row>
                    <b-col class="buttons">
                        <div v-for="(site, index) in filterSites.slice(0, 3)" :key="'site'+index">
                            <a :href="site.path" target="_blank"><b-button :style="'background: '+colors[index]+' !important'" class="button">{{site.name}}</b-button></a>
                        </div>
                    </b-col>
                </b-row>
                </b-container>
            </b-col>
        </b-row>

    </b-container>
</template>

<script>
    export default {
        name: "Online",
        data() {
            return {
                colors: [
                    '#9AAF22',
                    '#008DD2',
                    '#E5097F'
                ]
            }
        },
        computed: {
            info: function () {
                return this.$store.getters.allParentsPage;
            },
            sites: function () {
                return this.$store.getters.allWebsites;
            },
            filterSites: function () {
                return this.sites.filter(item => {
                    let a = item.directions.filter(direction => {
                        return (direction.id === 1)
                    });
                    if (a.length) return true;
                })
            },
            getTheme() {
                return this.$store.getters.userTheme
            }
        },
        mounted(){
                this.$store.dispatch('getWebsites');
        }
    }
</script>

<style scoped>
    .title_block {
        height: 500px;
        background-position: center !important;
        background-size: 1900px !important;
    }

    .title_prefix{
        text-align: right;
        margin-top: 100px;
        width: 600px;
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        color: #5B5B5B;

    }

    .title {
        text-align: right;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin-top: 16px;
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        line-height: 40px;
        color: #FEFEFE;
    }

    .buttons {
        text-align: right;
        margin-top: 32px;
    }

    .button {
        border: 0px;
        border-radius: 8px;
        width: 320px;
        font-family: SF, sans-serif;
        font-style: normal;
        font-weight: 400;
        padding: 12px 8px !important;
        font-size: 18px;
        line-height: 18px;
        color: #FEFEFE;
        margin-bottom: 12px;
    }

    @media all and (max-width: 1200px) {
        .title_block {
            background-position-x: 50% !important;
        }
        .start_image {
            position: relative;
            height: 178px;
            background-size: 1901px !important;
            background-position: -325px !important;
        }
    }

    @media all and (max-width: 920px) {
        .title_block {
            background-position-x:50% !important;
        }
        .title {
            font-size: 36px;
            line-height: 36px;
        }

        .button {
            font-size: 16px;
            line-height: 16px;
            width: 240px;
            padding: 8px 4px !important;
        }

        .start_image {
            background-size: 1250px !important;
            background-position-x: -200px !important;
        }
    }

    @media all and (max-width: 640px) {
        .title_block {
            background-position-x: 100% !important;
        }
        .title_prefix {
            text-align: center !important;
        }
        .title {
            text-align: center !important;
        }
        .buttons {
            text-align: center !important;
        }

        .start_image {
            height: 66px;
            width: 100%;
            background-size: 840px !important;
            background-position-x: -120px !important;
        }
    }

    .button:hover {
        opacity: 0.8;
    }


</style>