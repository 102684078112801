<template>
    <b-container fluid v-if="orgList">
        <b-row>
            <div class="menu ml-3 mr-5 mr-xl-0">
                <b-list-group class="menu pt-2 pb-2">
                    <b-list-group-item v-for="(link, index) in orgList" :key="'org'+index" :class="(link.slug==slug) ? 'menu_item_check' : 'menu_item'">
                        <router-link :to="{name: 'org', params: {slug: link.slug}}">{{link.name}}</router-link>
                    </b-list-group-item>
                </b-list-group>
            </div>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Menu",
        props: ['slug'],
        computed: {
            orgList: function () {
                return this.$store.getters.allOrg;
            }
        }
    }
</script>

<style scoped>

    .menu {
        width: 100%;
        background: var(--background-color-secondary);
    }

    .menu_item, .menu_item_check {
        background: var(--background-color-secondary);
        border: 0px !important;
        line-height: 16px;
        padding: 8px 16px;
    }

    .menu_item a, .menu_item_check a {
        color: var(--text-secondary-color) !important;
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
    }

    .menu_item_check a{
        color: #3A90B9 !important;
    }

</style>