<template>
  <div id="app">
    <b-container fluid class="pl-0 pr-0 main">
      <Header />
      <Menu />
      <transition mode="in-out">
      <router-view :key="$route.params.name + $route.params.slug"/>
      </transition>
      <Footer />
    </b-container>
  </div>
</template>

<script>
import Header from './components/Header'
import Menu from './components/Menu'
import Footer from "./components/Footer";

export default {
  name: 'Home',
  components: {
    Header,
    Menu,
    Footer
  },
  mounted() {
    this.$store.dispatch('getSiteInfo')
  }
}
</script>
<style>

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.4s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }

  body {
    background-color: var(--background-color-primary);
  }
  .main {
    background-color: var(--background-color-primary);
  }

  @font-face {
    font-family: "SF Bold";
    src: local("SF Bold"),   url(./assets/fonts/SFUIDISPLAY-BOLD_0.TTF) format("truetype");
  }

  @font-face {
    font-family: "SF Medium";
    src: local("SF Bold"),   url(./assets/fonts/SFUIDISPLAY-MEDIUM_0.TTF) format("truetype");
  }

  @font-face {
    font-family: "SF";
    src: local("SF Bold"),   url(./assets/fonts/SFUIDISPLAY-REGULAR_0.TTF) format("truetype");
  }

  @font-face {
    font-family: "SF Light";
    src: local("SF Bold"),   url(./assets/fonts/SFUIDISPLAY-LIGHT_0.TTF) format("truetype");
  }

  @font-face {
    font-family: "SF SEMIBOLD";
    src: local("SF Srmibold"),   url(./assets/fonts/SFUIDISPLAY-SEMIBOLD_0.TTF) format("truetype");
  }
</style>