<template>
    <b-container fluid="xl" class="pl-3 pl-md-3 pl-xl-1">
        <!--<b-row class="mt-0 mb-5">
            <b-col cols="12">
                <div class="ml-3 float-right">
                    <span class="title">Выберите тип услуги, возраст и направление</span>
                </div>
            </b-col>
        </b-row>-->
            <b-row class="mb-4">
                <b-col class="pl-1 pl-sm-3">
                    <div cols="4"  v-for="(type, index) in types" :key="'types'+index" :class="(currentTypeId==type.id)?'types check mt-3 mt-lg-0':'types mt-3 mt-lg-0'" @click="chooseType(type)">
                        {{type.name}}
                    </div>
                </b-col>
            </b-row>
            <b-row class="mb-4">
                <b-col class="pl-1 pl-sm-3">
                    <div v-for="(age, index) in ages" :key="'ages'+index" :class="(currentAgeId==age.id)?'ages check':'ages'" @click="chooseAge(age)">
                        {{age.name}}
                    </div>
                </b-col>
            </b-row>
            <b-row v-if="(info)&&(!currentDepart)">
                <b-col cols="12" class="p-0">
                    <departs></departs>
                </b-col>
            </b-row>
            <b-row v-if="(info)&&(currentDepart)">
                <b-col>
                    <courses></courses>
                </b-col>
            </b-row>
        </b-container>
</template>

<script>
    import Courses from "./Courses";
    import Departs from "./Departs";
    export default {
        name: "ChooseCourse",
        components: {
            Courses,
            Departs
        },
        data() {
            return {
            }
        },
        computed: {
            info: function () {
                return this.$store.getters.allDeparts
            },
            types: function(){
                return this.$store.getters.allCourseTypes
            },
            ages: function(){
                return this.$store.getters.allFilterAge
            },
            currentAge: function(){
                return this.$store.getters.filterAge
            },
            currentType: function(){
                return this.$store.getters.filterType
            },
            currentTypeId: function(){
                if (this.currentType) return this.currentType.id;
                else return null;
            },
            currentDepart: function(){
                return this.$store.getters.currentDepart
            },
            currentAgeId: function(){
                if (this.currentAge) return this.currentAge.id;
                else return null;
            }
        },
        mounted(){
            this.$store.dispatch('getDepartList');
        },
        methods: {
            chooseAge: function(data){
              this.$store.commit('SET_FILTER_AGE', data);
                this.$store.commit('SET_DEPART', null);
            },
            chooseType: function(data){
                this.$store.commit('SET_FILTER_TYPE', data);
                this.$store.commit('SET_DEPART', null);
            }
        }
    }
</script>

<style scoped>
    .title {
        font-family: Roboto, sans-serif;
        width: 200px;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 20px;
        color: #999;
    }

    .types {
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
        text-decoration: none;
        color: #808080;
        margin-right: 40px;
        float: left;
    }

    .ages {
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 24px;
        text-decoration: none;
        color: #808080;
        margin-right: 40px;
        float: left;
    }

    .check{
        color: #EF7F1A;
    }
    .types:hover, .ages:hover {
        color: #9AAF22;
        cursor:pointer;
    }

    @media all and (max-width: 919px) {
        .types {
            font-family: SF BOLD, sans-serif;
            font-style: normal;
            font-size: 16px;
            line-height: 20px;
        }

        .ages {
            font-family: SF, sans-serif;
            font-style: normal;
            font-size: 14px;
            line-height: 30px;
            margin-right: 20px;
        }
    }
</style>