<template>
    <b-container fluid>
        <b-row>
            <b-col class="mb-1 p-0">
                <fire-title></fire-title>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-1 mt-0 p-0 ml-2 mr-2 ml-xl-0">
                <fire-description></fire-description>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-1 mt-4 p-0 ml-2 mr-2 ml-xl-0">
                <fire-docs></fire-docs>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import Title from "./Fire/Title";
    import Docs from "./Fire/Docs";
    import Description from "./Fire/Description";
    export default {
        name: "Fire",
        components: {
            'fire-title': Title,
            'fire-docs': Docs,
            'fire-description': Description
        },
        mounted() {
            this.$store.dispatch('getPagesInfo', 'fire');
        }
    }
</script>

<style scoped>

</style>