<template>
    <b-container fluid class="p-0 m-0">
        <b-row class="p-0 m-0">
            <b-col cols="12" class="p-0 m-0">
                <slider></slider>
            </b-col>
        </b-row>
        <b-row class="m-0">
            <b-col  :style="(getTheme=='light-theme')?'background: url('+require('/src/assets/img/news_back.png')+') no-repeat; background-position: 10% 60%':''">
                <b-row>
                    <b-col class="mt-0 mb-4">
                        <news></news>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mt-0 mb-0">
                        <depart-menu></depart-menu>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mt-5 mb-4">
                        <navigator></navigator>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row class="m-0">
            <b-col class="mt-5 mb-5">
                <about></about>
            </b-col>
        </b-row>
        <b-row class="m-0">
            <b-col class="p-0 mt-0 mb-0">
                <websites></websites>
            </b-col>
        </b-row>
        <b-row class="m-0">
            <b-col class="mt-5 mb-5">
                <gallery></gallery>
            </b-col>
        </b-row>
        <b-row class="m-0">
            <b-col class="mt-2 mb-5">
                <partners></partners>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>

    import Partners from "./Main/Partners";
    import Gallery from "./Main/Gallery";
    import Websites from "./Main/Websites";
    import About from "./Main/About";
    import News from "./Main/News";
    import Slider from "./Main/Slider";
    import Navigator from "./Main/Navigator";
    import DepartMenu from "./Main/DepartMenu";
    import './../assets/css/main.scss'

    export default {
        name: "Main",
        components: {
            Partners,
            Gallery,
            Websites,
            About,
            News,
            Slider,
            Navigator,
            DepartMenu
        },
        mounted() {
            this.$store.dispatch('getCarousel');
            this.$store.dispatch('getPartners');
            this.$store.dispatch('getWebsites');
            this.$store.dispatch('getDepartList');
            this.$store.dispatch('getInfoStat');
            this.$store.dispatch('getGallery');
            this.$store.dispatch('getNavigatorInfo');
            this.$store.dispatch('getNews', 0);
        },
        computed: {
            getTheme() {
                return this.$store.getters.userTheme
            }
        },
    }
</script>

<style scoped>

</style>