<template>
    <b-container fluid>
        <b-row>
            <b-col class="mb-1 p-0">
                <psy-title/>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-1 p-0">
                <psy-description></psy-description>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-0 p-0">
                <psy-docs></psy-docs>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import Title from "./Psy/Title";
    import Description from "./Psy/Description";
    import Docs from "./Psy/Docs";

    export default {
        name: "Psy",
        components: {
            'psy-title': Title,
            'psy-description': Description,
            'psy-docs': Docs
        },
        mounted() {
            this.$store.dispatch('getPagesInfo', 'psy');
        }
    }
</script>

<style scoped>

</style>