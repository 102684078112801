<template>
    <b-container fluid="xl" class="p-0 pl-2 pl-md-4 pr-2 pl-lg-5 pl-xl-1" v-if="depart">
        <b-row class="mt-3 mb-4">
            <b-col class="title" cols="12">
                О направлении
            </b-col>
        </b-row>
        <b-row>
            <b-col class="d-block d-lg-none text-center mb-5">
                <img :src="depart.image_description.path" v-if="depart.image_description" class="image_description">
            </b-col>
        </b-row>
        <b-row align-v="top">
            <b-col cols="12" lg="6" xl="8">
                <b-row v-if="depart.description">
                    <b-col class="content" cols="12" xl="6" v-html="description[0]">
                    </b-col>
                    <b-col class="content" cols="12" xl="6" v-html="description[1]">
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" lg="6" xl="4" class="d-none d-lg-block text-right">
                <img :src="depart.image_description.path" v-if="depart.image_description" class="image_description">
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Description",
        computed: {
            depart: function () {
                return this.$store.getters.currentDepart;
            },
            description: function(){
                return this.depart.description.split("<hr>");
            }
        }
    }
</script>

<style scoped>
    .title {
        width: 600px;
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-size: 48px;
        line-height: 56px;
        color: #9D9E9E;
    }

    .content {
        padding-right: 32px;
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        color: var(--text-secondary-color);
    }

    .image_description {
        width: 100%;
        max-width: 460px;
    }
</style>