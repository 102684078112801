<template>
    <b-container fluid>
        <b-row v-if="info.image_title">
            <b-col class="head_block p-0" v-if="info.slug=='faq'">
                <div :style="'background: url('+info.image_title.path+') no-repeat'" class="title_block">
                    <b-container class="pl-3 pl-md-0 pl-xl-3">
                        <b-row class="pl-3 pr-3 pl-md-3 pl-lg-3 pl-xl-0">
                            <div class="title nl2br">{{info.title}}</div>
                        </b-row>
                    </b-container>
                </div>
            </b-col>
            <b-col class="p-0" v-else>
                <b-skeleton-img height="259px"></b-skeleton-img>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Title",
        data() {
            return {
                image: null,
                load: false
            }
        },
        computed: {
            info: function () {
                return this.$store.getters.allPages;
            }
        }
    }
</script>

<style scoped>
    .head_block {
        background: linear-gradient(to right, #E5097F 50%, #AF4C85 50%);
        width: 100% !important;
    }
    .title_block {
        margin: auto;
        min-height: 259px;
        width: 100% !important;
        max-width: 100% !important;
        background-size: 1600px !important;
        background-position: center !important;
    }

    .title {
        margin-top: 104px;
        width: 1200px;
        max-width: 1200px;
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 52px;
        line-height: 46px;
        color: #FFF;
    }

    @media all and (max-width: 1200px) {
        .title_block {
            min-height: 227px;
            max-height: 260px;
            background-size: 1400px !important;
        }

        .title {
            font-size: 44px;
            line-height: 36px;
        }
    }

    @media all and (max-width: 920px) {
        .title_block {
            min-height: 198px;
            background-size: 1220px !important;
            background-position-x: 70% !important;
        }

        .title {
            margin-top: 110px;
            font-size: 40px;
            line-height: 34px;
        }
    }

    @media all and (max-width: 640px) {
        .title_block {
            min-height: 182px;
            background-size: 1120px !important;
            background-position-x: 65% !important;
        }

        .title {
            font-size: 36px;
            line-height: 32px;
        }
    }

    @media all and (max-width: 460px) {
        .title_block {
            min-height: 130px;
            background-size: 800px !important;
            background-position-x: 65% !important;
        }

        .title {
            margin-top: 76px;
            font-size: 24px;
            line-height: 22px;
        }
    }

    .nl2br {
        white-space: pre;
    }

</style>