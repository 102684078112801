import Vue from 'vue'
import Router from 'vue-router'
import Main from './pages/Main.vue'
import News from './pages/News.vue'
import Depart from './pages/Depart.vue'
import Course from "./pages/Course";
import Parents from "./pages/Parents";
import Faq from "./pages/Faq";
import Gallery from "./pages/Gallery";
import EventList from "./pages/EventList";
import Event from "./pages/Event";
import Info from "./pages/Info";
import About from "./pages/About";
import Contacts from "./pages/Contacts";
import RRC from "./pages/RRC";
import OVZ from "./pages/OVZ";
import PSY from "./pages/Psy";
import SVO from "./pages/SVO";
import Anticheat from "./pages/Anticheat";
import Kassa from "./pages/Kassa";
import SheduleDocs from "./pages/SheduleDocs";
import Projects from "./pages/Project";
import Fire from "./pages/Fire";
import Programms from "./pages/Programms";
import Error from "./pages/Error";

Vue.use(Router);

export default new Router({
  mode: 'history',
  base:process.env.BASE_URL,
  routes: [
      {
          path: '/',
          name: 'home',
          component: Main
      },
      {
          path: '/news/:id',
          name: 'news',
          component: News
      },
      {
          path: '/news',
          name: 'news',
          component: News
      },
      {
          path: '/gallery/:id',
          name: 'gallery',
          component: Gallery
      },
      {
          path: '/gallery',
          name: 'gallery',
          component: Gallery
      },
      {
          path: '/parents',
          name: 'parents',
          component: Parents
      },
      {
          path: '/faq',
          name: 'faq',
          component: Faq
      },
      {
          path: '/depart/:slug',
          name: 'depart',
          component: Depart
      },
      {
          path: '/course/:slug',
          name: 'course',
          component: Course
      },
      {
          path: '/event/:id',
          name: 'event',
          component: Event
      },
      {
          path: '/events',
          name: 'events',
          component: EventList
      },
      {
          path: '/org/:slug',
          name: 'org',
          component: Info
      },
      {
          path: '/info',
          name: 'info',
          component: Info
      },
      {
          path: '/about',
          name: 'about',
          component: About
      },
      {
          path: '/contacts',
          name: 'contacts',
          component: Contacts
      },
      {
          path: '/rrc',
          name: 'rrc',
          component: RRC
      },
      {
          path: '/ovz',
          name: 'ovz',
          component: OVZ
      },
      {
          path: '/psy',
          name: 'psy',
          component: PSY
      },
      {
          path: '/svo',
          name: 'svo',
          component: SVO
      },
      {
          path: '/anticheat',
          name: 'anticheat',
          component: Anticheat
      },
      {
          path: '/kassa',
          name: 'kassa',
          component: Kassa
      },
      {
          path: '/shedule',
          name: 'shedule',
          component: SheduleDocs
      },
      {
          path: '/project/:slug',
          name: 'project',
          component: Projects
      },
      {
          path: '/fire',
          name: 'fire',
          component: Fire
      },
      {
          path: '/programms',
          name: 'programms',
          component: Programms
      },
      { 
        path: "*", 
        component: Error
      }
  ],
   scrollBehavior (to, from, savedPosition) {
       if (savedPosition) {
           return savedPosition
       } else {
           return new Promise((resolve) => {
               setTimeout(() => {
               resolve ({x: 0, y: 0})
           }, 800);
           })
       }
   }
  }
)