<template>
    <b-container fluid>
        <b-row>
            <b-col class="head_block p-0">
                <div :style="'background: url('+image+') no-repeat'" class="title_block">
                    <b-container class="pl-4 pl-xl-3">
                        <b-row>
                            <div class="title nl2br">Все<br>новости</div>
                            <b-col class="align-bottom title_calendar_block" v-if="!this.idNews">
                                <a id="calendar" @click="showPopover('calendar')" class="title_calendar">Найти в календаре</a>  
                                <a @click="clearDay" class="title_calendar" v-if="day.length">(все дни)</a></b-col>
                            <b-popover
                                    :variant="(getTheme=='light-theme')?'light':'dark'"
                                    target="calendar"
                                    class="popover-calendar"
                                    placement="right"
                            >
                                <b-calendar v-model="day"
                                            label-help=""
                                            label-selected="выбрана дата"
                                            locale="ru-RU"
                                            start-weekday="1"
                                            @selected="selectDay('calendar')"
                                            :hide-header=true></b-calendar>
                            </b-popover>
                        </b-row>


                    </b-container>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>

    export default {
        name: "Title",
        data() {
            return {
                image: require('@/assets/img/news/title_back.png'),
                day: ''
            }
        },
        methods: {
            clearDay: function(){
                this.$store.commit('GET_ALL_NEWS');
                this.day = '';
            },
            showPopover: function(id){
                this.$root.$emit('bv::show::popover', id)
            },
            selectDay: function(id){
                if (!this.day){
                    this.$store.commit('GET_ALL_NEWS');
                }
                else this.$store.commit('GET_NEWS_BY_DATE', this.day);
                this.$store.commit('SET_NEWS_DAY', this.day);
                this.$root.$emit('bv::hide::popover', id)
            }
        },
        mounted(){
            this.day = this.$store.getters.newsDay;
        },
        computed: {
            idNews: function(){
                return this.$route.params.id;
            },
            getTheme() {
                return this.$store.getters.userTheme
            }
        }
    }
</script>

<style scoped>
    .head_block {
        background: linear-gradient(to right, #7064A2 50%, #7E71B1 50%);
        width: 100% !important;
    }

    .title_block {
        margin: auto;
        min-height: 260px;
        width: 100% !important;
        max-width: 100% !important;
        background-size: 1600px !important;
        background-position: center !important;
    }

    .title {
        margin-top: 84px;
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 52px;
        line-height: 46px;
        color: #f5f5f5;
    }


    .title_text {
        margin-top: 104px;
        width: 1200px;
        max-width: 1200px;
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 62px;
        line-height: 62px;
        color: #FFF;
        text-shadow: 0px 0px 10px #636363;
    }

    .nl2br {
        white-space: pre;
    }

    @media all and (max-width: 920px) {
        .title_block {
            min-height: 200px;
            background-size: 1220px !important;
            background-position-x: 50% !important;
        }

        .title {
            margin-top: 80px;
            font-size: 40px;
            line-height: 34px;
        }
    }

    @media all and (max-width: 640px) {
        .title_block {
            min-height: 184px;
            background-size: 1120px !important;
            background-position-x: 45% !important;
        }

        .title {
            font-size: 36px;
            line-height: 32px;
        }
    }

    @media all and (max-width: 460px) {
        .title_block {
            min-height: 130px;
            background-size: 800px !important;
            background-position-x: 56% !important;
        }

        .title {
            margin-top: 48px;
            font-size: 24px;
            line-height: 22px;
        }
    }

    .title_calendar_block {
        margin-top: 142px;
        margin-left: 16px;
    }

    .title_calendar {
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 20px;
        font-weight: 400;
        cursor: pointer;
        color: #FFF;
        text-shadow: 0px 0px 10px #636363;
        text-decoration: none;
    }

    .title_calendar:hover {
        color: #f1ea3a;
    }

    .popover {
        margin-left: 20px;
        max-width: 306px !important;
        width: 306px !important;
        border-radius: 16px;
    }

    .popover .arrow {
        display: block;
    }

    ::v-deep  .form-control {
        border: 0px;
    }

    ::v-deep .b-calendar-header {
        display: none;
    }

</style>