<template>
    <b-container fluid="xl" class="pl-4 pl-md-4 pl-lg-4 pl-xl-0 pr-2" v-if="info">
        <b-row class="mr-0 ml-0 mt-0 mb-4" no-gutters>
            <b-col class="title mt-4" cols="12">
                Документы
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-5">
                <b-row v-for="(item, index) in info.docs" :key="'doc' + index">
                    <div class="book_icon pl-3 mt-2">
                        <img :src="require('/src/assets/img/rrc/book_icon.png')" />
                    </div>
                    <b-col class="description mr-5 mt-2">
                        <a :href="item.path" v-if="item" target="_blank">{{ item.title }}</a>
                        <a v-else>
                            <h4>{{ item.title }}</h4>
                        </a>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: "Docs",
    computed: {
        info: function () {
            return this.$store.getters.allPages;
        },
        docs: function () {
            return this.docs.filter(item => {
                return (item.type == 1)
            })
        }
    }
}
</script>

<style scoped>
.title {
    width: 600px;
    font-family: SF MEDIUM, sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: var(--text-primary-color);
}

.doc_item a {
    font-family: SF, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #F2913A;
}
</style>