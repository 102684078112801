<template>
    <b-container fluid class="p-0">
        <b-row no-gutters>
            <b-col class="event_back">
                <b-container class="m-auto">
                    <div id="circle_big"><img :src="circle_big" /></div>
                    <div id="circle_medium"><img :src="circle_medium" /></div>
                    <b-row class="p-0">
                        <b-col class="event_block shadow m-0 mb-5 mt-3 m-lg-5">
                            <div id="circle_small"><img :src="circle_small" /></div>
                            <b-row>
                                <b-col>
                                    <event-title></event-title>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12" lg="6">
                                    <event-timeline></event-timeline>
                                </b-col>
                                <b-col cols="12" lg="6">
                                    <event-contacts></event-contacts>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-container>
            </b-col>
        </b-row>
        <!-- <b-row no-gutters>
            <b-col>
                <event-question></event-question>
            </b-col>
        </b-row>
        -->
    </b-container>
</template>

<script>

    import Title from './Event/Title'
    import Timeline from './Event/Timeline'
    import Contacts from './Event/Contacts'
    // import Question from './Event/Question'

    export default {
        name: "Event",
        components: {
            'event-title': Title,
            'event-timeline': Timeline,
            'event-contacts': Contacts
       //     'event-question': Question
        },
        data() {
            return {
                circle_big: require('@/assets/img/main/gallery_back.png'),
                circle_medium: require('@/assets/img/events/circle2.png'),
                circle_small: require('@/assets/img/events/circle1.png')
            }
        },
        mounted() {
            this.$store.dispatch('getEventPage');
            this.$store.dispatch('getCurrentEvent', this.$route.params.id);
        }
    }
</script>

<style scoped>
    .event_back {
        overflow: hidden;
        min-height: 400px;
        z-index: 2;
    }

    .event_block {
        min-height: 400px;
        background-color: var(--background-color-secondary);
        border-radius: 16px;
    }

    #circle_big {
        position: absolute;
        bottom: 0px;
        right:-140px;
        z-index: 0;
    }

    #circle_big img {
        width: 700px;
    }

    #circle_medium {
        position: absolute;
        top: 200px;
        left:5%;
        z-index: 0;
    }

    #circle_medium img {
        width: 350px;
    }

    #circle_small {
        position: absolute;
        top: 60px;
        right:-130px;
        z-index:99;
    }

    #circle_small img {
        width: 170px;
    }

    @media all and (max-width: 640px) {
        #circle_small {
            top: 20px;
            right:-60px;
        }

        #circle_small img {
            width: 120px;
        }
    }

    @media all and (max-width: 420px) {
        #circle_small {
            right:-20px;
        }
        #circle_small img {
            width: 80px;
        }
    }
</style>