<template>
    <b-container fluid>
        <b-row>
            <b-col class="mb-1 p-0">
                <gallery-title></gallery-title>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-0">
                <gallery-list v-if="!idGallery"></gallery-list>
                <gallery-item v-else></gallery-item>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import Title from './Gallery/Title';
    import List from './Gallery/List';
    import Item from './Gallery/Item'

    export default {
        name: "Gallery",
        components: {
            'gallery-title': Title,
            'gallery-list': List,
            'gallery-item': Item
        },
        mounted() {
            this.$store.dispatch('getGallery');
        },
        computed: {
            idGallery: function(){
                return this.$route.params.id;
            }
        }
    }
</script>

<style scoped>

</style>