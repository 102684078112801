<template>
    <b-container fluid>
        <b-row>
            <b-col class="mb-1 p-0">
                <bilet-description />
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-0 p-0">
                <bilet-footer />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import Description from "./Bilet/Description";
    import Footer from "./Bilet/Footer";
    export default {
        name: "ProjectBilet",
        components: {
            'bilet-description': Description,
            'bilet-footer': Footer
        },
        mounted() {
            this.$store.dispatch('getProject', 'bilet');
        }
    }
</script>

<style scoped>

</style>