<template>
    <b-container fluid="xl" class="pl-3 pl-sm-5 pl-lg-5 pl-xl-0 pr-5 pr-sm-5 pr-xl-0">
        <b-row>
            <b-col class="title mt-3 ml-0 mb-5">
                <span class="title"> НОВОСТИ &nbsp; <span class="line">|</span></span> &nbsp; <a @click='toNews'  class="href">Все новости</a><span class="title"> &nbsp; <span class="line">|</span> &nbsp; </span> <a id="calendar" @click="showPopover('calendar')" class="href">Найти в календаре</a>
            </b-col>
            <b-popover
                    target="calendar"
                    class="popover-calendar"
                    triggers="click blur"
                    placement="right"
            >
                <b-calendar v-model="day"
                            label-help=""
                            label-selected="выбрана дата"
                            locale="ru-RU"
                            start-weekday="1"
                            @selected="hidePopover('calendar')"
                            :hide-header=true></b-calendar>
            </b-popover>
        </b-row>
        <b-row v-if="info">
            <b-col cols="12">
                <b-card-group classs="pl-0 ml-0"
                        deck>
            <!-- <b-col v-for="(item, index) in news" :key="'p'+index" xl="4" lg="6" md="6" class="mb-4"> -->
                <b-card
                        v-for="(item, index) in news"
                        :key="'p'+index"
                        img-alt="Image"
                        img-top
                        img-width="250"
                        img-height="250"
                        tag="article"
                        class="news_item"
                >
                    <b-row no-gutters>
                        <b-col cols="5" md="5" lg="12">
                            <b-card-img :src="img_path+item.poster" alt="Image" class="news_item_image"></b-card-img>
                        </b-col>
                        <b-col cols="7" md="7" lg="12">
                            <b-card-body :title="item.title" class="p-3 pb-0 pb-lg-3"
                                         title-tag="h4">
                                <b-card-text v-html="cropText(item.content)" class="content d-none d-md-block">
                                </b-card-text>

                                <router-link :to="{name: 'news', params: { id: item.id}}" class="href_small">Подробнее</router-link>
                            </b-card-body>
                        </b-col>

                    </b-row>
                </b-card>
                </b-card-group>
            <!--</b-col>-->
            </b-col>
        </b-row>
    </b-container>
</template>

<script>

    export default {
        name: "News",
        watch: {
            day: function (newVal) {
                this.$store.commit('GET_NEWS_BY_DATE', newVal);
                this.$store.commit('SET_NEWS_DAY', newVal);
                this.$router.push('/news')
            }
        },
        data() {
            return {
                day: '',
                img_path: process.env.VUE_APP_IMG
            }
        },
        computed: {
            info: function () {
                return this.$store.getters.allNews
            },
            news: function(){
                return this.info.slice(0,6);
            }
        },
        methods: {
            cropText: function(text){
                let cuttingText = text.substring(0, 160);
                let editedText = cuttingText.substr(0, Math.min(cuttingText.length, cuttingText.lastIndexOf(" ")));
                if (editedText.includes("<hr>"))
                    return editedText.split("<hr>")[0];
                else
                    return editedText+'...';

            },
            toNews(){
                this.$store.commit('GET_ALL_NEWS');
                this.$store.commit('SET_NEWS_DAY', '');
                this.$router.push('/news')
            },
            showPopover: function(id){
                this.$root.$emit('bv::show::popover', id)
            },
            hidePopover: function(id){
                this.$root.$emit('bv::hide::popover', id)
            }
        }
    }
</script>

<style scoped>

    .card-text {
        font-weight: 300;
        line-height: 24px;
        min-height: 160px;
        color: var(--text-secondary-color);
    }

   .item img {
        width: 120px;
    }

   .content {
       font-family: SF, sans-serif;
       font-size: 16px;
       line-height: 24px;
   }

   .news_item {
       margin-right: 16px !important;
       margin-bottom: 32px !important;
       box-shadow: 5px 5px 15px var(--shadow);
       border-radius: 8px;
       min-width: 352px;
       max-width: 352px;
       background-color: var(--background-color-secondary);
   }

    h4 {
        min-height: 112px;
        color: var(--text-primary-color);
    }

    .news_item_image {
        border-radius: 8px 8px 0px 0px;
    }

    @media (max-width: 1200px) {

        .news_item_image {
            border-radius: 8px 0px 0px 8px;
        }

        .news_item {
            margin-right: 2px !important;
            min-width: 262px;
            max-width: 362px;
        }

        h4 {
            min-height: 132px;
        }

        .card-text {
            min-height: 162px !important;
        }

        .href_small {
            position: absolute;
            bottom: 16px;
        }
    }

    @media all and (max-width: 919px) {
        .news_item {
            min-width: 440px;
            max-width: 840px;
        }

        h4 {
            font-size: 18px !important;
            line-height: 22px !important;
            min-height: 48px;
        }
        .content {
            font-size: 14px !important;
            line-height: 21px !important;
        }
        .card-text {
            min-height:90px !important;
        }

    }

    @media all and (max-width: 639px) {
        .news_item {
            min-width: 360px;
        }
        .content {
            font-size: 14px !important;
            line-height: 21px !important;
        }

        h4 {
            font-size: 16px !important;
            line-height: 20px !important;
        }

        .title {
            font-size: 16px;
        }

        .href {
            font-size: 12px;
        }
    }

    ::v-deep .card-body {
        padding: 0px;
    }

    .title_calendar {
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 20px;
        font-weight: 400;
        cursor: pointer;
        color: #FFF;
        text-shadow: 0px 0px 10px #636363
    }

    .title_calendar:hover {
        color: #f1ea3a;
    }

    .popover {
        margin-left: -20px;
        max-width: 306px !important;
        width: 306px !important;
        border-radius: 16px;
    }

    .popover .arrow {
        display: block;
    }

    ::v-deep .form-control {
        border: 0px;
    }

    ::v-deep .b-calendar-header {
        display: none;
    }



</style>