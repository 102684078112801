<template>
    <b-container fluid="xl" class="pl-5 pr-5 pl-xl-3 pr-xl-3" v-if="course">
        <b-modal id="BigImage" title="BigImage"  hide-footer hide-header size="xl">
            <b-row>
                <b-col v-if="course.gallery">
                    <b-carousel
                            id="images"
                            v-model="imageIndex"
                            :interval="0"
                            controls
                            indicators
                            background="#ababab"
                            img-width="1024"
                            img-height="480"
                            label-next=""
                            label-prev=""
                    >
                        <b-carousel-slide
                                v-for="(image, index) in course.gallery"
                                :key="'gal'+index"
                                :img-src="img_path+image.photo"
                        ></b-carousel-slide>
                    </b-carousel>
                </b-col>
            </b-row>
        </b-modal>

        <b-row>
            <b-col class="title mt-3 ml-0 mb-5 p-0">
                <span class="main_title"> Галерея &nbsp; </span> &nbsp; <!--<a href="" class="href">Перейти в галерею</a>-->

            </b-col>
        </b-row>
        <b-row class="mb-4" v-if="course">
            <b-col cols="12" sm="6" md="4" xl="3" v-for="(image, index) in course.gallery" :key="'img'+index" class="p-1">
                <img :src="img_path+image.photo" class="image" @click="showImage(index)" />
            </b-col>
        </b-row>
        <b-row>

        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Works",
        data() {
            return {
                img_path: process.env.VUE_APP_IMG
            }
        },
        computed: {
            course: function () {
                return this.$store.getters.currentCourse;
            }
        },
        methods: {
            showImage: function (i) {
                this.imageIndex = i;
                this.$bvModal.show('BigImage');
            }
        }
    }
</script>

<style scoped>
    .main_title {
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-size: 32px;
        line-height: 46px;
        color: #ccc;
    }


    .href {
        font-family: SF SEMIBOLD, sans-serif;
        font-style: normal;
        color: #F2913A;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        text-decoration: none;
    }


    .href:hover, .href_small:hover {
        color: #af483a;
        text-decoration: underline;
    }

    .image {
        width: 100%;
        cursor: pointer;
    }
</style>