<template>
    <b-container fluid class="p-0">
        <b-container fluid="xl"  class="p-0 pl-3 pl-xl-0 pr-md-0">
            <b-row>
                <b-col class="title mt-3 ml-3 ml-lg-0 mb-5">
                    НАШИ ИНТЕРНЕТ-РЕСУРСЫ
                </b-col>
            </b-row>
            <b-row v-if="info" class="m-0">
                <b-col class="mb-4 ml-4 ml-lg-0">
                    <carousel
                            :perPageCustom="[[200, 1], [640, 2], [1024, 3], [1224, 4]]"
                            mouseDrag
                            autoplay
                            loop
                            :speed="2000"
                            :autoplay-timeout="5000"
                    >
                        <slide v-for="(item, index) in info_in" :key="'p'+index">
                            <b-row style="width: 320px">
                                <b-col cols="4" md="4" class="item" v-if="item.image">
                                    <img :src="item.image.path">
                                </b-col>
                                <b-col cols="8" md="8">
                                    <div class="item_title">{{item.small_path}}</div>
                                    <div class="item_name">{{item.name}}</div>
                                    <div><a :href="item.path" class="item_href" target="_blank">Перейти</a></div>
                                </b-col>
                            </b-row>
                        </slide>
                    </carousel>
                </b-col>
            </b-row>
            </b-container>
            <b-container fluid class="pl-0 pr-0 pt-1 pb-3 wrapper">
                <b-container fluid="xl">
                    <b-row v-if="(info)" class="m-0 mt-4">
                        <b-col class="mb-4 ml-4 ml-lg-0">
                            <carousel
                                    :perPageCustom="[[200, 1], [400, 2], [640, 3], [1024, 4], [1224, 5]]"
                                    mouseDrag
                                    autoplay
                                    loop
                                    :speed="2000"
                                    :autoplay-timeout="5000"
                            >
                                <slide v-for="(item, index) in info_out" :key="'p'+index">
                                    <b-row>
                                        <b-col cols="12" class="item_out text-center" v-if="item.image">
                                            <a :href="item.path" class="item_href" target="_blank" v-if="item.path">
                                                <img :src="(getTheme=='light-theme')?item.image.path:(item.image_dark)?item.image_dark.path:item.image.path">
                                            </a>
                                            <a class="item_href" v-else>
                                                <img :src="(getTheme=='light-theme')?item.image.path:(item.image_dark)?item.image_dark.path:item.image.path">
                                            </a>
                                        </b-col>
                                    </b-row>
                                </slide>
                            </carousel>
                        </b-col>
                    </b-row>
                </b-container>
            </b-container>
            <b-container fluid="xl"  class="p-0 pl-3 pl-xl-0 pr-md-0">
            <b-row class="justify-content-center pt-5 mt-2">
                <b-col cols="12" md="6" class="text-center">
                    <iframe id="widgetPosId" src="https://pos.gosuslugi.ru/og/widgets/view?type=[10,20,30,40]&amp;fontFamily=Arial&amp;maxPage=3&amp;maxElement=5&amp;updateFrequency=5000&amp;level=20&amp;region_id=4&amp;itemColor=354052&amp;logoColor=ffffff&amp;selectColor=2c8ecc&amp;typeBgColor=F2F8FC&amp;deviderColor=e3e8ee&amp;hoverItemColor=2c8ecc&amp;startTextColor=666666&amp;backgroundColor=ffffff&amp;paginationColor=000000&amp;startBtnBgColor=0063B0&amp;startTitleColor=000000&amp;fillSvgHeadColor=ffffff&amp;hoverSelectColor=116ca6&amp;phoneHeaderColor=0B40B3&amp;startTextBtnColor=FFFFFF&amp;widgetBorderColor=e3e8ee&amp;backgroundItemColor=f9f9fa&amp;hoverPaginationColor=2862AC&amp;widgetBorderOldPageColor=e3e8ee&amp;backgroundPaginationColor=2862AC&amp;itemFs=15&amp;logoFs=16&amp;selectFs=25&amp;startTextFs=18&amp;paginationFs=14&amp;startTitleFs=38&amp;startTextBtnFs=16&amp;widgetBorderFs=1&amp;orgActivity=" width="320" height="552" style="border:0"></iframe>
                </b-col>
                <b-col cols="12" md="6" lg="4" class="text-center mt-4 mt-md-0">
                    <iframe style="border: 1px solid #eee" height="552" width="320" src="./gu.html"></iframe>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>
    import { Carousel, Slide } from 'vue-carousel';

    export default {
        name: "Websites",
        components: {
            'carousel': Carousel,
            'slide': Slide
        },
        computed: {
            info: function () {
                return this.$store.getters.allWebsites
            },
            info_in: function () {
                return this.info.filter(item => item.type=='in');
            },
            info_out: function () {
                return this.info.filter(item => item.type=='out');
            },
            getTheme() {
                return this.$store.getters.userTheme
            }
        },
    }
</script>

<style scoped>
    .wrapper {        
        background-color: var(--background-color-secondary) !important;
    }
    
    .item img {
        width: 70px;
    }

    .item_out img {
        height: 160px;

    }

    .item_title {
        font-family: SF, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        height: 24px;
        color: var(--text-secondary-color);
    }

    .item_name {
        font-family: SF LIGHT, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
        color: var(--text-secondary-color);
        height: 60px;
    }

    .item_href {
        font-family: SF, sans-serif;
        font-style: normal;
        color: #F2913A;
    }
    .item_href:hover {
        color: #9AAF22;
    }



    @media all and (max-width: 640px) {
        ::v-deep .VueCarousel {
            width: 80%;
        }

        .item img {
            width: 80px;
        }

        .item_out img {
            height: 120px;
        }
    }


</style>