<template>
    <b-container fluid class="pl-0 pr-0 pb-3" v-if="info">
        <b-row class="m-0">
            <b-col :style="(info.back_color)?'background-color: '+info.back_color:''" class="title_block">
                <b-container fluid="xl">
                    <b-row class="pl-0 pl-md-0">
                        <b-col class="d-none d-xl-block"></b-col>
                        <div class="title_header_block ml-0 ml-md-0 ml-lg-4 ml-xl-0">
                            <div class="title" :style="'color: '+info.name_color">{{info.name}}</div>
                            <div class="slogan" :style="'color: '+info.name_color">{{info.slogan}}</div>
                        </div>
                        <b-col class="d-none d-xl-block"></b-col>
                    </b-row>
                </b-container>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Title",
        computed: {
            info: function () {
                return this.$store.getters.currentCourse;
            }
        }
    }
</script>

<style scoped>
    .title_block {
        height: 210px;
    }

    .title_header_block {
        width: 1200px !important;
    }

    .title {
        max-width: 1020px;
        margin-top: 32px;
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-size: 46px;
        line-height: 46px;
        color: #FFF;
    }

    .slogan {
        margin-top: 8px;
        max-width: 640px;
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-size: 18px;
        line-height: 24px;
        color: #FFF;
    }

    @media (max-width: 800px) {
        .title {
            font-size:40px ;
            line-height: 42px;
        }

        .slogan {
            font-size: 16px;
            line-height: 20px;
        }
    }

    @media (max-width: 620px) {
        .title {
            margin-top: 16px;
        }

        .slogan {
            font-size: 16px;
            line-height: 20px;
        }
    }

</style>