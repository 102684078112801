<template>
    <b-container class="p-0 mt-5">
        <b-row v-if="info" class="mb-4">
            <b-col cols="12" lg="4">
                <b-row>
                    <b-col>
                        <h3>{{info.name}}</h3>
                    </b-col>
                </b-row>
                <b-row class="mt-3" v-if="info.description">
                    <b-col class="content" v-html="description[0]">                
                    </b-col>
                </b-row>
            </b-col>
            <b-col class="text-center" v-if="info.pics">
                <img class="logo" :src="img_path+logo" />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" sm="6" lg="3" class="mb-4">
                <div class="step_num">1</div>
                <div class="step_text">Организация и проведение краевых мероприятий и социальных акций по безопасности дорожного движения</div>
            </b-col>
            <b-col cols="12" sm="6" lg="3" class="mb-4">
                <div class="step_num">2</div>
                <div class="step_text">Координация деятельности отрядов юных инспекторов движения Красноярского края;</div>
            </b-col>
            <b-col cols="12" sm="6" lg="3" class="mb-4">
                <div class="step_num">3</div>
                <div class="step_text">Разработка и реализация дополнительных общеразвивающих программ, направленных на формирование навыков безопасного поведения на дорогах;</div>
            </b-col>
            <b-col cols="12" sm="6" lg="3">
                <div class="step_num">4</div>
                <div class="step_text">Осуществление методической помощи педагогическим работникам, организующим деятельность по профилактике детского дорожно-транспортного травматизма.</div>
            </b-col>
        </b-row>
        <b-row class="mt-5">
            <b-col cols="12" sm="6" lg="4" class="mb-4">
                <div class="banner orange">
                    Лаборатория<br>безопасности
                </div>
            </b-col>
            <b-col cols="12" sm="6" lg="4" class="mb-4">
                <div class="banner green">
                    Краевые<br>мероприятия
                </div>
            </b-col>
            <b-col cols="12" sm="6" lg="4">
                <div class="banner blue">
                    Движение<br>ЮИД
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "ProjectBddDescription",
        data() {
            return {
                img_path: process.env.VUE_APP_IMG
            }
        },        
        computed: {
            info() {
                return this.$store.getters.getProject
            },
            logo() {
                return this.info.pics.find(item => item.label == "logo").images
            },
            description: function(){        
                return this.info.description.split("<hr>")
            }
        },
    }
</script>

<style lang="scss" scoped>
    h3 {
        font-family: 'SF';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        color: var(--text-primary-color);
    }

    .logo {
        width: 100%;
        max-width: 500px;
    }

    .content, .step_text {
        font-family: 'SF Light';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* or 133% */


        color: var(--text-primary-color);
    }

    .step_text {
        float: right;
        width: 80%;
    }

    .step_num {
        float: left; 
        width: 20%;
        font-family: 'SF BOLD';
        font-style: normal;
        font-weight: 700;
        font-size: 53.1502px;
        line-height: 63px;

        color: #ED6D00;
    }

    .banner {
        border-radius: 8px;
        height: 80px;
        padding: 16px 24px;
        font-family: 'SF';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        color: #FFFFFF;
        background: #ED6D00;
    }

    .orange {
        background-color: #ED6D00;
    }

    .green {
        background-color: #99AD0B;
    }

    .blue {
        background-color: #009EE3;
    }
</style>