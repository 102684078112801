<template>
    <b-container fluid class="pl-3 pb-0">
        <b-row class="mb-1">
            <b-col cols="12" class="title">
                Контакты
            </b-col>
        </b-row>
        <b-row v-for="(depart, i) in info.depart" :key="'depart'+i" class="mt-3">
            <b-col class="info" cols="8" md="12">
                {{depart.name}}<br>
                e-mail: <b>{{depart.email}}</b><br>
                тел. <b>{{depart.phone}}</b>
            </b-col>
            <b-col cols="4" class="social d-block d-md-none mb-5 mt-3 text-right">
                <a href="" target="_blank">
                    <Icon icon="akar-icons:vk-fill" class="mr-1" color="#008DD2" height="16" /> dvoriets
                </a>
            </b-col>           
            <b-col class="info mt-3" cols="12" v-for="(person, j) in depart.team" :key="'person'+j">
                <div class="contact_fio">{{person.fio}}</div>
                <div class="contact_job">{{person.job}}</div>
            </b-col>           
        </b-row>
        <b-row>
            <b-col class="info mt-3" cols="12" v-for="(person, c) in info.contact" :key="'person'+c">
                <div class="contact_fio">{{person.fio}}</div>
                <div class="contact_job">{{person.job}},<br /> тел. {{person.phone}}<span v-show='person.email'>, почта: {{person.email}}</span></div>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="social mb-5 mt-3" v-if="site.vk">
                <a :href="site.vk" target="_blank" class="d-none d-md-block">
                    <Icon icon="akar-icons:vk-fill" class="mr-1" color="#008DD2" height="16" /> dvoriets
                </a>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import { Icon } from '@iconify/vue2';

    export default {
        name: "Contacts",
        components: {
          Icon
        },
        computed: {
            info: function(){
                return this.$store.getters.currentEvent;
            },
            site: function () {
                return this.$store.getters.allInfo
            }
        }
    }
</script>

<style scoped>
    .title {
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-size: 22px;
        line-height: 26px;
        color: var(--text-primary-color);
    }

    .info {
        font-family: SF, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        color: var(--text-primary-color);
    }

    .contact_fio {
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 18px;
        line-height: 28px;
        color: var(--text-primary-color);
    }

    .contact_job {
        font-family: SF LIGHT, sans-serif;
        font-style: normal;
        font-size: 13px;
        line-height: 20px;
        color: var(--text-primary-color);
    }
    .social {
        font-family: SF LIGHT, sans-serif;
        font-style: normal;
        font-size: 13px;
        line-height: 16px;
        color: var(--text-primary-color);
    }

    @media all and (max-width: 420px) {
        .info {
            font-size: 12px;
            line-height: 16px;
        }
    }
</style>