<template>
    <b-container fluid v-if="orgBySlug[0]">
            <b-row v-for="(item, index) in orgBySlug[0].org_section" :key="'item'+index" class="mb-4">
                <b-col cols="12" :lg="(orgBySlug[0].column==0)?'5':'12'" class="title pl-0 pl-xl-3 mb-4 mt-4 mt-lg-0">
                    {{item.name}}
                </b-col>
                <b-col class="content pl-0 pl-lg-3" v-html="item.content">

                </b-col>
            </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Content",
        props: ['slug'],
        computed: {
            orgBySlug: function () {
                return this.$store.getters.allOrg.filter((item) => {
                    return (item.slug==this.slug)
                });
            }
        }
    }
</script>

<style scoped>

    .title {
        color: var(--text-secondary-color);
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 18px;
    }

    .content {
        color: var(--text-secondary-color);
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
    }

    /deep/ .content a {
        color: #3A90B9 !important;
    }
</style>