<template>
    <b-container fluid>
        <b-row>
            <b-col class="head_block p-0">
                <div :style="'background: url('+image+') no-repeat'" class="title_block">
                    <b-container class="pl-4 pl-xl-3">
                        <b-row>
                            <div class="title nl2br" v-if="!idGallery">Галерея</div>
                            <div class="title nl2br" v-if="idGallery">{{gallery[0].name}}</div>
                        </b-row>
                    </b-container>
                </div>
            </b-col>
        </b-row>
    </b-container>

</template>

<script>
    export default {
        name: "Title",
        data() {
            return {
                image: require('@/assets/img/gallery/title_back.png'),
                day: ''
            }
        },
        computed: {
            idGallery: function () {
                return this.$route.params.id;
            },
            gallery: function(){
                return this.$store.getters.allGallery.filter((item)=>{
                    return item.id == this.$route.params.id
                })
            }
        }
    }
</script>

<style scoped>
    .head_block {
        background: linear-gradient(to right, #EF7F1A 50%, #EF8A33 50%);
        width: 100% !important;
    }

    .title_block {
        margin: auto;
        min-height: 260px;
        width: 60% !important;
        max-width: 100% !important;
        background-size: 1600px !important;
        background-position: center !important;
    }

    .title {
        margin-top: 104px;
        width: 1200px;
        max-width: 1200px;
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 52px;
        line-height: 46px;
        color: #FFF;
    }


    @media all and (max-width: 920px) {
        .title_block {
            min-height: 200px;
            background-size: 1220px !important;
            background-position-x: 50% !important;
        }

        .title {
            margin-top: 80px;
            font-size: 40px;
            line-height: 34px;
        }
    }

    @media all and (max-width: 640px) {
        .title_block {
            min-height: 184px;
            background-size: 1120px !important;
            background-position-x: 45% !important;
        }

        .title {
            font-size: 36px;
            line-height: 32px;
        }
    }

    @media all and (max-width: 460px) {
        .title_block {
            min-height: 130px;
            background-size: 800px !important;
            background-position-x: 56% !important;
        }

        .title {
            margin-top: 48px;
            font-size: 24px;
            line-height: 22px;
        }
    }
</style>