<template>
    <b-container fluid>
        <b-row>
            <b-col class="mb-1 p-0">
                <kassa-title></kassa-title>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-1 mt-4 p-0 ml-2 mr-2 ml-xl-0">
                <kassa-form></kassa-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-1 mt-4 p-0 ml-2 mr-2 ml-xl-0">
                <kassa-info></kassa-info>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-1 mt-4 p-0 ml-2 mr-2 ml-xl-0">
                <kassa-shedule></kassa-shedule>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import Title from "./Kassa/Title";
    import Form from "./Kassa/Form";
    import Info from "./Kassa/Info";
    import Shedule from "./Kassa/Shedule";
    export default {
        name: "Kassa",
        components: {
            'kassa-title': Title,
            'kassa-form': Form,
            'kassa-info': Info,
            'kassa-shedule': Shedule
        },
        mounted() {
            this.$store.dispatch('getPagesInfo', 'kassa');
            this.$store.dispatch('getKassa', 'kassa');
        }
    }
</script>

<style scoped>

</style>