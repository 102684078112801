import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex);

const link = (process.env.NODE_ENV=="development")?"http://localhost/dvpion/api":"https://www.dvpion.ru/api";

// eslint-disable-next-line no-unused-vars
export const lmsStore = {
    state: {
        departList: [],
        currentDepart: null,
        courseTypes: [],
        filterAge: [],
        currentFilterAge: null,
        currentFilterType: null,
        currentCourse: null,
        currentShedule: []
    },
    getters: {
        allDeparts: state=> state.departList,
        currentDepart: state => state.currentDepart,
        allCourseTypes: state => state.courseTypes,
        allFilterAge: state => state.filterAge,
        filterAge: state => state.currentFilterAge,
        filterType: state => state.currentFilterType,
        currentCourse: state => state.currentCourse,
        currentShedule: state => state.currentShedule,
    },
    mutations: {
        GET_DEPART_LIST: (state, list) => {
            state.departList = list
        },
        SET_DEPART: (state, depart) => {
            state.currentDepart = depart
        },
        GET_COURSE_TYPES: (state, types) => {
            state.courseTypes = types
        },
        GET_COURSE: (state, info) => {
            state.currentCourse = info
        },
        GET_SHEDULE_BY_TEACHER: (state, info) => {
            state.currentShedule = info
        },
        GET_SHEDULE_BY_COURSE: (state, info) => {
            state.currentShedule = info
        },
        GET_FILTER_AGE: (state, list) => {
            state.filterAge = list
        },
        SET_FILTER_AGE: (state, age) => {
            state.currentFilterAge = age
        },
        SET_FILTER_TYPE: (state, type) => {
            state.currentFilterType = type
        }
    },
    actions:{
        getDepartList({ commit, state }) {
            if (!state.departList.length) {
                return axios.post(link+'/depart/list/get').then(response => {
                    commit('GET_DEPART_LIST', response.data)
                })
            }
        },
        async getDepartBySlug({ dispatch, commit, state }, slug) {
            if (!state.departList.length) await dispatch('getDepartList')
            const depart = state.departList.find(depart => depart.slug === slug)
            commit('SET_DEPART', depart)
        },
        async getDepartById({ dispatch, commit, state }, id) {
            if (!state.departList.length) await dispatch('getDepartList')
            const depart = state.departList.find(depart => depart.id === id)
            commit('SET_DEPART', depart)
        },
        getCourseTypeList({ commit }) {
            return axios.post(link+'/courses/types/get').then(response => {
                commit('GET_COURSE_TYPES', response.data)
            })
        },
        getCourseFilterAge({ commit }) {
            return axios.post(link+'/courses/filter/age').then(response => {
                commit('GET_FILTER_AGE', response.data)
            })
        },
        getCourse({ commit }, slug) {
                return axios.post(link + '/courses/get/' + slug).then(response => {
                    commit('GET_COURSE', response.data)
                })
        },
        getGroupSheduleByTeacher({ commit }, id) {
            return axios.post(link + '/group/shedule/teachers/' + id).then(response => {
                commit('GET_SHEDULE_BY_TEACHER', response.data)
            })
        },
        getSheduleByCourse({ commit }, id) {
            return axios.post(link + '/courses/shedule/' + id).then(response => {
                commit('GET_SHEDULE_BY_COURSE', response.data)
            })
        }
    }
}