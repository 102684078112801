<template>
    <b-container fluid="xl">
        <b-row class="mt-5">
            <b-container class="pl-4 pl-xl-3">
                <b-col cols="12" class="p-0">
                    <b-row>
                        <b-col cols="12" lg="5" class="description pl-0" v-html="info.description"></b-col>
                        <b-col cols="12" lg="7" class="mt-4 mt-lg-0 p-0">
                            <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A56a2cdff9d0c5dd27848259c35a3b5d536060e360a43a23731694029c8b20342&amp;source=constructor" width="100%" height="340" frameborder="0"></iframe>
                        </b-col>
                    </b-row>
                </b-col>
            </b-container>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Map",
        computed: {
            info: function () {
                return this.$store.getters.allPages;
            }
        }
    }
</script>

<style scoped>
    /deep/ h4 {
        font-family: SF MEDIUM, sans-serif !important;
        font-style: normal;
        font-size: 14px !important;
        line-height: 18px !important;
        color: var(--text-primary-color);
    }
    .description {
        font-family: SF, sans-serif !important;
        font-style: normal;
        font-size: 13px !important;
        line-height: 16px !important;
        color: var(--text-secondary-color);
    }

</style>