<template>
    <b-container fluid class="pl-3 pb-5">
        <b-row class="mb-4">
            <b-col cols="12" class="title">
                Этапы проведения
            </b-col>
        </b-row>
        <b-row v-for="(stage, i) in info.timeline" :key="'date'+i" class=" mt-2 mb-3">
            <div class="dates">
                {{date(stage.date_in)}} - {{date(stage.date_out)}}
            </div>
            <b-col class="description">
                {{stage.name}}
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col cols="6" sm="5" lg="6" v-if="info.registration_link">
                <div class="reg_button"><a :href="info.registration_link" class="href" target="_blank">Регистрация</a></div>
            </b-col>
            <b-col cols="6" sm="7" lg="6" class="href pl-0">
                <event-documents />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import moment from 'moment';
    import Documents from './Documents'
    export default {
        name: "Timeline",
        components: {
            'event-documents': Documents
        },
        computed: {
            info: function(){
                return this.$store.getters.currentEvent;
            }
        },
        methods: {
            date: function (date) {
                return moment(date).locale('ru').format('DD.MM');
            }
        }
    }
</script>

<style scoped>
    .title {
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-size: 22px;
        line-height: 26px;
        color: var(--text-primary-color);
    }

    .dates {
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-size: 20px;
        line-height: 26px;
        color: #F2913A;
        width: 150px;
        margin-left: 20px;
    }

    .description {
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 20px;
        color: var(--text-primary-color);
    }

    .icon-list {
        font-size: 24px;
        color: #F2913A;
    }

    .icon-list:hover {
        color: #af483a;
    }

    .href {
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        color: #F2913A;
        text-decoration: none;
        cursor: pointer;
    }
    .href:hover {
        color: #af483a;
    }

    .href:hover .icon-list {
        color: #af483a;
    }

    .reg_button {
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 16px;
        width: 160px;
        color: #F2913A;
        padding: 4px;
        border-radius: 6px;
        text-align: center;
        border: 2px solid #F2913A;
        cursor: pointer;
    }

    .reg_button:hover {
        color: #af483a;
        border: 2px solid #af483a;
    }


    @media all and (max-width: 640px) {

    }

    @media all and (max-width: 420px) {
        .dates {
            font-size: 16px;
            line-height: 24px;
            width: 102px;
            margin-left: 20px;
        }

        .description {
            font-size: 12px;
            line-height: 16px;
            color: var(--text-primary-color);
        }

        .reg_button {
            width: 120px;
        }
        .href {
            font-size: 14px;
            line-height: 18px;
        }
    }
</style>