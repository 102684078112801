<template>
    <b-container fluid>
        <b-row>
            <b-col class="mb-1 p-0">
                <anticheat-title />
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-1 p-0">
                <anticheat-description />
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-0 p-0">
                <anticheat-docs />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import Title from "./Anticheat/Title";
    import Description from "./Anticheat/Description";
    import Docs from "./Anticheat/Gallery";

    export default {
        name: "Anticheat",
        components: {
            'anticheat-title': Title,
            'anticheat-description': Description,
            'anticheat-docs': Docs
        },
        mounted() {
            this.$store.dispatch('getPagesInfo', 'anticheat');
        }
    }
</script>

<style scoped>

</style>