<template>
    <b-container fluid="xl" class="pl-3 pr-3 pl-lg-3 pr-lg-5 pl-xl-3 pr-xl-3">
        <b-row align-v="top">
            <div class="photo">
                <b-row>
                    <b-col class="pl-2" v-if="info.director_photo">
                        <img :src="info.director_photo.path" class="photo">
                    </b-col>
                </b-row>
            </div>
            <b-col cols="12" md="7" lg="8" xl="9" class="d-none d-lg-flex">
                <b-row v-if="info.director_talk">
                    <b-col class="content pl-4" cols="12" lg="6">
                        <b-row no-gutters>
                            <b-col v-html="description[0]"></b-col>
                        </b-row>
                        <b-row>
                            <b-col v-html="description[1]"></b-col>
                        </b-row>
                    </b-col>
                    <b-col class="pl-4" cols="12" lg="6">
                        <b-row no-gutters>
                            <b-col class="content" v-html="description[2]"></b-col>
                        </b-row>
                        <b-row no-gutters>
                            <b-col class="content" v-html="description[3]"></b-col>
                        </b-row>
                        <b-row no-gutters>
                            <b-col class="content" v-html="description[4]"></b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" sm="7" md="8" lg="8" xl="9" class="mt-3 mt-sm-0 d-flex d-lg-none">
                <b-row v-if="info.director_talk">
                    <b-col class="content pl-0 pl-sm-4" cols="12" lg="6">
                        <b-row no-gutters>
                            <b-col class="pl-0 content" v-html="description[0]"></b-col>
                        </b-row>
                        <b-row no-gutters>
                            <b-col class="content" v-html="description[3]"></b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row class="d-flex d-lg-none mt-3 pl-0" >
            <b-col class="content pl-0" cols="12">
                <b-row no-gutters>
                    <b-col class="content" v-html="description[1]"></b-col>
                </b-row>
                <b-row no-gutters>
                    <b-col class="content" v-html="description[2]"></b-col>
                </b-row>
                <b-row>
                    <b-col class="content" v-html="description[4]"></b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Director",
        computed: {
            info() {
                return this.$store.getters.allInfo
            },
            description: function () {
                return this.info.director_talk.split("<hr>");
            }
        }
    }
</script>

<style scoped>
    .photo {
        width: 266px;
    }

    .content{
        font-family: SF LIGHT, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        color: var(--text-secondary-color);;
    }

    /deep/ .content > h2 {
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 26px;
        color: #FF6E00;
    }


    @media all and (max-width: 920px) {
        .photo  {
            width: 160px !important;
        }
    }


    @media all and (max-width: 460px) {
        .photo  {
            width: 275px !important;
        }
    }
</style>