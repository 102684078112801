<template>
    <b-container fluid="xl">
        <b-row class="mt-5">
                <b-container class="pl-4 pl-xl-3">
                    <b-col cols="12" lg="8" class="p-0">
                        <b-row>
                            <b-col class="description pl-0" v-html="info.description"></b-col>
                        </b-row>
                        <b-row class="mt-4" v-if="info.document">
                            <div class="pl-0"><a :href="info.document.path" target="_blank" class="href"><b-icon icon="file-earmark-text" class="icon-list"></b-icon></a></div>
                            <b-col class="description">
                                {{info.document.title}}
                                <div class="mt-2">
                                <a :href="info.document.path" target="_blank" class="href">Скачать одним документом</a>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-container>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Description",
        data() {
            return {
            }
        },
        computed: {
            info: function () {
                return this.$store.getters.eventPage;
            }
        }
    }
</script>

<style scoped>

    .description {
        font-family: SF, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 18px;
        color: var(--text-secondary-color);
    }

    .href {
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
        color: #F2913A;
        text-decoration: none;
    }
    .href:hover {
        color: #af483a;
    }

    .icon-list {
        font-size: 60px;
        color: #F2913A;
    }

</style>