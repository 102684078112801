<template>
    <b-container class="p-0 mt-5">
        <b-row v-if="info" class="mb-4">
            <b-col cols="12">
                <b-row>
                    <b-col>
                        <h3>{{info.name}}</h3>
                    </b-col>
                </b-row>
                <b-row class="mt-3" v-if="info.description">
                    <b-col cols="12" sm="6" lg="4" class="content" v-html="description[0]">                
                    </b-col>
                    <b-col cols="12" sm="6" lg="4" class="content" v-html="description[1]">
                    </b-col>
                    <b-col cols="4" class="content d-none d-lg-flex">
                        <div>
                            <div v-for="(person, i) in info.contacts" :key="'person'+i">
                                {{person.fio}}
                                <div class="content mt-2 mb-3">
                                    <Icon icon="carbon:phone-voice-filled" color="#ED6D00" height="16" :rotate="2" class="float-left mr-2 mt-1" 
                                                    :horizontalFlip="true" :verticalFlip="true" />
                                    {{person.phone}}
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row>                    
                    <b-col cols="12" sm="6" lg="4" class="content">
                        <div class="mb-3 mb-lg-0">Группа в VK:<br class="d-block d-lg-none mb-2"/> <a :href="info.adress" target="_blank">{{ info.adress }}</a></div>
                        <div class="mb-4 mb-lg-0">Официальный сайт проекта:<br class="d-block d-lg-none mb-2"/> <a :href="info.site" target="_blank">{{ info.site }}</a></div>
                        <b-row class="d-flex d-lg-none mt-3" v-if="info.docs">
                            <b-col cols="12" lg="6" class="content_small mt-1 pb-3" v-for="(doc, i) in info.docs" :key="'doc'+i">
                                <b-icon icon="file-earmark-text" class="icon-list mt-0 mr-2" />
                                <a :href='info.docs[0].document' target="_blank" class="content_small">{{info.docs[0].title}}</a>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" sm="6" class="content mt-3 d-flex d-lg-none">
                        <div>
                            <div v-for="(person, i) in info.contacts" :key="'person'+i">
                                {{person.fio}}
                                <div class="content mt-2 mb-3">
                                    <Icon icon="carbon:phone-voice-filled" color="#ED6D00" height="16" :rotate="2" class="float-left mr-2 mt-1" 
                                                    :horizontalFlip="true" :verticalFlip="true" />
                                    {{person.phone}}
                                </div>
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="12" lg="8" class="d-none d-lg-flex" v-if="info.docs">
                        <b-row class="w-100">
                            <b-col cols="12" lg="4" class="content_small mt-1 pb-3" v-for="(doc, i) in info.docs" :key="'doc'+i">
                                <b-icon icon="file-earmark-text" class="icon-list mt-0 mr-2" />
                                <a :href='img_path+doc.document' target="_blank" class="content_small">{{doc.title}}</a>
                            </b-col>                            
                        </b-row>
                    </b-col>  
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import {Icon} from '@iconify/vue2';
    export default {
        name: "ProjectBiletDescription",        
        components: {
            Icon
        },
        data() {
            return {
                img_path: process.env.VUE_APP_IMG
            }
        },        
        computed: {
            info() {
                return this.$store.getters.getProject
            },
            description: function(){        
                return this.info.description.split("<hr>")
            }
        },
    }
</script>

<style lang="scss" scoped>
    h3 {
        font-family: 'SF';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        color: var(--text-primary-color);
    }

    .content, .step_text {
        font-family: 'SF Light';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* or 133% */


        color: var(--text-primary-color);
    }

    .step_text {
        float: right;
        width: 80%;
    }

    .step_num {
        float: left; 
        width: 20%;
        font-family: 'SF BOLD';
        font-style: normal;
        font-weight: 700;
        font-size: 53.1502px;
        line-height: 63px;

        color: #ED6D00;
    }

    .banner {
        border-radius: 8px;
        height: 80px;
        padding: 16px 24px;
        font-family: 'SF';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        color: #FFFFFF;
        background: #ED6D00;
    }

    .orange {
        background-color: #ED6D00;
    }

    .green {
        background-color: #99AD0B;
    }

    .blue {
        background-color: #009EE3;
    }

    
    .content_small {
        font-family: 'SF Light';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: var(--text-primary-color);
    }

    .icon-list {
        font-size: 32px;
        color: #ED6D00;
        float: left;
    }

</style>