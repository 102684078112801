<template>
    <b-container fluid class="p-0 pb-3" v-if="depart">
        <b-row>
            <b-col style="overflow: hidden; width: 100%; margin-top: -100px; height: 450px; position: absolute" >
                <img class="robot d-none d-md-block" :src="depart.image_stat.path" v-if="depart.image_stat">
            </b-col>
        </b-row>
        <b-row>
            <b-col class="block pl-sm-4 pl-md-5 pl-lg-5 pl-xl-0 ">
                <b-container fluid="xl">
                    <b-row class="mt-4 ml-0 ml-md-0">
                        <b-col cols="12" md="10" lg="6" class="pl-0 ml-0 pr-5" offset="1">
                            <div class="stat_block">
                                <b-row class="pl-0 mt-4">
                                    <b-col class="pl-0" cols="6" v-for="(stat, index) in depart.depart_stat" :key="'stat'+index">
                                        <div class="stat_num">{{stat.number}}</div>
                                        <div class="stat_text">{{stat.description}}</div>
                                    </b-col>
                                </b-row>
                                <b-row class="d-block d-md-none">
                                    <b-col>
                                        <img class="robot_mini mt-3" :src="depart.image_stat.path" v-if="depart.image_stat">
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Stat",
        computed: {
            depart: function () {
                return this.$store.getters.currentDepart;
            }
        }
    }
</script>

<style scoped>
    .block {
        width: 100%;
        background-color: var(--background-color-secondary);
        min-height: 350px;
    }

    .stat_block {
        width: 420px;
    }

    .stat_num {
        margin: auto;
        max-width: 220px;
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 62px;
        color: #FECC00;
        text-align: center;
        margin-bottom: 4px;
    }

    .stat_text {
        margin: auto;
        margin-bottom: 32px;
        max-width: 220px;
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        color: var(--text-secondary-color);
        text-align: center;
    }

    .robot{
        position: absolute;
        max-width: 780px;
        max-height: 400px;
        margin-top: 50px;
        margin-left: 720px;
        z-index:99;
    }

    .robot_mini {
        max-width: 400px;
    }
    .robot_mini img {
        width: 100%;
    }

    @media (max-width: 1600px) {
        .robot {
            margin-left: 620px;
        }
    }

    @media (max-width: 1400px) {
        .robot {
            margin-left: 480px;
        }
    }

    @media (max-width: 1200px) {
        .robot {
            margin-left: 360px;
        }
    }

    @media (max-width: 1020px) {
        .robot {
            margin-left: 320px;
        }
    }

    @media (max-width: 500px) {
        .stat_block {
            width: 370px;
        }
    }

    @media (max-width: 400px) {
        .stat_block {
            width: 320px;
        }
    }

</style>