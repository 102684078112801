<template>
    <b-container fluid>
        <b-row>
            <b-col class="mb-1 p-0">
                <programms-title></programms-title>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-1 mt-0 p-0 ml-2 mr-2 ml-xl-0">
                <programms-description></programms-description>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-1 mt-3 p-0 ml-2 mr-2 ml-xl-0">
                <programms-docs></programms-docs>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import Title from "./Programms/Title";
    import Docs from "./Programms/Docs";
    import Description from "./Programms/Description";
    export default {
        name: "Programms",
        components: {
            'programms-title': Title,
            'programms-docs': Docs,
            'programms-description': Description
        },
        mounted() {
            this.$store.dispatch('getPagesInfo', 'programms');
        }
    }
</script>

<style scoped>

</style>