<template>
    <b-container class="p-0 mt-5">
        <b-row v-if="info" class="mb-4">
            <b-col cols="12" lg="4">
                <b-row>
                    <b-col>
                        <h3>{{info.name}}</h3>
                    </b-col>
                </b-row>
                <b-row class="mt-3" v-if="info.description">
                    <b-col class="content" v-html="description[0]">                
                    </b-col>
                </b-row>
            </b-col>
            <b-col class="text-center" v-if="info.pics">
                <img class="logo" :src="img_path+logo" />
            </b-col>
        </b-row><!--
        <b-row>
            <b-col cols="12" sm="6" lg="3" class="mb-4">
                <div class="step">
                    <div class="step_num">1</div>
                    <div class="step_text">Программы дополнительного образования</div>
                </div>
            </b-col>
            <b-col cols="12" sm="6" lg="3" class="mb-4">
                    <div class="step">
                    <div class="step_num">2</div>
                    <div class="step_text">Конференции и соревнования</div>
                </div>
            </b-col>
            <b-col cols="12" sm="6" lg="3" class="mb-4">
                    <div class="step">
                    <div class="step_num">3</div>
                    <div class="step_text">Научно-популярные фестивали</div>
                </div>
            </b-col>
            <b-col cols="12" sm="6" lg="3">
                    <div class="step">
                    <div class="step_num">4</div>
                    <div class="step_text">Дополнительные курсы для школьников и детей</div>
                </div>
            </b-col>
        </b-row>-->
        <b-row class="mt-5">
            <b-col cols="12" sm="6" lg="4" class="mb-4">
                <a href='https://vk.com/oitpkkdp' target='_blank'>
                <div class="banner multi">
                    Новости<br>МУЛЬТИЛАБА
                </div>
                </a>
            </b-col>
            <b-col cols="12" sm="6" lg="4" class="mb-4">
                <div class="banner multi" @click="show = true">
                    Программы
                </div>
            </b-col>
            <b-col cols="12" sm="6" lg="4">
                <a href='https://vk.link/oitpkkdp' target='_blank'>
                    <div class="banner multi">
                        Дополнительные<br>материалы
                    </div>
                </a>
            </b-col>
        </b-row>
        <b-modal v-model="show" hide-footer hide-header size="lg" content-class="testWindow">
            <b-row class="p-4"
                   :style="'background: url('+require('/src/assets/img/test/back.png')+'); border-radius: 16px'">
                <b-col class="quiz_back" v-if="courses">
                    <table width="100%">
                        <tr>
                            <th>
                                Наименование курса
                            </th>
                            <th>
                                Ссылка на курс в Навигаторе
                            </th>
                        </tr>
                        <tr v-for=" course, index in courses" :key="index">
                            <td> 
                                <router-link :to="{name: 'course', params: { slug: course.slug}}" class="href">
                                    {{ course.name }}
                                </router-link>
                            </td>
                            <td>
                                <a :href='"https://navigator.krao.ru/program/"+course.navigator_id'>
                                    https://navigator.krao.ru/program/{{ course.navigator_id }}
                                </a></td>
                        </tr>
                    </table>
                </b-col>
            </b-row>
        </b-modal>
    </b-container>
</template>

<script>
    export default {
        name: "ProjectBddDescription",
        data() {
            return {
                img_path: process.env.VUE_APP_IMG,
                show: false
            }
        },       
        computed: {
            depart: function() {
                return this.$store.getters.currentDepart;
            },
            courses: function() {
                if (this.depart)
                    return this.depart.courses.filter(item => item.name.includes('Мультилаб'));
                else return null
            },
            info() {
                return this.$store.getters.getProject
            },
            logo() {
                return this.info.pics.find(item => item.label == "photo").images
            },
            description: function(){        
                return this.info.description.split("<hr>")
            }
        },
    }
</script>

<style lang="scss" scoped>
    h3 {
        font-family: 'SF';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        color: var(--text-primary-color);
    }

    .logo {
        width: 100%;
        max-width: 500px;
        border-radius: 16px;
    }

    .content, .step_text {
        font-family: 'SF Light';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* or 133% */


        color: var(--text-primary-color);
    }
    .step {
        border: 2px solid #ff6e00;
        border-radius: 8px;
        padding: 8px 16px;
        min-height: 80px;
    }
    .step_text {
        font-family: 'SF MEDIUM';
        margin-top: 6px;
        width: 80%;
        line-height: 16px;

    }

    .step_num {
        float: left; 
        width: 20%;
        font-family: 'SF BOLD';
        font-style: normal;
        font-weight: 700;
        font-size: 53.1502px;
        line-height: 63px;

        color: #ED6D00;
    }

    .banner {
        border-radius: 8px;
        height: 80px;
        padding: 16px 24px;
        font-family: 'SF';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;
        color: #FFFFFF;
        background: #ED6D00;
        cursor: pointer;
    }

    .multi {
        background: linear-gradient(90deg, rgba(168,38,130,1) 0%, rgba(246,103,13,1) 80%);
    }

    .quiz_back {
        background-color: var(--background-color-primary);
    }

    table {
        border-collapse: collapse;
    }

    table th {
        padding: 8px 16px;
        border: 1px solid #eee;
        text-align: center;
    }

    table td {
        padding: 8px 16px;
        border: 1px solid #eee;
    }
</style>