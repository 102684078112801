<template>
    <div>
        <b-img :src="src" :alt="alt" />
    </div>
</template>

<script>
    export default {
        props: {
            src: {
                type: String,
                default: null
            },
            alt: {
                type: String,
                default: null
            }
        },
    }
</script>

<style scoped>
    div {
        overflow: hidden;
        max-height: 300px;
        max-width: 540px;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        min-width: 540px;
        min-height: 300px;
        max-width: 700px;
        max-height: 500px;
    }

    @media all and (max-width: 920px) {
        div {            
            max-height: 250px;
        }
    }

    @media all and (max-width: 640px) {
        div {            
            max-height: 200px;
        }
    }
</style>