<template>
    <b-container class="p-3 mt-3 pb-5 wrapper">
        <b-row>
            <b-col cols="12" lg="4" class="p-3 pl-4 pr-5">
                <b-row>
                    <b-col>
                        <h3>
                            {{info.name}} Красноярского краевого Дворца пионеров
                        </h3>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="content mt-3 mb-3">
                        <Icon icon="ci:location" color="#ED6D00" height="20" class="float-left mr-2 mt-1" :rotate="2" :verticalFlip="true" />
                        {{info.adress}}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="content mb-3">
                        <Icon icon="carbon:phone-voice-filled" color="#ED6D00" height="16" :rotate="2" class="float-left mr-2 mt-1" 
                                          :horizontalFlip="true" :verticalFlip="true" />
                        {{info.phone}}
                    </b-col>
                </b-row>
                
                <b-row>
                    <b-col class="content mb-3">
                        <Icon icon="entypo:email" color="#ED6D00" height="16" :rotate="2" class="float-left mr-2 mt-1" 
                                          :horizontalFlip="true" :verticalFlip="true" />
                        {{info.mail}}
                    </b-col>
                </b-row>
                
                <b-row>
                    <b-col class="content">
                        <Icon icon="cib:vk" color="#ED6D00" height="16" :rotate="2" class="float-left mr-2 mt-1" 
                                          :horizontalFlip="true" :verticalFlip="true" />
                        <a :href="'https://'+info.site" target="_blank">{{info.site}}</a>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" lg="8" class="p-3 pr-4">
                <b-row>
                    <b-col cols="12" md="6" class="content pl-4 pr-3 pl-lg-0 mb-4" v-for="(person, index) in info.contacts" :key="'person'+index">
                        <h3 class="mb-0">{{person.fio}}</h3>{{person.position}}
                    </b-col>                    
                </b-row>
            </b-col>
        </b-row>
        <b-row class="pl-1 pr-4 pb-4 ml-0 justify-content-center">
            <b-col cols="3" class="partner mt-5 text-center" v-for="(partner, i) in info.partners" :key="'partner'+i">
                <a :href="partner.link" target="_blank">
                    <img width="120" :src="img_path+partner.Logo" />
                    <!-- <div class="partner_title">{{ partner.name }}</div> -->
                </a>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import {Icon} from '@iconify/vue2';
    
    export default {
        name: 'ProjectsBDDContacts',
        components: {
            Icon
        },
        data() {
            return {
                img_path: process.env.VUE_APP_IMG
            }
        },        
        computed: {
            info() {
                return this.$store.getters.getProject
            }
        }
    }
</script>

<style scoped>
    .wrapper {
        background-color: var(--background-color-secondary);
    }

    h3 {
        font-family: 'SF Light';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;

        color: var(--text-primary-color);
    }

    .content {
        font-family: 'SF Light';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--text-primary-color);
    }

    .partner_logo {
        width: 100px;
    }

    .partner_title {
        width: 300px;
    }
    .partner_title a {        
        color: var(--text-primary-color) !important;
    }

</style>