<template>
    <b-container fluid="xl" class="p-0 pl-3 pl-sm-4 pr-4 pl-lg-5 pl-xl-0 pr-lg-4 pr-xl-3">
        <b-row>
            <b-col class="back ml-3 mr-3 pl-0 ml-lg-0 ml-xl-3 pl-lg-3">
                <div class="man"><img src="../../assets/img/course/woman.png"></div>
                <b-row class="mt-2">
                    <b-col class="title ml-5 mb-0 mb-xl-2 ml-md-5 mt-md-4 pl-0 pl-lg-3">
                        Хотите записать вашего ребёнка?
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="subtitle ml-5 mb-0 mb-xl-2 mt-0 ml-md-5 mb-md-3 mt-md-1 pl-0 pl-lg-3">
                        Оставьте заявку на сайте:
                    </b-col>
                </b-row>

                <b-row class="pl-4 pl-md-4 pt-1 pt-xl-2 pb-0 pb-xl-3 mb-2" v-if="info">
                    <div class="navi_logo">
                        <a :href="'https://navigator.krao.ru/program/'+info.navigator_id" target="_blank"><img src="../../assets/img/course/blazon.png" class="nav_img"></a>
                    </div>
                    <b-col cols="5" md="3" lg="4" xl="3" class="nav_title m-1 mb-0 m-md-0">
                        <div style="width: 220px">
                            <a :href="'https://navigator.krao.ru/program/'+info.navigator_id" target="_blank">Навигатор дополнительного образования Красноярского края</a>
                        </div>
                    </b-col>
                    <b-col cols="12" lg="12" xl="4" class="nav_text ml-4 mt-2 mb-0 m-xl-0 pl-0 pl-lg-3">
                        <div>
                            Региональный оператор дополнительного образования детей Красноярского края - общедоступный информационный портал, позволяющий семьям выбирать дополнительные общеобразовательные программы
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="4" lg="12" class="subtitle pl-0 pl-lg-3 ml-5 mb-2 mt-0 ml-md-5 mb-md-3 mt-xl-1" v-if="depart">
                        <div>
                            Телефон для справок: <br class="d-block d-lg-none"><span>{{depart.depart_team[0].phone}}</span><br>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="docs pl-0 pl-lg-3 ml-5 mr-0  mb-4 mt-0 ml-md-5 mt-xl-2 mb-md-4">
                        <b-icon icon="file-earmark-text" class="nav_icon"></b-icon>
                        Документы на зачисление
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: "Navigator",
        watch: {
          info:function() {
                  this.$store.dispatch('getDepartById', this.info.depart_id);
              }
        },
        computed:{
            info: function () {
                return this.$store.getters.currentCourse;
            },
            depart: function(){
                return this.$store.getters.currentDepart;
            }
        }
    }
</script>

<style scoped>

    .wrapper {
        max-width: 1300px;
    }

    .back {
        width: 100%;
        min-height: 400px;
        background-color: var(--background-color-secondary);
    }

    .title {
        font-family: SF BOLD, sans-serif;
        font-size: 30px;
        line-height: 56px;
        color: var(--text-primary-color);
    }

    .subtitle {
        font-family: SF LIGHT, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        line-height: 33px;
        color: var(--text-primary-color);
    }

    .subtitle div {
        width: 420px;
    }

    .subtitle span {
        font-family: SF MEDIUM, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        line-height: 33px;
        color: var(--text-primary-color);
    }

    .man {
        position: absolute;
        float: right;
        right: 32px;
        margin-top: 32px;
    }

    .man img {
        max-width: 330px;
    }

    .navi_logo {
        width: 120px;
    }

    .nav_icon {
        color: #EF7F1A;
        margin-right: 4px;
    }

    .docs {
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 32px;
        color: #EF7F1A;
    }

    .docs:hover {
        text-decoration: underline;
        cursor: pointer;
    }
    .nav_img {
        width: 90px;
        margin-left: 32px;
    }

    .nav_title {
        text-transform: uppercase;
        font-family: SF BOLD, sans-serif;
        font-size: 18px;
        line-height: 22px;
    }
    .nav_title a {
        color: var(--text-primary-color);
    }
    .nav_title a:hover {
        text-decoration: underline;
    }

    .nav_text {
        font-family: SF LIGHT, sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        color: var(--text-secondary-color);
        padding-right: 48px;
    }

    .nav_text div {
        width: 320px;
    }

    @media all and (max-width: 1200px) {
        .man {
            margin-top: 80px;
        }
    }

    @media all and (max-width: 920px) {
        .title {
            font-size: 24px;
        }
        .subtitle {
            font-size: 20px;
        }
        .docs {
            font-size: 16px;
        }
        .navi_logo {
            width: 90px;
        }

        .navi_logo img{
            width: 64px;
        }
        .man {
            width: 269px;
            margin-top: 132px;
            right: 16px;
        }

        .man img {
            max-width: 269px;
        }

        .nav_title {
            font-size: 15px;
            line-height: 16px;
        }
        .nav_title div {
            width: 220px;
        }
        .nav_text {
            font-size: 12px;
            line-height: 16px;
        }
        .nav_text div {
            width: 280px !important;
        }
        .navi_logo {
            margin-left: -16px;
        }
    }

    @media all and (max-width: 640px) {
        .title {
            margin-top: 16px;
            font-size: 20px;
            line-height: 22px;
        }

        .subtitle {
            font-size: 16px;
        }

        .docs {
            font-size: 14px;
        }

        .man {
            width: 196px;
            margin-top: 180px;
            right: 0px;
        }
        .man img {
            width: 196px;
        }
        .nav_text {
            font-size: 11px;
            line-height: 14px;
        }
        .nav_text div {
            width: 220px !important;
        }
    }

    @media all and (max-width: 480px) {
        .man {
            display: none;
        }

        .nav_title div {
            width: 180px !important;
        }

        .subtitle div {
            width: 280px;
        }
    }
</style>