<template>
    <b-container class="p-3 mb-2" v-if="info">
        <b-row>
            <b-col class="text-center" v-if="info.pics">
                <img :src="img_path+image_photo" class="image" />
            </b-col>
        </b-row>
        <b-row>
            <b-col class="content text-center mt-5 mb-4">
                <h3>Проект ЮниорПрофи представляет собой трехуровневую модель, включающую следующие направления</h3>
            </b-col>
        </b-row>
        <b-row class="wrapper" v-if="info.pics">
            <b-col cols="12" md="4" class="pl-5 pt-4">
                <b-row class="mb-4">
                    <div><img :src="master_image" class="float-left icon-big" alt="мастерство" /></div>
                    <b-col><span class="title ml-1 pt-1 pt-lg-3 pt-xl-4">Мастерство</span></b-col>
                </b-row>
                <b-row class="mt-3" v-for="(doc, i) in masterDocs" :key="'mdoc'+i">
                    <div style="width: 24px">                        
                        <b-icon icon="file-earmark-text" class="icon-list mt-0 mr-2" />
                    </div>
                    <b-col class="content_small mt-0">
                                <a :href='img_path+doc.document' target="_blank" class="content_small" v-html="doc.title"></a>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" md="4" class="pl-5 pt-4">
                <b-row class="mb-4">
                    <div><img :src="team_image" class="float-left icon-big" alt="команда" /></div>
                    <b-col><span class="title ml-1 pt-1 pt-lg-3 pt-xl-4">Команда</span></b-col>
                </b-row>
                <b-row class="mt-3" v-for="(doc, i) in teamDocs" :key="'mdoc'+i">
                    <div style="width: 24px"> 
                        <b-icon icon="file-earmark-text" class="icon-list mt-0 mr-2" />
                    </div>
                    <b-col class="content_small mt-0">                                
                                <a :href='img_path+doc.document' target="_blank" class="content_small" v-html="doc.title"></a>
                    </b-col>
                </b-row>                
            </b-col>
            <b-col cols="12" md="4" class="pl-5 pt-4">
                <b-row class="mb-4">
                    <div><img :src="startup_image" class="float-left icon-big" alt="стартап" /></div>
                    <b-col><span class="title ml-1 pt-1 pt-lg-3 pt-xl-4">Стартап</span></b-col>
                </b-row>
                <b-row class="mt-3" v-for="(doc, i) in startupDocs" :key="'mdoc'+i">
                    <div style="width: 24px"> 
                        <b-icon icon="file-earmark-text" class="icon-list mt-0 mr-2" />
                    </div>
                    <b-col class="content_small mt-0">
                        <a :href='img_path+doc.document' target="_blank" class="content_small" v-html="doc.title"></a>
                    </b-col>
                </b-row>                         
            </b-col>
            <b-col cols="12">
                <b-row>
                    <b-col class="text-center mt-5">
                        <h3>Основные мероприятия движения ЮниорПрофи в Красноярском крае</h3>
                    </b-col>
                </b-row>    
                <b-row>
                    <b-col>
                        <img :src="arrow" style="width: 100%"/>
                    </b-col>
                </b-row>
                <b-row class="mt-4 mb-5">
                    <b-col cols="12" md="4" class="pl-4">
                        <div class="area">
                            <h4>Комплексный региональный фестиваль ЮниорПрофи</h4>
                            <b-row class="mt-3" v-for="(doc, i) in festDocs" :key="'mdoc'+i">
                                <b-col cols="12" class="content_small mt-3 mt-lg-1">
                                            <b-icon icon="file-earmark-text" class="icon-list mt-0 mr-2" />
                                            <a :href='img_path+doc.document' target="_blank" class="content_small" v-html="doc.title"></a>
                                </b-col>
                            </b-row> 
                        </div>
                    </b-col>
                    <b-col cols="12" md="4" class="pl-4 pl-md-2 pt-4 pt-md-0">
                        <div class="area">
                            <h4>Летняя<br/>образовательная смена “Школа ЮниорПрофи”</h4>
                            <b-row class="mt-3" v-for="(doc, i) in schoolDocs" :key="'mdoc'+i">
                                <b-col cols="12" class="content_small mt-3 mt-lg-1">
                                            <b-icon icon="file-earmark-text" class="icon-list mt-0 mr-2" />
                                            <a :href='img_path+doc.document' target="_blank" class="content_small" v-html="doc.title"></a>
                                </b-col>
                            </b-row> 
                        </div>
                    </b-col>
                    <b-col cols="12" md="4" class="pl-4 pl-md-2 pr-3 pr-md-4 pt-4 pt-md-0">
                        <div class="area">
                            <h4>Краевой<br/>семинар-практикум</h4>
                            <b-row class="mt-3" v-for="(doc, i) in seminarDocs" :key="'mdoc'+i">
                                <b-col cols="12" class="content_small mt-3 mt-lg-1">
                                            <b-icon icon="file-earmark-text" class="icon-list mt-0 mr-2" />
                                            <a :href='img_path+doc.document' target="_blank" class="content_small" v-html="doc.title"></a>
                                </b-col>
                            </b-row> 
                        </div>
                    </b-col>
                </b-row>
            </b-col>           
        </b-row>
    </b-container>
</template>

<script>
    
    export default {
        name: 'ProjectsJuniorFooter',
        data() {
            return {
                
                img_path: process.env.VUE_APP_IMG,
                master_image: require('/src/assets/img/projects/junior_master.png'),
                team_image: require('/src/assets/img/projects/junior_team.png'),
                startup_image: require('/src/assets/img/projects/junior_startup.png'),
                arrow: require('/src/assets/img/projects/junior_arrow.png'),
                types: ['основной', 'мастерство', 'команда','стартап','фестиваль','школа','семинар']
            }
        },        
        computed: {
            info() {
                return this.$store.getters.getProject
            },
            image_logo() {
                return this.info.pics.find(item => item.label == "logo").images
            },
            image_photo() {
                return this.info.pics.find(item => item.label == "photo").images
            },
            image_footer() {
                return this.info.pics.find(item => item.label == "footer").images
            },
            masterDocs(){
                return this.info.docs.filter(item =>{
                    return (item.type === this.types[1])
                })
            },
            teamDocs(){
                return this.info.docs.filter(item =>{
                    return (item.type === this.types[2])
                })
            },
            startupDocs(){
                return this.info.docs.filter(item =>{
                    return (item.type === this.types[3])
                })
            },
            festDocs(){
                return this.info.docs.filter(item =>{
                    return (item.type === this.types[4])
                })
            },
            schoolDocs(){
                return this.info.docs.filter(item =>{
                    return (item.type === this.types[5])
                })
            },
            seminarDocs(){
                return this.info.docs.filter(item =>{
                    return (item.type === this.types[6])
                })
            }
        }
    }
</script>

<style scoped>
    .wrapper {
        background-color: var(--background-color-secondary);
    }

    h3 {
        font-family: 'SF';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;

        color: var(--text-primary-color);
    }

    .title {
        font-family: 'SF';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 16px;
        text-transform: uppercase;       
        float: left;
        color: var(--text-secondary-color);
    }

    .area {
        border: 2px solid #55A7B2;
        border-radius: 8px;
        min-height: 200px;
        padding: 24px;
    }

    h4 {
        font-family: 'SF BOLD';
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;  
        text-align: center; 
        color: var(--text-secondary-color);
    }

    .content {
        font-family: 'SF Light';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
    }
    
    .content_small {
        font-family: 'SF Light';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color:  #1B1A19;
    }

    .icon-list {
        font-size: 28px;
        color: #55A7B2;
        float: left;
    }
    

    .image {
        width: 100%;
    }

    .image_footer {
        width: 80%;
    }

    .image_logo{
        width: 300px;
    }

    
    @media all and (max-width: 1200px) {        
        .icon-big{
            width:40px;
        }
        .title {
            font-size: 24px;
        }
        
    }

    @media all and (max-width: 920px) {        
        .icon-big{
            width:24px;
        }
        .title {
            font-size: 16px;
        }
        h4 {
            font-size: 16px !important;
            line-height: 24px !important;  
        }
    }
</style>