<template>
    <b-container fluid>
        <b-row>
            <b-col class="mb-1 p-0">
                <bdd-description />
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mb-1 p-0">
                <bdd-smena />
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-5 p-0">
                <bdd-contacts />
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-5 p-0">
                <bdd-footer />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import Smena from "./BDD/Smena";
    import Description from "./BDD/Description";
    import Contacts from "./BDD/Contacts";
    import Footer from "./BDD/Footer";

    export default {
        name: "ProjectBDD",
        components: {
            'bdd-smena': Smena,
            'bdd-description': Description,
            'bdd-contacts':Contacts,
            'bdd-footer': Footer
        },
        mounted() {
            this.$store.dispatch('getProject', 'bdd');
        }
    }
</script>

<style scoped>

</style>