<template>
    <b-container class="p-0 p-md-3 pt-0 pt-md-0 pb-md-0">
        <b-row>
            <b-col class="ml-0 mt-5 mb-3">
                <b-pagination
                        class="float-end"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        aria-controls="my-table"
                ></b-pagination>
            </b-col>
        </b-row>
        <b-row class="mt-2 mb-5">
            <b-col cols="12" md="6" xl="4" v-for="(item, index) in filteredInfo" :key="'gal'+index" class="mb-4">
                    <b-card :title="item.name"
                            :img-src="img_path+item.poster"

                    class="shadow item_name">
                        <router-link :to="{name: 'gallery', params: { id: item.id}}" class="href">
                            <div class="mt-4 href">Подробнее</div>
                        </router-link>

                    </b-card>

            </b-col>
        </b-row>

    </b-container>
</template>

<script>
    export default {
        name: "List",
        data() {
            return {
                img_path: process.env.VUE_APP_IMG,
                perPage: 5,
                currentPage: 1,
            }
        },
        computed: {
            info: function () {
                return this.$store.getters.allGallery
            },
            rows: function (){
                return this.info.length
            },
            filteredInfo: function (){
                let i = (this.currentPage-1)*this.perPage;
                return this.info.slice(i, i+this.perPage)
            }
        }
    }
</script>

<style scoped>

    .href {
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 20px;
        line-height: 24px;
        color: #F2913A;
        text-decoration: none;
    }
    .href:hover {
        color: #af483a;
    }

    .item_image img{
        width: 100%;
        max-width: 620px;
        border-radius: 16px;
    }

    .item_name {
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 20px;
        line-height: 24px;
        color: var(--text-primary-color);
    }

    .card-title {
        color: var(--text-primary-color);
    }

    .shadow {
        background-color: var(--background-color-secondary);
    }

    /deep/ .page-item.disabled .page-link, /deep/ .form-control {
        background-color: var(--background-input);
        border-color: var(--background-input-border);
    }

    /deep/ .page-item.active .page-link {
        background-color: #F2913A !important;
        border-color: #F2913A;
    }
</style>